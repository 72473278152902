import React, { createContext, useContext, useMemo } from "react";

const context = createContext({})

function useMemoObject(object, allowedValues) {
    return useMemo(() =>
        allowedValues.reduce((prev, current) =>
        ({
            ...prev,
            [current]: object[current]
        }), {}),
        allowedValues.map(key => object?.[key]))

}

const allowedValues = [
    "ruleId",
    "operatorsData",
    "refetch",
    "currentGroupId",
    "forJoin"
]

export function ConditionContextProvider({ value, children }) {

    return <context.Provider value={useMemoObject(value, allowedValues)}>
        {children}
    </context.Provider>
}

export const useConditionContext = () => useContext(context)