import axios from './axios/config';

export const getDropdownData = async () => {
  const res = await axios.get('audit-trail');
  return res.data;
}

export const getChartData = async (data) => {
  const res = await axios.post('audit-trail', data);
  return res.data;
}

