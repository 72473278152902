import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import {
    editRest,
    editSmpt,
    getEmailConfig,
    getRestConfig,
    getSmtp,
} from '../../../services/settings';
import { useState } from 'react';
import {
    Button,
    NumberBox,
    RadioGroup,
    SelectBox,
    TextBox,
} from 'devextreme-react';
import Loader from '../../../components/loader/loader';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { flagStatuses, rolesLabels } from '../../../utils/vars';
import { editEmailConfig } from '../../../services/settings';
import { useSelector } from 'react-redux';
import { getUser, updateTenant } from '../../../services/user';
import { reactQueryOptions } from '../../../utils/reactQueryOptions';
import Stack  from '@mui/system/Stack';
import { getAxiosResponseError } from '../../../utils/functions';
import { useToastContext } from '../../../components/toast/toast';

export default function EmailSettings() {
    const { showErrorToast, showSuccessToast } = useToastContext();

    const {
        data: smtpData,
        isLoading: loadingSmtpData,
        error: errorFetchingSmtp,
    } = useQuery('smtp-data', getSmtp, {
        refetchOnWindowFocus: false,
        retry: 1,
    });
    const {
        data: restConfigData,
        isLoading: loadingRestConfigData,
        error: errorFetchingRestConfig,
    } = useQuery('rest-config-data', getRestConfig, {
        refetchOnWindowFocus: false,
        retry: 1,
    });
    const {
        data: emailsConfigData,
        isLoading: loadingEmailsConfigData,
        error: errorFetchingEmailsConfig,
    } = useQuery('emails-config-data', getEmailConfig, {
        refetchOnWindowFocus: false,
        retry: 1,
    });
    const { data: userData, isLoading: loadingUserData } = useQuery(
        'current-user-data',
        getUser,
        reactQueryOptions
    );
    const [smtpValues, setSmtpValues] = useState(null);
    const [showPass, setShowPass] = useState(false);
    const [inputChanges, setInputChanges] = useState({});
    const [emailConfigs, setEmailConfigs] = useState([]);
    const [restConfigValues, setRestConfigValues] = useState(null);
    const theme = useSelector((state) => state.theme);
    const [mailSenderValue, setMailSenderValue] = useState(null);

    const memoizedEmailsConfigData = useMemo(
        () => emailsConfigData,
        [emailsConfigData]
    );
    const memoizedSetEmailsConfigData = useCallback(setEmailConfigs, [
        setEmailConfigs,
    ]);
    const memoizedSetHasChanges = useCallback(setInputChanges, [
        setInputChanges,
    ]);

    useEffect(() => {
        if (!mailSenderValue) {
            setMailSenderValue(userData?.tenant?.mailSender);
        }
    }, [userData]);

    useState(() => {
        if (
            errorFetchingSmtp ||
            errorFetchingEmailsConfig
        ) {
            showErrorToast(getAxiosResponseError(errorFetchingSmtp || errorFetchingEmailsConfig));
        }
    }, [errorFetchingSmtp, errorFetchingEmailsConfig]);

    if (smtpData && !smtpValues) {
        setSmtpValues({
            mailFrom: smtpData.mailFrom,
            server: smtpData.server,
            port: smtpData.port,
            username: smtpData.username,
        });
    }

    if (restConfigData && !restConfigValues) {
        const data = {};
        Object.keys(restConfigData).forEach((key) => {
            if (key === 'id' || key === 'dateCreated' || key === 'tenant') {
                return;
            }

            data[key] = restConfigData[key];
        });
        setRestConfigValues(data);
    }

    const hasChanges = !!Object.keys(inputChanges).length;

    async function saveBtnHandler() {
        let hasErrors = false;
        try {
            if (inputChanges.smtp) {
                await editSmpt(smtpValues);
            }

            if (inputChanges.roleConfig) {
                const requests = emailConfigs.map((e) => {
                    return editEmailConfig(e);
                });

                await Promise.all(requests);
            }

            if (inputChanges.rest) {
                await editRest(restConfigValues);
            }

            if (inputChanges.mailSender) {
                await updateTenant({ mailSender: mailSenderValue });
            }

            setInputChanges({});
        } catch (err) {
            hasErrors = true;
            showErrorToast(getAxiosResponseError(err));
        }

        if (!hasErrors) {
            showSuccessToast('Changes saved.')
        }
    }

    return (
        <div className="emails-settings-section">
            <div className="section-header">
                <p className="setting-label align-center">Emails</p>
                <div className="save-btn">
                    <Button
                        icon="save"
                        stylingMode="outlined"
                        onClick={saveBtnHandler}
                        disabled={!hasChanges}
                    />
                </div>
            </div>

            {loadingSmtpData ||
            loadingRestConfigData ||
            loadingEmailsConfigData || loadingUserData ? (
                <Loader height={'300px'} />
            ) : (
                <div
                    className={theme === 'dark' ? 'paper-dark-theme' : 'paper'}
                >
                    <div className="smtp-section">
                        <p className="secondary-label">SMTP configuration</p>
                        <div className="flex-container smtp-wrapper">
                            <div className="settings-input-wrapper">
                                <TextBox
                                    label="Mail from"
                                    width={250}
                                    stylingMode="underlined"
                                    value={smtpValues?.mailFrom}
                                    onInput={(e) => {
                                        setInputChanges((oldState) => {
                                            return { ...oldState, smtp: true };
                                        });
                                        setSmtpValues((oldState) => {
                                            return {
                                                ...oldState,
                                                mailFrom: e.event.target.value,
                                            };
                                        });
                                    }}
                                />
                            </div>
                            <div className="settings-input-wrapper">
                                <TextBox
                                    label="SMTP server"
                                    width={250}
                                    stylingMode="underlined"
                                    value={smtpValues?.server}
                                    onInput={(e) => {
                                        setInputChanges((oldState) => {
                                            return { ...oldState, smtp: true };
                                        });
                                        setSmtpValues((oldState) => {
                                            return {
                                                ...oldState,
                                                server: e.event.target.value,
                                            };
                                        });
                                    }}
                                />
                            </div>
                            <div className="settings-input-wrapper">
                                <TextBox
                                    label="SMTP port"
                                    width={250}
                                    stylingMode="underlined"
                                    value={
                                        smtpValues?.port &&
                                        smtpValues?.port.toString()
                                    }
                                    onInput={(e) => {
                                        setInputChanges((oldState) => {
                                            return { ...oldState, smtp: true };
                                        });
                                        setSmtpValues((oldState) => {
                                            return {
                                                ...oldState,
                                                port: e.event.target.value,
                                            };
                                        });
                                    }}
                                />
                            </div>
                            <div className="settings-input-wrapper">
                                <TextBox
                                    label="User"
                                    width={250}
                                    stylingMode="underlined"
                                    value={smtpValues?.username}
                                    onInput={(e) => {
                                        setInputChanges((oldState) => {
                                            return { ...oldState, smtp: true };
                                        });
                                        setSmtpValues((oldState) => {
                                            return {
                                                ...oldState,
                                                username: e.event.target.value,
                                            };
                                        });
                                    }}
                                />
                            </div>
                            <div className="settings-input-wrapper password-wrapper">
                                <TextBox
                                    label="Password"
                                    mode={showPass ? 'text' : 'password'}
                                    width={250}
                                    stylingMode="underlined"
                                    value={smtpValues?.password || ''}
                                    onInput={(e) => {
                                        setInputChanges((oldState) => {
                                            return { ...oldState, smtp: true };
                                        });
                                        setSmtpValues((oldState) => {
                                            return {
                                                ...oldState,
                                                password: e.event.target.value,
                                            };
                                        });
                                    }}
                                />
                                {showPass ? (
                                    <div
                                        className="show-hide-icon"
                                        onClick={() => setShowPass(false)}
                                    >
                                        <VisibilityOffIcon />
                                    </div>
                                ) : (
                                    <div
                                        className="show-hide-icon"
                                        onClick={() => setShowPass(true)}
                                    >
                                        <VisibilityIcon />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="rest-section">
                        <p className="secondary-label">REST configuration</p>
                        <Stack flexDirection='row' justifyContent='center'>
                            <TextBox
                                label="From"
                                defaultValue={restConfigValues?.from}
                                onInput={(e) => {
                                    setInputChanges((oldState) => {
                                        return { ...oldState, rest: true };
                                    });
                                    setRestConfigValues((oldState) => {
                                        return {
                                            ...oldState,
                                            from: e.event.target.value,
                                        };
                                    });
                                }}
                                stylingMode="underlined"
                            />
                            
                        </Stack>
                    </div>
                    <div className="role-email-settings-section">
                        <p className="secondary-label">
                            Role email notifications
                        </p>
                        <div className="role-email-settings-content flex-container">
                            {/* <div className="flex-container role-email-settings-content"> */}
                            <RoleEmailSettings
                                role={'ADMIN'}
                                emailsConfigData={memoizedEmailsConfigData}
                                setEmailConfigs={memoizedSetEmailsConfigData}
                                setInputChanges={memoizedSetHasChanges}
                            />
                            <RoleEmailSettings
                                role={'AML_MANAGER'}
                                emailsConfigData={memoizedEmailsConfigData}
                                setEmailConfigs={memoizedSetEmailsConfigData}
                                setInputChanges={memoizedSetHasChanges}
                            />
                            <RoleEmailSettings
                                role={'AUDITOR'}
                                emailsConfigData={memoizedEmailsConfigData}
                                setEmailConfigs={memoizedSetEmailsConfigData}
                                setInputChanges={memoizedSetHasChanges}
                            />
                        </div>
                    </div>
                    <MailSender
                        value={mailSenderValue}
                        setValue={setMailSenderValue}
                        setHasChanges={() => {
                            if (!inputChanges.mailSender) {
                                setInputChanges((oldState) => ({
                                    ...oldState,
                                    mailSender: true,
                                }));
                            }
                        }}
                    />
                </div>
            )}
        </div>
    );
}

function MailSender({ value, setValue, setHasChanges }) {
    const mailSenderItems = ['SMTP', 'REST'];

    return (
        <div>
            <p className="secondary-label">Mail sender</p>
            <div className="mail-sender-radio">
                <RadioGroup
                    value={value}
                    layout="horizontal"
                    items={mailSenderItems}
                    onValueChange={(v) => {
                        setHasChanges();
                        setValue(v);
                    }}
                ></RadioGroup>
            </div>
        </div>
    );
}

const RoleEmailSettings = memo(function RoleEmailSettings({
    role,
    emailsConfigData,
    setEmailConfigs,
    setInputChanges,
}) {
    const items = [
        { label: 'Week', value: 'WEEK' },
        { label: 'Month', value: 'MONTH' },
        { label: 'Day', value: 'DAY' },
    ];
    const configs =
        emailsConfigData?.find((e) => e.role === role)?.configs || [];

    function InputsSection({ config }) {
        function changeValueHandler(key, value) {
            setInputChanges((oldState) => {
                return { ...oldState, roleConfig: true };
            });
            setEmailConfigs((oldState) => {
                const arrCopy = [...oldState];
                let index = -1;

                if (!!arrCopy.length) {
                    arrCopy.forEach((e, i) => {
                        if (e.id === config.id) {
                            index = i;
                        }
                    });
                }

                if (index === -1) {
                    const data = {
                        id: config.id,
                        count: config.count,
                        period: config.period,
                        countPerPeriod: config.countPerPeriod,
                    };
                    data[key] = value;
                    arrCopy.push(data);
                } else {
                    const data = arrCopy[index];
                    data[key] = value;
                    arrCopy[index] = data;
                }
                return arrCopy;
            });
        }
        return (
            <div>
                <p>Status {flagStatuses[config.status] || config.status}</p>
                <div className="flex-container inputs-gap-contaner">
                    <SelectBox
                        disabled={role !== 'ADMIN'}
                        defaultValue={config?.period}
                        items={items}
                        displayExpr={'label'}
                        valueExpr={'value'}
                        stylingMode="underlined"
                        onValueChange={(v) => changeValueHandler('period', v)}
                    />
                    <NumberBox
                        stylingMode="underlined"
                        label="Flags count"
                        defaultValue={
                            config.count ? Number(config.count) : null
                        }
                        onInput={(e) => {
                            changeValueHandler('count', e.event.target.value);
                        }}
                    />
                    <NumberBox
                        stylingMode="underlined"
                        label="Count per period"
                        defaultValue={
                            config?.count ? Number(config.countPerPeriod) : null
                        }
                        disabled={role !== 'ADMIN'}
                        onInput={(e) => {
                            changeValueHandler(
                                'countPerPeriod',
                                e.event.target.value
                            );
                        }}
                    />
                </div>
            </div>
        );
    }

    return (
        <div className="role-email-settings">
            <p className="role-label">{rolesLabels[role]}</p>
            <div className="flex-container inputs-section">
                {configs.map((e, i) => (
                    <InputsSection key={i} config={e} />
                ))}
            </div>
        </div>
    );
});
