import { createContext, useContext } from 'react';

export const ruleContext = createContext();
export const RuleContext = ({
    children,
    ...value

}) => {
    
    return (
        <ruleContext.Provider value={value}>{children}</ruleContext.Provider>
    );
};

export const useRuleContext = () => useContext(ruleContext);
