import { configureStore } from '@reduxjs/toolkit';
import languageReducer from './features/appSettings/language';
import queriesReducer from './features/dashboard/queries';
import ruleReducer from './features/dashboard/rule';
import limitSectionReducer from './features/dashboard/limitSection';
import showGroupsReducer from './features/dashbord';
import themeReducer from './features/appSettings/theme';
import localizedLabelsReducer from './features/localizedLabels';
import filtersReducer from './features/filters';
import userReducer from './features/user';
import rulesScrollReducer from './features/rulesScroll';
import reportsSelectedSlice from './features/reportsSelected';
import rulesSearchParamsReducer from './features/rulesSearchParams';
import errorFetchingActionsReducer from './features/errorFetchingActions';
import unselectTreeViewReducer from './features/unselectTreeView';
import ruleDisplayedEntitiesReducer from './features/rule/displayedEntities';

export const store = configureStore({
    reducer: {
        language: languageReducer,
        showGroups: showGroupsReducer,
        theme: themeReducer,
        localizedLabels: localizedLabelsReducer,
        filters: filtersReducer,
        user: userReducer,
        rulesScroll: rulesScrollReducer,
        reportsSelected: reportsSelectedSlice,
        queries: queriesReducer,
        limitSection: limitSectionReducer,
        rule: ruleReducer,
        rulesSearchParams: rulesSearchParamsReducer,
        errorFetchingActions: errorFetchingActionsReducer,
        unselectTreeView: unselectTreeViewReducer,
        ruleDisplayedEntities: ruleDisplayedEntitiesReducer,
    },
});
