import { CheckBox, DataGrid } from "devextreme-react";
import { Column } from "devextreme-react/data-grid";
import moment from "moment";
import { resolutionStatuses } from "../../../utils/vars";

export default function ResolutionStatusHistory({ dataSource }) {
    return (
        <div className='flag-table width-50'>
            <h5>Resolution history</h5>
            <DataGrid dataSource={dataSource} keyExpr={'id'}>
                <Column
                    allowSorting={false}
                    allowResizing={true}
                    caption={'Date'}
                    dataField={'date'}
                    dataType="datetime"
                />
                <Column
                    allowResizing={true}
                    allowSorting={false}
                    caption={'Resolution status'}
                    dataField={'status'}
                    cellRender={(data) => resolutionStatuses[data.data.status]}
                />
                <Column
                    allowResizing={true}
                    allowSorting={false}
                    caption={'Employee'}
                    cellRender={(data) => data.data.user?.name}
                />
                <Column
                    allowResizing={true}
                    allowSorting={false}
                    caption={'Description'}
                    cellRender={(data) => data.data.description}
                />
                <Column
                    dataType={'date'}
                    allowResizing={true}
                    allowSorting={false}
                    caption={'Due date'}
                    cellRender={(data) => {
                        return data.data.dueDate
                            ? moment(data.data.dueDate).format('DD/MM/yyyy')
                            : null;
                    }}
                />
                <Column
                    alignment="center"
                    dataType={'date'}
                    allowResizing={true}
                    allowSorting={false}
                    caption={'Due date check'}
                    cellRender={(data) => {
                        
                        const checked = data.data.dueDateCheck;
                        return (
                            <>
                                {data.data.dueDate && (
                                    <CheckBox disabled value={checked || null}/>
                                )}
                            </>
                        );
                    }}
                />
            </DataGrid>
        </div>
    );
}