import Stack from '@mui/material/Stack';
import { Popover } from 'devextreme-react';
import { useState } from 'react';
import {
    addEntityFieldFunction,
    deleteEntityFieldFunction,
} from '../../services/rules';
import { useFunctionsDataContext } from '../ruleDashboard/contexts/functionsDataContext';
import { useRuleContext } from '../ruleDashboard/ruleContext';

export default function DataFieldFunctions({ parentFunctionId, id }) {
    const [popoverOpen, setPopoverOpen] = useState(false);
    const { refetchRule } = useRuleContext();
    const { functionsData } = useFunctionsDataContext();
    const liStyle = {
        listStyleType: 'none',
        borderBottom: '1px solid #d7d7d7',
        padding: '4px',
    };

    const functionsLis = functionsData?.map((e, i) => (
        <li
            key={i}
            style={liStyle}
            onClick={() => {
                addFunctionHandler(e.id);
            }}
        >
            {e.name}
        </li>
    ));

    async function addFunctionHandler(functionId) {
        try {
            await addEntityFieldFunction({
                id,
                functionId,
            });
            setPopoverOpen(false);
            refetchRule();
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <Stack flexDirection={'row'} justifyContent={'end'}>
            <div
                id={`data-field-${id}`}
                className="more-icon-wrapper"
                onClick={() => setPopoverOpen(true)}
            >
                <i className="dx-icon-more" />
            </div>
            <Popover
                visible={popoverOpen}
                target={`#data-field-${id}`}
                position="bottom"
                width={'auto'}
                onHiding={() => setPopoverOpen(false)}
            >
                <ul
                    className="right-field-type-popover"
                    style={{ padding: 0, fontSize: '17px' }}
                    id={`data-field-${id}`}
                >
                    {functionsLis}
                </ul>
            </Popover>
        </Stack>
    );
}

export function FunctionWrapper({ functionData, content, id }) {
    const { refetchRule } = useRuleContext();

    console.log('functionData', functionData);

    async function deleteFunctionHandler() {
        try {
            await deleteEntityFieldFunction({id: functionData.id});
            refetchRule();
        } catch (err) {
            console.error(err);
        }
    }

    return (
        <div className="function-wrapper">
            <Stack
                flexDirection={'row'}
                justifyContent="space-between"
                gap={'10px'}
            >
                <p>Function {functionData?.function?.name}</p>
                <div className="close-icon-wrapper">
                    <i
                        className="dx-icon-close"
                        onClick={deleteFunctionHandler}
                    />
                </div>
            </Stack>

            {content}
        </div>
    );
}
