import { SelectBox } from 'devextreme-react';
import React from 'react';
import { useConditionContext } from './conditionsContext';
import { useFieldsGroupContext } from './fieldsGroupContext';
import { updateField } from '../../../../services/rules';
import { useToastContext } from '../../../../components/toast/toast';
import { getAxiosResponseError } from '../../../../utils/functions';
import { useRuleContext } from '../../ruleContext';
import { useGetDefaultPutData } from '../../../../hooks/useGetDefaultPostData';

export default function ChangeOperator() {
    const { operatorsData } = useConditionContext();
    const { allowRuleEdit } = useRuleContext();
    const { fieldsGroupData } = useFieldsGroupContext();
    const { showErrorToast } = useToastContext();
    const items = operatorsData?.map((e) => e.value);
    const defaultIndex = items?.indexOf(fieldsGroupData?.operator?.value);
    const getDefaultPutData = useGetDefaultPutData();
    const {refetchRule} = useRuleContext();

    function getSelectedType(value) {
        let type = null;
        operatorsData.forEach((e) => {
            if (e.value === value) {
                type = e.type;
            }
        });

        return type;
    }

    async function changeOperator(value) {
        const defaultData = getDefaultPutData();
        const putData = {...defaultData};
        
        const conditionOperatorType = getSelectedType(value);
        putData.conditionOperatorType = conditionOperatorType;

        try {
            await updateField(putData);
            refetchRule();
        } catch (err) {
            console.error(err);
            showErrorToast(getAxiosResponseError(err));
        }
    }

    if(!fieldsGroupData) {
        return null;
    }

    return (
        <SelectBox
            items={items}
            defaultValue={defaultIndex !== undefined ? items[defaultIndex] : ''}
            stylingMode="underlined"
            width={'50px'}
            onValueChange={(v) => changeOperator(v)}
            disabled={!allowRuleEdit}
        />
    );
}
