import { SelectBox } from 'devextreme-react';
import { errorMsg } from '../../../utils/vars';
import { attachEntity } from '../../../services/rules';
import { useQueryContext } from '../conditionsDropTarget/condition/queryContext';
import { useToastContext } from '../../../components/toast/toast';
import { getAxiosResponseError } from '../../../utils/functions';
import { useSelector } from 'react-redux';
import { selectDisplayedEntities } from '../../../redux/features/rule/displayedEntities';

export default function SelectEntity({ entities, refetch }) {
    const { queryId } = useQueryContext();
    const { showErrorToast } = useToastContext();
    const displayedEntities = useSelector(selectDisplayedEntities)[queryId];

    const disabled = !!displayedEntities?.length || !displayedEntities;


    if (!entities) {
        return null;
    }

    async function addEntity(entityId) {
        try {
            await attachEntity({ entityId, queryId });
            refetch();
        } catch (err) {
            console.error(err);
            showErrorToast(getAxiosResponseError(err));
        }
    }

    return (
        <div className="accordion select-entity-accordion">
            <SelectBox
                width={180}
                value={''}
                label="Add"
                items={entities}
                displayExpr={'name'}
                valueExpr={'id'}
                stylingMode="underlined"
                onValueChange={(v) => addEntity(v)}
                disabled={disabled}
            ></SelectBox>
        </div>
    );
}
