import { Button, SelectBox } from 'devextreme-react';
import React from 'react';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { changeTimezone, getTimezones } from '../../../services/user';
import { useEffect } from 'react';
import { changeLanguage } from '../../../redux/features/appSettings/language';
import { changeTheme } from '../../../redux/features/appSettings/theme';
import themes from 'devextreme/ui/themes';
import { setUser } from '../../../redux/features/user';
import Loader from '../../../components/loader/loader';
import { errorMsg } from '../../../utils/vars';
import { useToastContext } from '../../../components/toast/toast';
import { getAxiosResponseError } from '../../../utils/functions';

export default function GeneralSettings() {
    const { showErrorToast, showSuccessToast } = useToastContext();

    const {
        data: timezones,
        isLoading,
        error: timezonesFetchError,
    } = useQuery('timezones', getTimezones, {
        refetchOnWindowFocus: false,
        retry: 1,
    });

    useEffect(() => {
        if (timezonesFetchError) {
            showErrorToast(
                getAxiosResponseError(timezonesFetchError) ||
                    "Can't load timezones data. Try again later"
            );
        }
    }, [timezonesFetchError]);

    const dispatch = useDispatch();
    const [selectedLanguage, setSelectedLanguage] = useState(null);
    const [selectedTheme, setSelectedTheme] = useState(null);
    const [selectedTimezone, setSelectedTimezone] = useState(null);
    const hasChanges =
        !!selectedTimezone || !!selectedTheme || !!selectedLanguage;
    const user = useSelector((state) => state.user);
    const lg = useSelector((state) => state.language);
    const theme = useSelector((state) => state.theme);
    let wrapperClasses = 'content-wrapper ';
    wrapperClasses += theme === 'dark' ? 'paper-dark-theme' : 'paper';

    const themeItems = [
        { label: 'Light', value: 'light' },
        { label: 'Dark', value: 'dark' },
    ];

    const languageItems = [
        { label: 'Български', value: 'bg-BG' },
        { label: 'English', value: 'en-US' },
        { label: 'Română', value: 'ro-RO' },
        { label: 'Polski', value: 'pl-PL' },
        { label: 'Українська', value: 'ua-UA' },
        { label: 'Македонски', value: 'mk-МК' },
        { label: 'Español', value: 'es-ES' },
        { label: 'Shqip', value: 'sh-ALB' },
        { label: 'Hrvatski', value: 'hr-HR' },
    ];
    let defaultLanguageIndex = null;
    let defaultThemeIndex = null;
    languageItems.forEach((e, i) => {
        if (e.value === lg) {
            defaultLanguageIndex = i;
        }
    });

    themeItems.forEach((e, i) => {
        if (e.value === theme) {
            defaultThemeIndex = i;
        }
    });

    async function saveBtnHandler() {
        let hasError = false;

        if (selectedLanguage) {
            localStorage.setItem('tm-language', selectedLanguage);
            dispatch(changeLanguage(selectedLanguage));
        }

        if (selectedTheme) {
            localStorage.setItem('theme', selectedTheme);
            dispatch(changeTheme(selectedTheme));
            if (selectedTheme === 'dark') {
                themes.current('material.blue.dark');
            } else {
                themes.current('material.blue.light');
            }
        }

        if (selectedTimezone) {
            try {
                const user = await changeTimezone(selectedTimezone);
                dispatch(setUser(user));
            } catch (err) {
                console.error(err);
                hasError = true;
                showErrorToast(getAxiosResponseError(err));
            }
        }

        setSelectedLanguage(null);
        setSelectedTheme(null);
        setSelectedTimezone(null);

        if (!hasError) {
            showSuccessToast('Changes saved.');
        }
    }

    if (isLoading) {
        return <Loader height={'300px'} />;
    }

    return (
        <div className="general-settings-wrapper">
            <div className="section-header">
                <div className="save-btn">
                    <Button
                        icon="save"
                        stylingMode="outlined"
                        onClick={saveBtnHandler}
                        disabled={!hasChanges}
                    />
                </div>
            </div>
            <div className={wrapperClasses}>
                <div className="language-section">
                    <p className="setting-label">Language</p>
                    <SelectBox
                        stylingMode="underlined"
                        defaultValue={languageItems[defaultLanguageIndex].value}
                        width={250}
                        valueExpr="value"
                        displayExpr="label"
                        items={languageItems}
                        onValueChange={(v) => setSelectedLanguage(v)}
                    />
                </div>
                <div className="theme-section">
                    <p className="setting-label">Theme</p>
                    <SelectBox
                        stylingMode="underlined"
                        width={250}
                        valueExpr={'value'}
                        displayExpr="label"
                        defaultValue={themeItems[defaultThemeIndex].value}
                        items={themeItems}
                        onValueChange={(v) => setSelectedTheme(v)}
                    />
                </div>
                <div>
                    <p className="setting-label">Timezone</p>
                    <SelectBox
                        stylingMode="underlined"
                        searchEnabled={true}
                        width={250}
                        valueExpr={'name'}
                        displayExpr="hours"
                        defaultValue={selectedTimezone || user?.timezone || ''}
                        items={timezones || []}
                        onValueChange={(v) => setSelectedTimezone(v)}
                    />
                </div>
            </div>
        </div>
    );
}
