import { createSlice } from '@reduxjs/toolkit';

const localizedLabelsSlice = createSlice({
    name: 'localizedLabels',
    initialState: [],
    reducers: {
        setLabels: (_state, action) => {
            return action.payload
        },
    },
});

export default localizedLabelsSlice.reducer;

export const { setLabels } = localizedLabelsSlice.actions;
