import { Button, SelectBox, Toast, Tooltip } from 'devextreme-react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmPopup from '../../../../components/confirmPopup/confirmPopup';
import {
    updateField,
    createField,
    deleteGroup,
    editGroup,
    postConditionGroup,
} from '../../../../services/rules';
import {
    languageLables as languageLabels,
    toastDisplayTime,
} from '../../../../utils/vars';
import Condition from './condition';
import { useDrop } from 'react-dnd';
import {
    ConditionContextProvider,
    useConditionContext,
} from './conditionsContext';
import { useRuleContext } from '../../ruleContext';
import {
    addConditionPlaceholder,
    clearConditionPlaceholder,
    removeHoverField,
} from '../../../../redux/features/dashboard/rule';
import Placeholder from './placeholder/placeholder';
import { useQueryContext } from './queryContext';
import { useToastContext } from '../../../../components/toast/toast';
import {
    getAxiosResponseError,
    getRightType,
} from '../../../../utils/functions';

export function ConditionDropHandler({ children, elementProps, groupId }) {
    const { refetchRule } = useRuleContext();
    const { forJoin } = useConditionContext();
    const { queryId } = useQueryContext();
    const dispatch = useDispatch();
    const { showErrorToast } = useToastContext();

    const [_collectedProps, drop] = useDrop(() => ({
        accept: 'entity-drop-target',
        drop: async (item, monitor) => {
            // monitor.getDropResult() will return an object if the entity has been dropped on a
            // condition, because the drop listener in 'Condition' returns {}. This means that the
            // group should ignore the event
            if (monitor.didDrop() || monitor.getDropResult()) {
                return;
            }
            console.log('Drop on group', item);

            const type = getRightType(item?.data?.type);

            try {
                if (groupId) {
                    await createField({
                        leftFieldId: item.data?.id,
                        leftDisplayedEntityId: item.displayedEntityId,
                        groupId,
                        leftType: 'FIELD',
                        type,
                    });
                } else {
                    const createGroupResp = await postConditionGroup({
                        queryId,
                        forJoin,
                    });

                    const group = createGroupResp.data;
                    const field = group.fields?.[0];

                    const putData = {
                        id: field.id,
                        conditionOperatorType: field?.operator?.type,
                        conditionInterval: field?.rightPart?.conditionInterval,
                        type,
                        leftDisplayedEntityId: item.displayedEntityId,
                        leftFieldId: item.data?.id,
                    };

                    if (item?.data?.type === 'DATE') {
                        putData.conditionInterval = 'DAY_BEFORE_NOW';
                    }

                    console.log('putData', putData);

                    // const updateFieldResp = await updateField({
                    //     id: group.fields?.[0]?.id,
                    //     leftFieldId: item.data?.id,
                    //     leftDisplayedEntityId: item.displayedEntityId,
                    // });

                    const updateFieldResp = await updateField(putData);

                    group.fields = [updateFieldResp.data];
                }
                refetchRule();
            } catch (err) {
                console.error(err);
                showErrorToast(getAxiosResponseError(err));
            }
        },
        hover: (item, monitor) => {
            // console.log('groupId on hover', groupId);
            if (monitor.isOver({ shallow: true })) {
                dispatch(
                    clearConditionPlaceholder({
                        type: forJoin ? 'joinGroups' : 'conditionGroups',
                    })
                );
                dispatch(
                    removeHoverField({
                        type: forJoin ? 'joinGroups' : 'conditionGroups',
                        queryId,
                    })
                );
                dispatch(
                    addConditionPlaceholder({
                        queryId,
                        conditionGroupId: groupId,
                        type: forJoin ? 'joinGroups' : 'conditionGroups',
                    })
                );
            }
        },
    }));

    return (
        <div {...elementProps} ref={drop}>
            {children}
        </div>
    );
}

function DeleteBtn({ setIsPopupOpen, index }) {
    const [tooltipVisible, setTooltipVisible] = useState(false);
    const lg = useSelector((state) => state.language);

    return (
        <div
            onMouseEnter={() => setTooltipVisible(true)}
            onMouseLeave={() => setTooltipVisible(false)}
        >
            <Tooltip
                target={'#delete-group-btn-' + index}
                visible={tooltipVisible}
            >
                <p>{languageLabels.deleteGroup[lg]}</p>
            </Tooltip>
            <Button
                id={'delete-group-btn-' + index}
                icon="trash"
                onClick={() => setIsPopupOpen(true)}
            />
        </div>
    );
}

function AndOrComponent({ andOrOperaton, id }) {
    const { forJoin } = useConditionContext();
    const { allowRuleEdit } = useRuleContext();
    const items = ['And', 'Or'];
    let index;
    items.forEach((e, i) => {
        if (e?.toLowerCase() === andOrOperaton?.toLowerCase()) {
            index = i;
        }
    });
    const defaultValue = items[index];
    const { showErrorToast } = useToastContext();

    async function changeOperator(groupOperator) {
        try {
            await editGroup({ groupOperator, id, forJoin });
        } catch (err) {
            console.error(err);
            showErrorToast(getAxiosResponseError(err));
        }
    }

    return (
        <div className="and-or-select-wrapper" style={{ marginRight: '10px' }}>
            <SelectBox
                stylingMode="underlined"
                defaultValue={defaultValue}
                width={80}
                items={items}
                onValueChange={(v) => changeOperator(v.toUpperCase())}
                disabled={!allowRuleEdit}
            />
        </div>
    );
}

function TopSection({ group, isPopupOpen, setIsPopupOpen, deleteGroup }) {
    const { refetchRule, allowRuleEdit } = useRuleContext();

    async function deleteHandler() {
        try {
            await deleteGroup(group?.id);
            refetchRule();
        } catch (err) {
            console.error(err);
        }
    }
    return (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <AndOrComponent andOrOperaton={group?.operator} id={group?.id} />
            {allowRuleEdit && <DeleteBtn setIsPopupOpen={setIsPopupOpen} />}

            <ConfirmPopup
                content={'Are you sure?'}
                open={isPopupOpen}
                onConfirm={deleteHandler}
                onCancel={() => setIsPopupOpen(false)}
            />
        </div>
    );
}

export default function ConditionGroup({ conditionGroupData, isTopLevel }) {
    const [popupOpen, setPopupOpen] = useState(false);
    const [rerender, setRerender] = useState(0);
    const conditionContext = useConditionContext();
    const { showErrorToast } = useToastContext();

    useEffect(() => {
        setRerender((oldState) => oldState + 1);
    }, [conditionGroupData?.id]);

    async function deleteGroupHandler(id) {
        try {
            await deleteGroup(id);
            setPopupOpen(false);
        } catch (err) {
            console.error(err);
            showErrorToast(getAxiosResponseError(err));
        }
    }

    return (
        <ConditionContextProvider
            value={{
                ...conditionContext,
                currentGroupId: conditionGroupData?.id,
            }}
        >
            <ConditionDropHandler
                key={rerender}
                groupId={conditionGroupData?.id}
                elementProps={{
                    className:
                        'condition-wrapper dashed-border' +
                        (isTopLevel ? ' top-level' : ''),
                }}
            >
                {!!conditionGroupData?.fields?.length && (
                    <TopSection
                        deleteGroup={deleteGroupHandler}
                        group={conditionGroupData}
                        isPopupOpen={popupOpen}
                        setIsPopupOpen={setPopupOpen}
                    />
                )}

                {conditionGroupData?.fields?.map((e, i) => {
                    //Needs to pass this data as key to unmount the condition when it's data has changed. Otherwise on drop it sends old data because the drag and drop library keeps some old state. The property hovered is removed because it's unmounting on hover and doesn't look good
                    
                    const getDataObj = () => {
                        const rightPartCopy = { ...e.rightPart };
                        const leftPartCopy = { ...e.leftPart };
                        if (
                            rightPartCopy.hovered === true ||
                            rightPartCopy.hovered === false
                        ) {
                            delete rightPartCopy.hovered;
                        }

                        if (
                            leftPartCopy.hovered === true ||
                            leftPartCopy.hovered === false
                        ) {
                            delete leftPartCopy.hovered;
                        }

                        return { leftPartCopy, rightPartCopy };
                    };

                    const dataObj = getDataObj();

                    const lastGroup =
                        conditionGroupData?.groups[
                            conditionGroupData?.groups.length - 1
                        ];
                    const isLastField =
                        conditionGroupData?.fields.length === i + 1;

                    return (
                        <Condition
                            key={JSON.stringify(dataObj)}
                            fieldsData={e}
                            isSingle={conditionGroupData?.fields.length === 1}
                            withNestedPlaceholder={
                                lastGroup === 'placeholder' && isLastField
                            }
                        />
                    );
                })}

                {conditionGroupData.withPlaceholder && <Placeholder />}

                {conditionGroupData?.groups?.map((e, i) => {
                    return e === 'placeholder' ? null : (
                        <ConditionGroup i={i} key={i} conditionGroupData={e} />
                    );
                })}
            </ConditionDropHandler>
        </ConditionContextProvider>
    );
}
