import moment from 'moment';
import { useSelector } from 'react-redux';
import { useCheckPermission } from '../../../hooks/useCheckPermission';
import {
    flagStatuses,
    languageLables,
    resolutionStatuses,
    statusColorClasses,
} from '../../../utils/vars';

export default function Details({
    date,
    status,
    rule,
    tenant,
    data,
    resolutionStatus,
    assignee
}) {
    const lg = useSelector((state) => state.language);
    let statusClassName =
        'padding-left status-label ' + statusColorClasses[status];
    const resolutionTextPermission = useCheckPermission('RESOLUTION_TEXT');

    return (
        <div className="flag-details-section">
            <h4>Details</h4>
            <table className="flag-details-tab">
                <tbody>
                    <tr>
                        <td>Flagged date:</td>
                        <td>{moment(date).format('M/D/YYYY, h:mm A')}</td>
                    </tr>
                    <tr>
                        <td>Status:</td>
                        <td>
                            <p className={statusClassName}>
                                {flagStatuses[status]}
                            </p>
                        </td>
                    </tr>
                    {resolutionTextPermission && (
                        <tr>
                            <td>Resolution status:</td>
                            <td>{resolutionStatuses[resolutionStatus]}</td>
                        </tr>
                    )}

                    <tr>
                        <td>{languageLables.rule[lg]}:</td>
                        <td>{rule}</td>
                    </tr>
                    <tr>
                        <td>{languageLables.tenant[lg]}:</td>
                        <td>{tenant}</td>
                    </tr>
                    <tr>
                        <td>{languageLables.data[lg]}:</td>
                        <td>{data}</td>
                    </tr>
                    <tr>
                        <td>Assignee:</td>
                        <td>{assignee}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}
