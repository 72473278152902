import { Button, Tooltip } from 'devextreme-react';
import { useState } from 'react';
import './saveBtn.scss';

export default function SaveBtn({ onSave, style, active, toolTipContent, id }) {
    const [tooltipVisible, setTooltipVisible] = useState(false);
    return (
        <div
            className="save-btn-wrapper"
            onMouseEnter={() => setTooltipVisible(true)}
            onMouseLeave={() => setTooltipVisible(false)}
            style={style}
        >
            {toolTipContent && (
                <Tooltip visible={tooltipVisible} target={`#${id}`}>
                    {active ? 'Save changes' : toolTipContent}
                </Tooltip>
            )}

            <div id={id}>
                <i
                    className={`dx-icon-save save-btn-${
                        active ? 'active' : 'disabled'
                    }`}
                    onClick={onSave}
                ></i>
                {/* <Button disabled={!active} icon="save" onClick={onSave} /> */}
            </div>
        </div>
    );
}
