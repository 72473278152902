import React, { useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import Chip from '../chip/chip';
import * as rulesService from '../../../services/rules';
import ConfirmPopup from '../../../components/confirmPopup/confirmPopup';
import { useSelector } from 'react-redux';
import { errorMsg, toastDisplayTime } from '../../../utils/vars';
import { Toast } from 'devextreme-react';
import update from 'immutability-helper';
import { useMemo } from 'react';
import { useEffect } from 'react';
import { useRuleContext } from '../ruleContext';
import DataFieldFunctions, {
    FunctionWrapper,
} from '../../dataFieldFunctons/dataFieldFunctions';
import { useQueryContext } from '../conditionsDropTarget/condition/queryContext';
import { useToastContext } from '../../../components/toast/toast';
import { getAxiosResponseError } from '../../../utils/functions';

export default function DataFieldsDropTarget({ dataFields, isSubquery }) {
    const { queryId } = useQueryContext();
    const theme = useSelector((state) => state.theme);
    const { refetchRule } = useRuleContext();
    const { showErrorToast } = useToastContext();

    let wrapperClasses = 'datafield-drop-target';
    wrapperClasses += theme === 'dark' ? ' paper-dark-theme' : ' paper';

    if (isSubquery) {
        wrapperClasses += ' paper-subquery';
    }
    async function addDataField(fieldId, displayedEntityId) {
        const postData = {
            queryId,
            displayedEntityId,
            fieldId,
        };
        try {
            await rulesService.postDataField(postData);
        } catch (err) {
            console.error(err);
            showErrorToast(getAxiosResponseError(err));
        }
        refetchRule();
    }

    const [_collectedProps, drop] = useDrop(() => ({
        accept: 'entity-drop-target',
        drop: (item) => {
            addDataField(item.data.id, item.displayedEntityId);
        },
    }));

    return (
        <div
            className={
                isSubquery
                    ? 'data-fields-wrapper width-50'
                    : 'data-fields-wrapper'
            }
        >
            <p className="section-label">Data fields</p>
            <div ref={drop} className={wrapperClasses}>
                {!dataFields?.length ? (
                    <div className="drop-here">
                        <p>Drop data field here</p>
                    </div>
                ) : (
                    <FieldsSection dataFields={dataFields} />
                )}
            </div>
        </div>
    );
}

function FieldsSection({ dataFields }) {
    const [fieldsData, setFieldsData] = useState();
    const memoizedData = useMemo(() => dataFields, [dataFields]);

    useEffect(() => {
        setFieldsData(dataFields);
    }, [memoizedData]);

    return (
        <div className="datafields-section">
            {fieldsData?.map((e, i) => (
                <DataField
                    key={i}
                    data={e}
                    from="data-field"
                    setFieldsData={setFieldsData}
                    fieldsData={fieldsData}
                />
            ))}
        </div>
    );
}

function DataField({ data, setFieldsData, fieldsData }) {
    const [dialogOpen, setDialogOpen] = useState(false);
    const ref = useRef(null);
    const { refetchRule } = useRuleContext();
    const hasFunction = !!data.entityField.entityFieldFunction;
    const { allowRuleEdit } = useRuleContext();
    const { showErrorToast } = useToastContext();

    function moveCard(id, afterId) {
        const index = fieldsData.findIndex((e) => e.id === id);
        const card = fieldsData[index];
        const afterIndex = fieldsData.findIndex((e) => e.id === afterId);
        const newArr = update(fieldsData, {
            $splice: [
                [index, 1],
                [afterIndex, 0, card],
            ],
        });
        setFieldsData(newArr);
    }

    async function removeDataField(id) {
        try {
            await rulesService.deleteDataField(id);
            refetchRule();
            setDialogOpen(false);
        } catch (err) {
            console.error(err);
            showErrorToast(getAxiosResponseError(err));
        }
    }
    const [{ isDragging, handlerId }, connectDrag] = useDrag({
        type: 'data-field',
        item: { id: data.id },
        collect: (monitor) => {
            const result = {
                handlerId: monitor.getHandlerId(),
                isDragging: monitor.isDragging(),
            };
            return result;
        },
    });

    const [, connectDrop] = useDrop({
        accept: 'data-field',
        hover({ id: draggedId }) {
            if (draggedId !== data.id) {
                moveCard(draggedId, data.id);
            }
        },
        drop: onDrop,
    });
    connectDrag(ref);
    connectDrop(ref);

    async function onDrop() {
        const mappedData = fieldsData.map((e, i) => {
            return { id: e.id, rowNumber: i };
        });
        try {
            await rulesService.reorderDataFields({ fields: mappedData });
            refetchRule();
        } catch (err) {
            console.error(err);
            showErrorToast(getAxiosResponseError(err));
        }
    }

    let element = getElement();

    function getElement() {
        const content = (
            <Chip
                drag={allowRuleEdit ? true : false}
                onDelete={() => setDialogOpen(true)}
                name={data?.entityField?.field?.name}
            />
        );

        if (!allowRuleEdit) {
            return content;
        }

        return !hasFunction ? (
            <>
                <DataFieldFunctions id={data.entityField.id} /> {content}
            </>
        ) : (
            <FunctionWrapper
                functionData={data?.entityField?.entityFieldFunction}
                content={content}
            />
        );
    }

    return (
        <>
            <div ref={allowRuleEdit ? ref : null}>{element}</div>
            <ConfirmPopup
                content={'Delete data field'}
                open={dialogOpen}
                onConfirm={() => removeDataField(data.id)}
                onCancel={() => setDialogOpen(false)}
            />
        </>
    );
}
