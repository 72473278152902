import { createSlice } from '@reduxjs/toolkit';

const rulesSearchParams = createSlice({
    name: 'rulesSearchParams',
    initialState: '',
    reducers: {
        setSearchParams: (state, { payload }) => {
            return payload;
        },
    },
});

export default rulesSearchParams.reducer;

export const { setSearchParams } = rulesSearchParams.actions;
