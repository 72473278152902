import React, { useState } from 'react';
import { useCheckPermission } from '../../../hooks/useCheckPermission';
import CustomStore from 'devextreme/data/custom_store';
import axios from '../../../services/axios/config';
import { errorMsg } from '../../../utils/vars';
import Loader from '../../../components/loader/loader';
import { DataGrid } from 'devextreme-react';
import { Column } from 'devextreme-react/data-grid';
import ExportButtons from '../exportButtons/exportButtons';

export default function DailyForOverlimit() {
    const [downloadLoading, setDownloadLoading] = useState(false);
    const [hasFetchError, setHasFetchError] = useState(true);
    const exportPermission = useCheckPermission('DOWNLOAD_REPORT');

    const customStore = new CustomStore({
        key: '',
        load(loadOptions) {
            const skip = loadOptions.skip;
            const take = loadOptions.take;
            const page = skip / take;
            return axios
                .post(`report?page=${page}&size=${take}`, {
                    type: 'DAILY',
                    forExport: false,
                    reportExportType: 'PDF',
                })
                .then((res) => {
                    const data = res.data;
                    setHasFetchError(false);
                    return {
                        data: data.data,
                        totalCount: data.elements,
                    };
                })
                .catch((err) => {
                    console.error(err);
                    setHasFetchError(true);
                    throw errorMsg;
                });
        },
    });

    if (downloadLoading) {
        return <Loader height={'70vh'} />;
    }

    return (
        <div className="reports-wrapper overlimit-wrapper">
            <div className="flex-container download-btns">
                {exportPermission && (
                    <ExportButtons
                        hasFilters={true}
                        filters={{}}
                        disabled={hasFetchError}
                        setLoading={setDownloadLoading}
                        reportType={'DAILY'}
                    />
                )}
            </div>
            <DataGrid
                allowColumnResizing={true}
                dataSource={customStore}
                remoteOperations={{ paging: true }}
            >
                <Column
                    allowSorting={false}
                    caption={'CID'}
                    dataField={'cid'}
                />
                <Column
                    allowSorting={false}
                    caption={'UIC'}
                    dataField={'uic'}
                />
                <Column
                    allowSorting={false}
                    caption={'Product'}
                    dataField={'product'}
                />
                <Column
                    allowSorting={false}
                    caption={'Limit'}
                    dataField={'limit'}
                />
                <Column
                    allowSorting={false}
                    caption={'Sum above one hundred'}
                    dataField={'sumAboveOnehundred'}
                />
                <Column
                    allowSorting={false}
                    caption={'Date'}
                    dataField={'date'}
                    dataType={'datetime'}
                />
                <Column
                    allowSorting={false}
                    caption={'Status'}
                    dataField={'status'}
                />
                <Column
                    allowSorting={false}
                    caption={'CID exists'}
                    dataField={'cidExists'}
                />
            </DataGrid>
        </div>
    );
}
