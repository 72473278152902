import { DataGrid, Popup } from 'devextreme-react';
import { Column, Paging } from 'devextreme-react/data-grid';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { useToastContext } from '../../../components/toast/toast';
import { getFlagHistory } from '../../../services/flag';
import { reactQueryOptions } from '../../../utils/reactQueryOptions';
import { flagStatuses, resolutionStatuses } from '../../../utils/vars';

export default function AmlHistoryPopup({ open, close }) {
    const { id } = useParams();
    const { showErrorToast } = useToastContext();

    const { data: historyData, error: dataFetchingError } = useQuery(
        'flag-history-data',
        () => getFlagHistory(id),
        reactQueryOptions
    );

    useEffect(() => {
        if (dataFetchingError) {
            showErrorToast(dataFetchingError.response?.data?.message);
        }
    }, [dataFetchingError]);

    // console.log(historyData);

    return (
        <Popup
            hideOnOutsideClick={true}
            visible={open}
            maxWidth={700}
            maxHeight={500}
            height={'auto'}
            onHiding={close}
            showTitle={false}
        >
            <DataGrid dataSource={historyData || []}>
                <Paging defaultPageSize={5} />
                <Column
                    allowSorting={false}
                    allowResizing={true}
                    caption={'Date'}
                    dataField={'dateCreated'}
                    dataType={'date'}
                />
                <Column
                    allowSorting={false}
                    allowResizing={true}
                    caption={'Resolution status'}
                    cellRender={(data) => {
                        return (
                            resolutionStatuses[data.data?.resolutionStatus] ||
                            data.data?.resolutionStatus
                        );
                    }}
                />
                <Column
                    allowSorting={false}
                    allowResizing={true}
                    caption={'Review status'}
                    cellRender={(data) => {
                        return (
                            flagStatuses[data.data?.reviewStatus] ||
                            data.data?.reviewStatus
                        );
                    }}
                />
                <Column
                    allowSorting={false}
                    allowResizing={true}
                    caption={'Employee'}
                    cellRender={(data) => data.data?.user?.name}
                />
            </DataGrid>
        </Popup>
    );
}
