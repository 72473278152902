import {
    Button,
    DataGrid,
    DateBox,
    NumberBox,
    SelectBox,
    TextBox,
    Toast,
    Tooltip,
    Validator,
} from 'devextreme-react';
import React from 'react';
import { useQuery } from 'react-query';
import { getSearchFields } from '../../services/report';
import { getAllTenants } from '../../services/other';
import Loader from '../../components/loader/loader';
import { useState } from 'react';
import './manualSearch.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { setFilters } from '../../redux/features/filters';
import CustomStore from 'devextreme/data/custom_store';
import axios from '../../services/axios/config';
import { Column } from 'devextreme-react/data-grid';
import { PatternRule } from 'devextreme-react/form';
import { changeStatusSearch } from '../../services/flag';
import { Link } from 'react-router-dom';
import { useRef } from 'react';
import { toastDisplayTime } from '../../utils/vars';
import { useToastContext } from '../../components/toast/toast';

export default function DefaultSearch() {
    const dispatch = useDispatch();

    useEffect(() => {
        return () => dispatch(setFilters({}));
    }, []);
    const [showTable, setShowTable] = useState(false);

    const {
        data: searchFieldsData,
        isLoading: loadingFieldsData,
        error: searchFiledsFetchError,
    } = useQuery('search-fields-data', getSearchFields, {
        refetchOnWindowFocus: false,
        retry: 1,
    });
    const {
        data: tenantsData,
        isLoading: loadingTenantsData,
        error: tenantsFetchError,
    } = useQuery('tenants-data', getAllTenants, {
        refetchOnWindowFocus: false,
        retry: 1,
    });
    const [selectValue, setSelectValue] = useState(null);
    const [filterValue, setFilterValue] = useState([]);
    const [initialFetchingToastConfig, setInitialFetchingToastConfig] =
        useState({
            type: 'error',
            visible: true,
            message: 'Something happened. Try again later.',
        });
    const columnsData = [];

    searchFieldsData?.baseSearch?.rule?.queries[0]?.dataFields?.forEach((e) => {
        if (e) {
            columnsData.push({
                label: e.entityField?.field?.name,
                key: e.entityField?.field?.tableFieldName,
            });
        }
    });

    if (loadingFieldsData || loadingTenantsData) {
        return <Loader height={'70vh'} />;
    }

    const tenantsSelectItems = tenantsData?.map((e) => {
        return { value: e.id, label: e.name };
    });

    function setFilter(id, value) {
        setFilterValue((oldState) => {
            const arrCopy = [...oldState];
            let index = -1;
            arrCopy.forEach((e, i) => {
                if (e.fieldId === id) {
                    index = i;
                }
            });

            if (value === '') {
                arrCopy.splice(index, 1);
            } else {
                if (index === -1) {
                    arrCopy.push({ fieldId: id, value });
                } else {
                    arrCopy[index] = { fieldId: id, value };
                }
            }
            return arrCopy;
        });
    }

    function hasFilterData() {
        if (!filterValue?.length) {
            return false;
        } else {
            return filterValue.some((e) => e.value !== '');
        }
    }

    function searchHandler() {
        setShowTable(true);

        dispatch(setFilters(filterValue));
    }

    const columnsDataStr = JSON.stringify(columnsData);

    return (
        <>
            {searchFiledsFetchError || tenantsFetchError ? (
                <Toast
                    visible={initialFetchingToastConfig.visible}
                    message={initialFetchingToastConfig.message}
                    type={initialFetchingToastConfig.type}
                    onHiding={() =>
                        setInitialFetchingToastConfig((oldState) => {
                            return { ...oldState, visible: false };
                        })
                    }
                    displayTime={toastDisplayTime}
                />
            ) : (
                <div className="default-search-wrapper">
                    <div className="header-section">
                        <Filters
                            fieldsData={searchFieldsData?.fields}
                            setFilter={setFilter}
                            tenantsSelectItems={tenantsSelectItems}
                            setSelectValue={setSelectValue}
                            selectValue={selectValue}
                        />
                        <div>
                            <Button
                                text="search"
                                disabled={!selectValue || !hasFilterData()}
                                onClick={searchHandler}
                                stylingMode="contained"
                                type="default"
                            />
                        </div>
                    </div>
                    <div className="table-wrapper">
                        <Table
                            searchId={searchFieldsData?.id}
                            ruleId={searchFieldsData?.baseSearch?.rule?.id}
                            show={showTable}
                            id={searchFieldsData?.id}
                            columnsData={columnsDataStr}
                        />
                    </div>
                </div>
            )}
        </>
    );
}

function Filters({
    setFilter,
    fieldsData,
    tenantsSelectItems,
    selectValue,
    setSelectValue,
}) {
    const [isValid, setIsValid] = useState(true);

    const elements = fieldsData?.map((e, i) => {
        if (!e) {
            return null;
        }
        const { field, id } = e;
        let el = null;

        switch (field.type) {
            case 'DATE':
                el = (
                    <DateBox
                        key={i}
                        width={270}
                        label={e.name}
                        onValueChange={(v) => setFilter(id, v)}
                    />
                );
                break;
            case 'NUMBER':
                if (e.name.toLowerCase() === 'uic') {
                    el = (
                        <NumberBox
                            stylingMode="underlined"
                            key={i}
                            defaultValue={null}
                            width={270}
                            label={e.name}
                            onInput={(ev) => {
                                const v = ev.event.currentTarget.value;
                                if (v.length !== 10 && v !== '') {
                                    setIsValid(false);
                                } else {
                                    setIsValid(true);
                                    setFilter(id, ev.event.currentTarget.value);
                                }
                            }}
                            isValid={isValid}
                        >
                            <Validator>
                                <PatternRule
                                    message="Please enter valid EGN"
                                    pattern={/^\d{10}$/}
                                />
                            </Validator>
                        </NumberBox>
                    );
                } else {
                    el = (
                        <NumberBox
                            stylingMode="underlined"
                            defaultValue={null}
                            key={i}
                            width={270}
                            label={e.name}
                            onInput={(ev) =>
                                setFilter(id, ev.event.currentTarget.value)
                            }
                        />
                    );
                }
                break;
            default:
                el = (
                    <TextBox
                        stylingMode="underlined"
                        key={i}
                        width={270}
                        label={e.name}
                        onInput={(ev) =>
                            setFilter(id, ev.event.currentTarget.value)
                        }
                    />
                );
                break;
        }

        return el;
    });

    return (
        <div className="filters-section">
            {elements}{' '}
            <div className="tenants-section">
                <SelectBox
                    value={selectValue || ''}
                    label="* Tenant"
                    items={tenantsSelectItems}
                    displayExpr={'label'}
                    valueExpr={'value'}
                    onValueChange={(v) => setSelectValue(v)}
                    stylingMode="underlined"
                />
            </div>
        </div>
    );
}

const Table = React.memo(function Table({
    show,
    id,
    columnsData,
    ruleId,
    searchId,
}) {
    const filter = useSelector((state) => state.filters);
    const [loading, setLoading] = useState(false);
    const { showSuccessToast, showErrorToast } = useToastContext();

    if (!show) {
        return null;
    }

    const columns =
        columnsData &&
        JSON.parse(columnsData).map((c, i) => {
            return (
                <Column
                    dataType={c.label.includes('date') ? 'datetime' : 'string'}
                    allowResizing={true}
                    allowSorting={false}
                    caption={c.label}
                    dataField={c.key}
                    key={i}
                />
            );
        });

    columns.push(
        <Column
            alignment={'center'}
            allowResizing={true}
            allowSorting={false}
            key={-1}
            caption={'Flagged'}
            cellRender={(data) => {
                if (!!data.data.flagId) {
                    return <LinkBtn flagId={data.data.flagId} />;
                } else {
                    return <i className="dx-icon-close"></i>;
                }
            }}
        />
    );

    function LinkBtn({ flagId }) {
        const ref = useRef();
        return (
            <>
                <Button onClick={() => ref.current.click()} text="Open" />
                <Link
                    ref={ref}
                    className="open-flag-btn"
                    to={`/flag-details/${flagId}`}
                    target="blank"
                />
            </>
        );
    }

    const customStore = new CustomStore({
        key: '',
        load(loadOptions) {
            const skip = loadOptions.skip;
            const take = loadOptions.take;
            const page = skip / take;

            return axios
                .post(`/search/base?id=${id}&page=${page}&size=${take}`, filter)
                .then((res) => {
                    const data = res.data;
                    const mapped = data?.columnData.map((e) => {
                        return { ...e.data, flagId: e.flagId };
                    });
                    return {
                        data: mapped,
                        totalCount: data.totalCount,
                    };
                })
                .catch((err) => {
                    console.error(err);
                    throw 'Seomething happend. Try again later.';
                });
        },
    });

    async function forMonitoringHandler() {
        try {
            await changeStatusSearch('FOR_MONITORING', ruleId, filter);
            showSuccessToast('Flag successfully added.')
        } catch (err) {
            console.error(err);
            showErrorToast(err?.response?.data?.message)
        }
    }

    function ExportButton() {
        const [tooltipVisible, setTootipVisible] = useState(false);
        return (
            <div className="export-btn-wrapper">
                <div
                    onMouseEnter={() => setTootipVisible(true)}
                    onMouseLeave={() => setTootipVisible(false)}
                    id="export-btn"
                >
                    <Button
                        stylingMode="outlined"
                        icon="exportxlsx"
                        onClick={exportHandler}
                    />
                </div>
                <Tooltip visible={tooltipVisible} target={'#export-btn'}>
                    Export in excel
                </Tooltip>
            </div>
        );
    }

    function exportHandler() {
        setLoading(true);
        // let postData = {};

        // const attribute = type === 'PDF' ? 'file.pdf' : 'file.xlsx'; 

        try {
            axios({
                url: `search/base/export?id=${searchId}&type=EXCEL`,
                method: 'POST',
                responseType: 'blob',
                data: filter,
            }).then((response) => {
                setLoading(false);
                const url = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'file.xlsx');
                document.body.appendChild(link);
                link.click();
            });
        } catch (err) {
            console.error(err);
        }
    }

    if (loading) {
        return <Loader height={'300px'} />;
    }

    return (
        <>
            <ExportButton />
            <DataGrid
                remoteOperations={{ paging: true }}
                dataSource={customStore}
            >
                {columns}
            </DataGrid>
            <div className="send-btn-wrapper">
                <Button
                    text="Send for monitoring"
                    stylingMode="outlined"
                    type="default"
                    onClick={forMonitoringHandler}
                />
            </div>
        </>
    );
});
