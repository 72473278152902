import axios from './axios/config';

export const create = async (data) => {
    const res = await axios.post('/rule', data);
    return res.data;
};

export const getAll = async () => {
    const res = await axios.get('/rule/all');
    return res.data;
};

export const getAllPaginated = async (
    page,
    size,
    q,
    direction,
    type,
    filterParams,
    rType
) => {
    let url = `rule/all/paginated?page=${page}&size=${size}&direction=${direction}&type=${type}${filterParams}&rType=${rType}`;
    if (q) {
        url += `&q=${q}`;
    }
    const res = await axios.get(url);
    const data = res.data;
    return { data: data.data, totalCount: data.elements };
};

export const getById = async (id) => {
    const res = await axios.get(`/rule?ruleId=${id}`);
    return res.data;
};

export const deleteRule = (id) => {
    return axios.delete(`rule?ruleId=${id}`);
};

export const attachEntity = async (data) => {
    const res = await axios.post(`/rule/attach`, data);
    return res.data;
};

export const getDisplayedEntities = async (id) => {
    const res = await axios.get(`rule/displayed-entities?ruleId=${id}`);
    return res.data;
};

export const postDataField = (data) => {
    return axios.post('rule/data-field', data);
};

export const deleteDataField = (id) => {
    return axios.delete('/rule/data-field', { data: { id } });
};

export const postConditionGroup = (data) => {
    return axios.post('rule/condition-group', data);
};

export const updateField = (data) => {
    return axios.put('rule/condition-field', data);
};

export const deleteCondition = (id) => {
    return axios.delete('rule/condition-field', { data: { id } });
};

export const createField = ({
    groupId,
    leftFieldId,
    rightFieldId,
    leftDisplayedEntityId,
    rightDisplayedEntityId,
    leftType,
    type
}) => {
    return axios.post('rule/condition-field', {
        leftDisplayedEntityId,
        value: null,
        rightDisplayedEntityId,
        leftFieldId,
        rightFieldId,
        conditionGroupId: groupId,
        conditionOperatorType: null,
        leftType,
        type
    });
};

export const deleteGroup = (id) => {
    return axios.delete('rule/condition-group', { data: { id } });
};

export const editGroup = (data) => {
    return axios.put('rule/condition-group', data);
};

export const deleteField = (data) => {
    return axios.delete('rule/condition-field-entity', { data });
};

export const getOperators = async () => {
    const res = await axios.get('rule/operator/all');
    return res.data;
};

export const postConditionFunction = (data) => {
    return axios.post('rule/condition-field-function', data);
};

export const deleteConditionFunction = (id) => {
    return axios.delete('rule/condition-field-function', { data: { id } });
};

export const getFunctions = async () => {
    const res = await axios.get('rule/function/all');
    return res.data;
};

export const postFieldFunction = (data) => {
    return axios.post('rule/condition-field-function', data);
};

export const deleteFieldFunction = (id) => {
    return axios.delete('rule/condition-field-function', { data: { id } });
};

export const getQueryTest = async (id) => {
    const res = await axios.get(`rule/sql?ruleId=${id}`);
    return res.data;
};

export const editEntitiyName = (data) => {
    return axios.put('rule/displayed-entity', data);
};

export const editRuleName = (data) => {
    return axios.put('rule/name', data);
};

export const changeRuleStatus = (data) => {
    return axios.put('rule/status', data);
};

export const getRuleHistory = async (id) => {
    const res = await axios.get(`rule/${id}/history`);
    return res.data;
};

export const getTestTableColumns = async (id) => {
    const res = await axios.get(`rule/columns/${id}`);
    return res.data;
};

export const getTestTableData = async (id, page, size) => {
    const res = await axios.get(
        `rule/columns/data/${id}?page=${page}&size=${size}`
    );
    return res.data;
};

export const postGroupBy = (data) => {
    return axios.post('rule/group-by', data);
};

export const deleteGroupBy = (id) => {
    return axios.delete('rule/group-by', { data: { id } });
};

export const editFieldName = (data) => {
    return axios.put('rule/entity-field/name', data);
};

export const cloneRule = (data) => {
    return axios.post('rule/clone', data);
};

export const postLimit = (data) => {
    return axios.put('rule/limit', data);
};

export const deleteLimit = (data) => {
    return axios.delete('rule/limit', { data });
};

export const reorderDataFields = (data) => {
    return axios.put('rule/data-field/reorder', data);
};

export const postOrderBy = (data) => {
    return axios.post('rule/order-by', data);
};

export const deleteOrderBy = (id) => {
    return axios.delete('rule/order-by', { data: { id } });
};

export const updateOrderBy = (data) => {
    return axios.put('rule/order-by', data);
};

export const postSubQuery = async (id) => {
    return axios.post(`query/${id}`);
};

export const deleteSubQuery = async (id) => {
    return axios.delete(`query/${id}`);
};

export const updateQueryName = (data) => {
    return axios.put('query/alias', data);
};

export const deleteConditionGroup = (data) => {
    return axios.delete('rule/condition-field', { data });
};

export const addEntityFieldFunction = (data) => {
    return axios.put('rule/entity-field/function', data);
};

export const deleteEntityFieldFunction = (data) => {
    return axios.delete('rule/entity-field/function', { data });
};

export const addConditionFunction = (data) => {
    return axios.post('rule/entity-field/function', data);
};

export const getLastActivatedRule = async() => {
    const res = await axios.get('rule/advanced/last-activated');
    return res.data;
}