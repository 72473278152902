import { Popover, Toast } from 'devextreme-react';
import React from 'react';
import { useState } from 'react';
import { useDrop } from 'react-dnd';
import { useSelector } from 'react-redux';
import { errorMsg, toastDisplayTime } from '../../../utils/vars';
import {
    deleteOrderBy,
    postOrderBy,
    updateOrderBy,
} from '../../../services/rules';
import Chip from './chip/chip';
import Stack from '@mui/material/Stack';
import { useFunctionsDataContext } from '../contexts/functionsDataContext';
import DataFieldFunctions, {
    FunctionWrapper,
} from '../../dataFieldFunctons/dataFieldFunctions';
import { useRuleContext } from '../ruleContext';
import { useQueryContext } from '../conditionsDropTarget/condition/queryContext';
import { useToastContext } from '../../../components/toast/toast';
import { getAxiosResponseError } from '../../../utils/functions';

export default function OrderByDropTarget({ orderByData, isSubquery }) {
    const { queryId } = useQueryContext();
    const { refetchRule } = useRuleContext();
    const theme = useSelector((state) => state.theme);
    let wrapperClasses = theme === 'dark' ? ' paper-dark-theme' : ' paper';
    const {showErrorToast} = useToastContext();

    if (isSubquery) {
        wrapperClasses += ' paper-subquery ';
    }

    const [_collectedProps, drop] = useDrop(() => ({
        accept: 'entity-drop-target',
        drop: (item) => {
            // addDataField(item.data.id, item.displayedEntityId);
            postOrderByHandler(item.data.id, item.displayedEntityId);
        },
    }));

    async function postOrderByHandler(fieldId, displayedEntityId) {
        const postData = {
            queryId,
            displayedEntityId,
            fieldId,
        };
        try {
            await postOrderBy(postData);
            refetchRule();
        } catch (err) {
            console.error(err);
            showErrorToast(getAxiosResponseError(err))
        }
    }

    return (
        <div
            className={
                isSubquery
                    ? 'order-by-drop-wrapper width-50'
                    : 'order-by-drop-wrapper'
            }
        >
            <p className="section-label">Order by</p>
            <div ref={drop} className={wrapperClasses}>
                <div className="orderByFields">
                    {orderByData?.map((e, i) => (
                        <OrderBy
                            key={i}
                            data={e}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}

function OrderBy({ data }) {
    const hasFunction = data?.entityField?.entityFieldFunction;
    const { refetchRule, allowRuleEdit } = useRuleContext();
    const {showErrorToast} = useToastContext();
    
    async function onDelete(id) {
        try {
            await deleteOrderBy(id);
            refetchRule();
        } catch (err) {
            console.error(err);
            showErrorToast(getAxiosResponseError(err))
        }
    }

    const element = getElement();

    function getElement() {
        const content = (
            <Chip
                name={data.entityField?.field?.name}
                onDelete={() => onDelete(data.id)}
                sortType={data.sortType}
                id={data.id}
            />
        );

        if (hasFunction) {
            return (
                <FunctionWrapper
                    functionData={data?.entityField?.entityFieldFunction}
                    content={content}
                />
            );
        } else if (allowRuleEdit) {
            return (
                <>
                    <DataFieldFunctions id={data.entityField.id} /> {content}
                </>
            );
        } else {
            return content;
        }
    }

    return <div>{element}</div>;
}
