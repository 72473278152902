import axios from './axios/config';

export const getActionsPermissions = async () => {
  const res = await axios.get('tenant/actions');
  return res.data;
}

export const changeAction = (data) => {
  return axios.post('admin/action', data);
}
