import { SelectBox } from 'devextreme-react';
import './tenants.scss';
import { getAllTenants } from '../../services/other';
import { useQuery } from 'react-query';

export default function Tenants({ setHasTenant }) {
    const {data: tenants, isLoading} = useQuery('tenants', getAllTenants)

    if (isLoading) {
        return null;
    }

    function clickHandler(selectedId) {
        const settings = tenants.find(t => t.id === selectedId);
        const hasAllSetting = !!settings?.clientId &&  !!settings?.redirectUrl && !!settings?.realm;

        if(!hasAllSetting) {
            return;
        }

        localStorage.setItem('tenantSettings', JSON.stringify(settings));
        setHasTenant(true);
    }

    return (
        <div className="tenants-wrapper">
            <div className="list-wrapper">
                <p className='label'>Please select your organization:</p>
                <SelectBox dataSource={tenants} displayExpr="name" valueExpr="id" onValueChange={clickHandler}/>
            </div>
        </div>
    );
}
