import React, { useState } from 'react';
import { useDrop } from 'react-dnd';
import Chip from '../chip/chip';
import * as rulesService from '../../../services/rules';
import ConfirmPopup from '../../../components/confirmPopup/confirmPopup';
import { useSelector } from 'react-redux';
import { toastDisplayTime } from '../../../utils/vars';
import { Toast } from 'devextreme-react';
import DataFieldFunctions, {
    FunctionWrapper,
} from '../../dataFieldFunctons/dataFieldFunctions';
import { useRuleContext } from '../ruleContext';
import { useQueryContext } from '../conditionsDropTarget/condition/queryContext';
import { useToastContext } from '../../../components/toast/toast';
import { getAxiosResponseError } from '../../../utils/functions';

export default function GroupBy({ groups, isSubquery, isTopLevel }) {
    const { queryId } = useQueryContext();
    const { refetchRule } = useRuleContext();
    const {showErrorToast} = useToastContext();

    const groupsEl = groups?.map((e, i) => (
        <DataField
            key={i}
            data={e}
            from="data-field"
        />
    ));
    const theme = useSelector((state) => state.theme);

    let wrapperClasses = 'datafield-drop-target';
    wrapperClasses += theme === 'dark' ? ' paper-dark-theme' : ' paper';

    if (isSubquery) {
        wrapperClasses += ' paper-subquery';
    }

    async function addDataField(fieldId, displayedEntityId) {
        const postData = {
            queryId,
            displayedEntityId,
            fieldId,
        };
        try {
            await rulesService.postGroupBy(postData);
        } catch (err) {
            console.error(err);
            showErrorToast(getAxiosResponseError(err));
        }
        refetchRule();
    }

    const [_collectedProps, drop] = useDrop(() => ({
        accept: 'entity-drop-target',
        drop: (item) => {
            addDataField(item.data.id, item.displayedEntityId);
        },
    }));

    return (
        <div
            className={
                isSubquery && isTopLevel
                    ? 'group-by-fields-wrapper width-50'
                    : 'group-by-fields-wrapper'
            }
        >
            <p className="section-label">Group by</p>
            <div ref={drop} className={wrapperClasses}>
                <div className="datafields-section">{groupsEl}</div>

                {!groups?.length && (
                    <div className="drop-here">
                        <p>Drop data field here</p>
                    </div>
                )}
            </div>
        </div>
    );
}

function DataField({ data }) {
    const [dialogOpen, setDialogOpen] = useState(false);
    const { refetchRule, allowRuleEdit } = useRuleContext();
    const hasFunction = !!data.entityField.entityFieldFunction;
    const {showErrorToast} = useToastContext();

    async function removeDataField(id) {
        try {
            await rulesService.deleteGroupBy(id);

            refetchRule();
            setDialogOpen(false);
        } catch (err) {
            console.error(err);
            showErrorToast(getAxiosResponseError(err));
        }
    }

    const element = getElement();

    function getElement(params) {
        const content = (
            <Chip
                onDelete={() => setDialogOpen(true)}
                name={data?.entityField?.field?.name}
            />
        );

        if (hasFunction) {
            return (
                <FunctionWrapper
                    functionData={data?.entityField?.entityFieldFunction}
                    content={content}
                    
                />
            );
        } else if (allowRuleEdit) {
            return (
                <>
                    <DataFieldFunctions id={data.entityField.id} /> {content}
                </>
            );
        } else {
            return content;
        }
    }

    return (
        <>
            <div>{element}</div>
            <ConfirmPopup
                content={'Delete data field'}
                open={dialogOpen}
                onConfirm={() => removeDataField(data.id)}
                onCancel={() => setDialogOpen(false)}
            />
        </>
    );
}
