import React from 'react'
import Table from '../../../components/rules/Table'

export default function Custom() {
  return (
    <div className='custom-reports-wrapper'>
        <Table rulesType ='custom'/>
    </div>
  )
}
