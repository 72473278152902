import { SelectBox } from 'devextreme-react';
import React from 'react';
import './placeholder.scss';

export default function Placeholder() {
    return (
        <div className="condition-group-placeholder flex-container">
            <div className="condition-placeholder dashed-border"></div>
            <SelectBox
                defaultValue={'='}
                width={80}
                items={['=']}
                disabled
                stylingMode="underlined"
            />
            <div className="condition-placeholder dashed-border"></div>
        </div>
    );
}

export const NestedPlaceholder = () => {
    return (
        <div className="condition-wrapper dashed-border">
            <Placeholder/>
        </div>
    );
};
