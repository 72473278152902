import { Button } from 'devextreme-react';
import React from 'react';
import axios from '../../services/axios/config';

export default function ExportBtn({ exportUrl, method, name }) {
    const downloadHandler = async () => {
        try {
            const response = await axios({
                url: exportUrl,
                method,
                responseType: 'blob',
              });

            const url = window.URL.createObjectURL(new Blob([response.data]));

            const anchor = document.createElement('a');
            anchor.style.display = 'none';
            anchor.href = url;
            anchor.download = `${name}.xlsx`;
            document.body.appendChild(anchor);
            anchor.click();

            document.body.removeChild(anchor);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };

    return (
        <Button
            stylingMode="outlined"
            icon="download"
            onClick={downloadHandler}
        />
    );
}
