import { createSlice } from '@reduxjs/toolkit';

const displayedEntities = createSlice({
    name: 'ruleDisplayedEntities',
    initialState: {},
    reducers: {
        setEntities: (state, { payload }) => {
            const { queryId, data } = payload;
            state[queryId] = data;
        },
    },
});

export default displayedEntities.reducer;

export const { setEntities } = displayedEntities.actions;

export const selectDisplayedEntities = (state) => state.ruleDisplayedEntities;
