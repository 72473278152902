import { Toast as DXToast } from 'devextreme-react';
import { createContext, useContext, useState } from 'react';
import { errorMsg, toastDisplayTime } from '../../utils/vars';

const context = createContext();

export const ToastContextProvider = ({ children }) => {
    //TODO: finish toast refactoring
    
    const [toastConfig, setToastConfig] = useState({
        type: 'success',
        visible: false,
        message: '',
    });
    const showSuccessToast = (message) =>
        setToastConfig({ visible: true, message, type: 'success' });
    const showErrorToast = (message) =>
        setToastConfig({
            visible: true,
            message: message || errorMsg,
            type: 'error',
        });
    const hideToast = () =>
        setToastConfig((oldState) => ({ ...oldState, visible: false }));

    const contextValue = {
        toastConfig,
        showSuccessToast,
        showErrorToast,
        hideToast,
    };

    return <context.Provider value={contextValue}>{children}</context.Provider>;
};

export const useToastContext = () => useContext(context);

export default function Toast() {
    const { toastConfig, hideToast } = useToastContext();

    return (
        <DXToast
            visible={toastConfig?.visible}
            message={toastConfig?.message}
            type={toastConfig?.type}
            onHiding={hideToast}
            displayTime={toastDisplayTime}
        />
    );
}
