import { createSlice } from '@reduxjs/toolkit';

const unselectTreeView = createSlice({
    name: 'unselectTreeView',
    initialState: 0,
    reducers: {
        unselect: (state) => {
            return state += 1;
        },
    },
});

export default unselectTreeView.reducer;

export const { unselect } = unselectTreeView.actions;

export const selectUnselect = state => state.unselectTreeView;