import axios from './axios/config';

export const getUser = async () => {
    const res = await axios.get('user');
    return res.data;
};

export const getTimezones = async () => {
    const res = await axios.get('user/zones');
    return res.data;
};

export const changeTimezone = async (value) => {
    const res = await axios.put('user', {
        timeZone: value,
    });
    return res.data;
};

export const homepage = async () => {
    const res = await axios.get('user/homepage');
    return res.data;
};

export const updateTenant = (data) => {
    return axios.put('tenant', data);
};

export const getCoordinators = async () => {
    const res = await axios.get('flag/cordinators');
    return res.data;
}