import axios from "axios";

export const getAllTenants = async () => {
    const data = await axios.get('https://d.infn.dev/trm/api/tenant/all');
    return data.data;
};

export const getLocalizedLabels = async () => {
    const data = await fetch('TranslationCroatia.json');
    return await data.json();
};


