import { Button, DataGrid, Toast, Tooltip } from 'devextreme-react';
import { Column, Paging } from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import { memo, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getTestTableColumns } from '../../../services/rules';
import axios from '../../../services/axios/config';
import { errorMsg, toastDisplayTime } from '../../../utils/vars';
import { changeStatusSearch } from '../../../services/flag';
import Loader from '../../../components/loader/loader';
import { useToastContext } from '../../../components/toast/toast';
import { getAxiosResponseError } from '../../../utils/functions';

export default function FilterTableSection({ ruleId, applyFilter, isInQuery }) {
    const [showTable, setShowTable] = useState(false);

    useEffect(() => {
        if (applyFilter) {
            setShowTable(true);
        }
    }, [applyFilter]);

    return (
        <div
            className={
                isInQuery
                    ? 'query-table-wrapper dashboard-table-wrapper'
                    : 'dashboard-table-wrapper'
            }
        >
            {showTable && <Table ruleId={ruleId} applyFilter={applyFilter} />}
        </div>
    );
}

const Table = memo(function Table({ ruleId, applyFilter }) {
    const {
        data: columnsData,
        isLoading,
        refetch,
        isFetching,
    } = useQuery('testColumnsData', () => getTestTableColumns(ruleId), {
        refetchOnWindowFocus: false,
    });
    const [customStore, setCustomStore] = useState();
    const [errors, setErrors] = useState(true);
    const [loading, setLoading] = useState(false);
    const { showErrorToast, showSuccessToast } = useToastContext();

    useEffect(() => {
        if (applyFilter) {
            refetch();
            const store = new CustomStore({
                key: '',
                load(loadOptions) {
                    const skip = loadOptions.skip;
                    const take = loadOptions.take;
                    const page = skip / take;

                    return axios
                        .get(
                            `rule/columns/data/${ruleId}?page=${page}&size=${take}`
                        )
                        .then((res) => {
                            setErrors(false);
                            const data = res.data;
                            const mapped = data.columnData.map((e) => e.data);
                            return {
                                data: mapped,
                                totalCount: data.totalCount,
                            };
                        })
                        .catch((err) => {
                            console.error(err);
                            setErrors(true);
                            throw err.response?.data?.message || errorMsg;
                        });
                },
            });
            setCustomStore(store);
        }
    }, [applyFilter]);

    if (isLoading || isFetching || !customStore) {
        return <Loader height={'50px'} />;
    }
    const columns = columnsData?.map((c, i) => (
        <Column
            dataType={c.includes('date') ? 'datetime' : 'string'}
            caption={c}
            dataField={c}
            key={i}
            allowFiltering={false}
            allowSorting={false}
        />
    ));

    async function forMonitoringHandler() {
        try {
            await changeStatusSearch('FOR_MONITORING', ruleId);
            showSuccessToast('Flag successfully added.');
        } catch (err) {
            console.error(err);
            showErrorToast(getAxiosResponseError(err));
        }
    }

    if (loading) {
        return <Loader height={'300px'} />;
    }

    return (
        <>
            {!errors && (
                <ExportButton setLoading={setLoading} ruleId={ruleId} />
            )}

            <DataGrid
                keyExpr={'id'}
                remoteOperations={{
                    paging: true,
                }}
                dataSource={customStore}
            >
                {columns}
                <Paging pageSize={10} />
            </DataGrid>
            {!errors && (
                <div className="send-btn-wrapper">
                    <Button
                        text="send for monitoring"
                        type="default"
                        stylingMode="outlined"
                        onClick={forMonitoringHandler}
                    />
                </div>
            )}
        </>
    );
});

function ExportButton({ setLoading, ruleId }) {
    const [tooltipVisible, setTootipVisible] = useState(false);
    const { showErrorToast } = useToastContext();

    function exportHandler() {
        setLoading(true);
        // let postData = {};

        // const attribute = type === 'PDF' ? 'file.pdf' : 'file.xlsx';

        axios({
            url: `search/advanced/export?ruleId=${ruleId}&type=EXCEL`,
            method: 'POST',
            responseType: 'blob',
        })
            .then((response) => {
                setLoading(false);
                const url = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'file.xlsx');
                document.body.appendChild(link);
                link.click();
            })
            .catch((err) => {
                console.error(err);
                setLoading(false);
                showErrorToast(getAxiosResponseError(err));
            });
    }
    return (
        <div className="export-btn-wrapper">
            <div
                onMouseEnter={() => setTootipVisible(true)}
                onMouseLeave={() => setTootipVisible(false)}
            >
                <Button
                    id="export-btn"
                    stylingMode="outlined"
                    icon="exportxlsx"
                    onClick={exportHandler}
                />
            </div>
            <Tooltip visible={tooltipVisible} target={'#export-btn'}>
                Export in excel
            </Tooltip>
        </div>
    );
}
