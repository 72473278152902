import { Button } from 'devextreme-react';
import { useParams } from 'react-router-dom';
import { useToastContext } from '../../../components/toast/toast';
import { assignFlag } from '../../../services/flag';

export default function Assign({ refetch }) {
    const { id } = useParams();
    const { showErrorToast } = useToastContext();

    async function assignHandler() {
        try {
            await assignFlag({
                flagId: id,
                assign: true,
            });
            refetch();
        } catch (err) {
            console.error(err);
            showErrorToast(err.response?.data?.message);
        }
    }
    return (
        <div>
            <Button
                stylingMode="outlined"
                type="default"
                text={'Assign'}
                onClick={assignHandler}
            />
        </div>
    );
}
