import { useFieldsGroupContext } from '../pages/ruleDashboard/conditionsDropTarget/condition/fieldsGroupContext';


export const useGetDefaultPutData = () => {
    const { fieldsGroupData } = useFieldsGroupContext();

    const getDefaultPutData = () => {
        return {
            id: fieldsGroupData.id,
            value: fieldsGroupData?.rightPart?.value,
            conditionOperatorType: fieldsGroupData?.operator?.type,
            conditionInterval: fieldsGroupData?.rightPart?.conditionInterval,
            leftConditionInterval: fieldsGroupData?.leftPart?.conditionInterval,
            sign: fieldsGroupData?.rightPart?.sign,
            leftSign: fieldsGroupData?.leftPart?.sign,
            leftValue: fieldsGroupData?.leftPart?.value,
            type: fieldsGroupData?.rightPart?.type,
            leftType: fieldsGroupData?.leftPart?.type,
        };
    };
    return getDefaultPutData;
};
