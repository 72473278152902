import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useCheckPermission } from '../../../hooks/useCheckPermission';
import { useToastContext } from '../../../components/toast/toast';
import moment from 'moment';
import { generate } from '../../../services/report';
import { getAxiosResponseError } from '../../../utils/functions';
import Loader from '../../../components/loader/loader';
import ExportButtons from '../exportButtons/exportButtons';
import {
    Accordion,
    Button,
    DateBox,
    SelectBox,
    TextBox,
} from 'devextreme-react';
import { languageLables } from '../../../utils/vars';
import DataGrid, { Column } from 'devextreme-react/data-grid';
import Stack from '@mui/material/Stack';
import Pagination from '@mui/material/Pagination';

function AccordionComponent({ dataSource }) {
    const selectedItems = [];

    function MultiTable(data) {
        return (
            <div>
                <DataGrid
                    paging={{ enabled: true, pageSize: 5 }}
                    allowColumnResizing={true}
                    keyExpr={'amlRisk'}
                    dataSource={data.flags}
                >
                    <Column
                        allowSorting={false}
                        caption="Type of rule"
                        dataField={'typeOfRule'}
                    />
                    <Column
                        allowSorting={false}
                        caption="Rule name"
                        dataField={'ruleName'}
                    />
                    <Column
                        allowSorting={false}
                        caption="Case status"
                        dataField={'caseStatus'}
                    />
                    <Column
                        allowSorting={false}
                        caption="Resolution status"
                        dataField={'resolutionStatus'}
                    />
                    <Column
                        allowSorting={false}
                        caption="AML risk"
                        dataField={'amlRisk'}
                    />
                    <Column
                        allowSorting={false}
                        caption="Amount"
                        dataField={'amount'}
                    />
                </DataGrid>
            </div>
        );
    }

    return (
        <div className="accordion-wrapper">
            <Accordion
                selectedItems={selectedItems}
                itemTitleRender={(data) => `UIC - ${data.uic}`}
                dataSource={dataSource}
                itemRender={MultiTable}
            ></Accordion>
        </div>
    );
}

export default function MultipleReport() {
    const [filtersValues, setFiltersValues] = useState({});
    const lg = useSelector((state) => state.language);
    const [downloadLoading, setDownloadLoading] = useState(false);
    const [refetch, setRefetch] = useState(0);
    const [reportData, setReportData] = useState(null);
    const [page, setPage] = useState(0);
    const [isLoadingReport, setIsLoadingReport] = useState(false);
    const size = 10;
    const [pages, setPages] = useState(null);
    const exportPermission = useCheckPermission('DOWNLOAD_REPORT');
    const { showErrorToast } = useToastContext();

    useEffect(() => {
        if (refetch) {
            generateReport(null, true);
        }
    }, [refetch]);

    async function generateReport(_ev, isRefetch) {
        if (!isRefetch) {
            setPage(0);
        }
        setIsLoadingReport(true);
        setReportData(null);

        const postData = {
            periodFrom:
                (filtersValues?.periodFrom &&
                    moment(filtersValues.periodFrom).format('YYYY-MM-DD')) ||
                '',
            periodTo:
                (filtersValues?.periodFrom &&
                    moment(filtersValues.periodTo).format('YYYY-MM-DD')) ||
                '',
            type: 'MULTI',
            clientStatus: filtersValues?.clientStatus || '',
            changedLevelRisk: filtersValues?.changedLevelRisk || '',
            typeOfRule: filtersValues?.typeOfRule || '',
            ruleName: filtersValues?.ruleName || '',
            caseStatus: filtersValues?.caseStatus || '',
            resolutionStatus: filtersValues?.resolutionStatus || '',
            forExport: false,
            riskLevel: filtersValues?.riskLevel || '',
        };

        try {
            const data = await generate(isRefetch ? page : 0, size, postData);
            setPages(data?.elements / size);

            setReportData(data.data);
        } catch (err) {
            console.error(err);
            showErrorToast(getAxiosResponseError(err));
        }
        setIsLoadingReport(false);
    }

    function updateFilterValue(key, value) {
        setFiltersValues((oldState) => {
            return { ...oldState, [key]: value };
        });
    }

    if (downloadLoading) {
        return <Loader height={'70vh'} />;
    }

    function pagingHandler(newPage) {
        setPage(newPage);
        setRefetch((oldState) => oldState + 1);
    }

    return (
        <div className="reports-wrapper">
            <div className="flex-container download-btns">
                <div>
                    {exportPermission && (
                        <ExportButtons
                            filters={filtersValues}
                            disabled={!reportData || !reportData?.length}
                            setLoading={setDownloadLoading}
                            reportType={'MULTI'}
                        />
                    )}
                </div>
                <Stack gap={'10px'} flexDirection={'row'}>
                    <Button
                        text="Clear"
                        disabled={
                            filtersValues && !Object.keys(filtersValues).length
                        }
                        onClick={() => {
                            setReportData(null);
                            setFiltersValues({});
                        }}
                    />

                    <Button
                        text={languageLables.generateReport[lg]}
                        onClick={generateReport}
                        type="default"
                    />
                </Stack>
            </div>
            <div className="filters-section">
                <DateBox
                    stylingMode="underlined"
                    width={270}
                    label="From date"
                    value={filtersValues.periodFrom || null}
                    onValueChange={(v) => updateFilterValue('periodFrom', v)}
                />
                <DateBox
                    stylingMode="underlined"
                    width={270}
                    label="To date"
                    value={filtersValues.periodTo || null}
                    onValueChange={(v) => updateFilterValue('periodTo', v)}
                />
                <SelectBox
                    stylingMode="underlined"
                    width={270}
                    value={filtersValues.clientStatus || ''}
                    items={[
                        { label: 'Active', value: 'active' },
                        { label: 'Inactive', value: 'inactive' },
                    ]}
                    label="Client status"
                    displayExpr={'label'}
                    valueExpr="value"
                    onValueChange={(v) => updateFilterValue('clientStatus', v)}
                />
                <SelectBox
                    stylingMode="underlined"
                    width={270}
                    value={filtersValues.changedLevelRisk || ''}
                    items={[
                        { label: 'Yes', value: 'yes' },
                        { label: 'No', value: 'no' },
                    ]}
                    label="Changed level risk"
                    displayExpr={'label'}
                    valueExpr="value"
                    onValueChange={(v) =>
                        updateFilterValue('changedLevelRisk', v)
                    }
                />
                <SelectBox
                    stylingMode="underlined"
                    width={270}
                    value={filtersValues.typeOfRule || ''}
                    items={[
                        { label: 'RTM', value: 'RTM' },
                        { label: 'PTM', value: 'PTM' },
                    ]}
                    label="Type of rule"
                    displayExpr={'label'}
                    valueExpr="value"
                    onValueChange={(v) => updateFilterValue('typeOfRule', v)}
                />
                <TextBox
                    width={270}
                    stylingMode="underlined"
                    label="Rule name"
                    value={filtersValues.ruleName || ''}
                    onInput={(ev) =>
                        updateFilterValue('ruleName', ev.event.target.value)
                    }
                />
                <SelectBox
                    stylingMode="underlined"
                    width={270}
                    value={filtersValues.caseStatus || ''}
                    items={[
                        { label: 'Pending', value: 'PENDING' },
                        { label: 'In progress', value: 'IN_PROGRESS' },
                        { label: 'Completed', value: 'COMPLETED' },
                        { label: 'Escalated', value: 'ESCALATED' },
                    ]}
                    label="Case status"
                    displayExpr={'label'}
                    valueExpr="value"
                    onValueChange={(v) => updateFilterValue('caseStatus', v)}
                />
                <SelectBox
                    stylingMode="underlined"
                    width={270}
                    value={filtersValues.resolutionStatus || ''}
                    items={[
                        {
                            label: 'Document Enquiry',
                            value: 'DOCUMENT_ENQUIRY',
                        },
                        {
                            label: 'For Investigation',
                            value: 'FOR_INVESTIGATION',
                        },
                        { label: 'False Positive', value: 'FALSE_POSITIVE' },
                        {
                            label: 'Termination of the contract',
                            value: 'TERMINATION_OF_CONTRACT',
                        },
                        {
                            label: 'Stop the operation',
                            value: 'STOP_OPERATION',
                        },
                        { label: 'For Reporting', value: 'FOR_REPORTING' },
                        { label: 'For Monitoring', value: 'FOR_MONITORING' },
                    ]}
                    label="Resolution status"
                    displayExpr={'label'}
                    valueExpr="value"
                    onValueChange={(v) =>
                        updateFilterValue('resolutionStatus', v)
                    }
                />
                <SelectBox
                    stylingMode="underlined"
                    width={270}
                    value={filtersValues.riskLevel || ''}
                    items={[
                        { label: 'Low', value: 'low' },
                        { label: 'High', value: 'high' },
                        { label: 'Medium', value: 'medium' },
                        { label: 'All', value: 'all' },
                    ]}
                    label="Risk level"
                    displayExpr={'label'}
                    valueExpr="value"
                    onValueChange={(v) => updateFilterValue('riskLevel', v)}
                />
            </div>
            {!!reportData && !reportData.length && (
                <p className="no-data">No data</p>
            )}
            {isLoadingReport && <Loader height={'300px'} />}
            {!!reportData && !!reportData.length && (
                <AccordionComponent dataSource={reportData} />
            )}
            {reportData && !!reportData.length && pages && pages > 1 && (
                <Stack
                    sx={{ marginTop: '10px' }}
                    flexDirection={'row'}
                    className="pagination-wrapper"
                    justifyContent={'end'}
                >
                    <Pagination
                        page={page + 1}
                        count={pages}
                        hideNextButton={true}
                        hidePrevButton={true}
                        onChange={(_ev, page) => pagingHandler(page - 1)}
                    />
                </Stack>
            )}
        </div>
    );
}
