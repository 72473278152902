import { Button } from 'devextreme-react';
import { useParams } from 'react-router-dom';
import { useToastContext } from '../../../components/toast/toast';
import { assignFlag } from '../../../services/flag';
import { useCheckPermission } from '../../../hooks/useCheckPermission';

export default function Unlock({ refetch, rowFlagId }) {
    const { id: paramsId } = useParams();
    const { showErrorToast, showSuccessToast } = useToastContext();
    const unlockRolePermission = useCheckPermission('UNLOCK');

    async function assignHandler(ev) {
        ev.event.stopPropagation();
        try {
            await assignFlag({
                flagId: rowFlagId || paramsId,
                assign: false,
            });
            showSuccessToast('Flag successfully unlocked')
            refetch();
        } catch (err) {
            console.error(err);
            showErrorToast(err.response?.data?.message);
        }
    }

    if (!unlockRolePermission) {
        return null;
    }

    return (
        <div>
            <Button
                stylingMode="outlined"
                type="default"
                text={'Unlock'}
                onClick={assignHandler}
            />
        </div>
    );
}
