import { useRuleContext } from '../ruleContext';

export default function Chip({ name, onDelete, drag }) {
    const { allowRuleEdit } = useRuleContext();

    return (
        <div className="my-chip">
            <div className="chip-flex-wrapper">
                {drag && (
                    <div className="drag-chip-icon">
                        <i className="dx-icon-dragvertical"></i>
                    </div>
                )}
                <p>{name}</p>
                {allowRuleEdit && (
                    <div
                        className="chip-delete-wrapper"
                        onClick={(ev) => {
                            ev.stopPropagation();
                            onDelete();
                        }}
                    >
                        <i className="dx-icon-close"></i>
                    </div>
                )}
            </div>
        </div>
    );
}
