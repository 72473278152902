import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import Loader from '../../../components/loader/loader';
import * as rulesService from '../../../services/rules';
import { useFunctionsDataContext } from '../contexts/functionsDataContext';
import ConditionGroup from './condition/conditionGroup';
import { ConditionContextProvider } from './condition/conditionsContext';
import { useQueryContext } from './condition/queryContext';

export default function ConditionsDropTarget({
    ruleId,
    hasSubQueries,
    isSubquery,
    forJoin,
    conditions,
}) {
    const { data: operatorsData, isLoading } = useQuery(
        'operators',
        rulesService.getOperators,
        {
            refetchOnWindowFocus: false,
        }
    );
    const { queryId } = useQueryContext();

    // const { data: functionsData } = useQuery(
    //     'functions',
    //     rulesService.getFunctions,
    //     {
    //         refetchOnWindowFocus: false,
    //     }
    // );

    const theme = useSelector((state) => state.theme);
    let wrapperClasses = 'conditions-drop-wrapper';

    wrapperClasses += theme === 'dark' ? ' paper-dark-theme' : ' paper';

    if (isSubquery) {
        wrapperClasses += ' paper-subquery';
    }

    if (isLoading) {
        return (
            <div style={{ flexGrow: 1 }}>
                <Loader height={'200px'} />
            </div>
        );
    }

    return (
        <div
            className={`conditions-section ${
                hasSubQueries && 'with-queries-width'
            }`}
        >
            <p className="section-label">{forJoin ? 'Joins' : 'Conditions'}</p>
            <ConditionContextProvider
                value={{
                    operatorsData,
                    ruleId,
                    forJoin,
                }}
            >
                <div className={wrapperClasses}>
                    <ConditionGroup
                        i={-1}
                        isTopLevel={true}
                        conditionGroupData={(conditions && conditions[0] )|| { fields: [] }}
                    />
                </div>
            </ConditionContextProvider>
        </div>
    );
}
