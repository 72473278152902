import React, { useState } from 'react';
import './descriptionSection.scss';
import { useRuleContext } from '../ruleContext';

export default function DescriptionSection() {
    const [showFullContent, setShowFullContent] = useState(false);
    const { rule } = useRuleContext();
    const description = rule?.description;

    console.log(description?.length);
    
    if (description?.length < 250 || !description) {
        return <div className="description-container">{description}</div>;
    }

    const toggleHandler = () => {
        setShowFullContent(!showFullContent);
    };

    return (
        <div className="description-container">
            {showFullContent ? description : description?.substring(0, 170)}
            {showFullContent ? (
                <span className="less-btn" onClick={toggleHandler}>
                    {' '}
                    less
                </span>
            ) : (
                <span className="more-btn" onClick={toggleHandler}>
                    {' '}
                    ... <span className="text">more</span>
                </span>
            )}{' '}
        </div>
    );
}
