import {
    RulesPage,
    MonitoringQueuePage,
    ReportsPage,
    AuditTrailPage,
    RuleDashboardPage,
} from './pages';
import { withNavigationWatcher } from './contexts/navigation';
import FlagDetails from './pages/flagDetails/flagDetails';
import Settings from './pages/settings/settings';
import { ActivityLog } from './pages/activityLog/activityLog';
import ManualSearch from './pages/manualSearch/manualSearch';
import { useActionsRoleContext } from './contexts/actionsRoleProvider';
import { useCheckPermission } from './hooks/useCheckPermission';
import Home from './pages/home/home';
import AdvancedSearch from './pages/advancedSearch/advancedSearch';
import SingleReports from './pages/reports/singleReport/singleReport';
import MultipleReport from './pages/reports/multipleReport/multipleReport';
import DailyForOverlimit from './pages/reports/dailyForOverlimit/dailyForOverlimit';
import ForMonitoring from './pages/reports/forMonitoring/forMonitoring';
import Custom from './pages/reports/custom/custom';

export default function useGetRoutes() {
    const { tenancy } = useActionsRoleContext();
    const hasReportsPermission = useCheckPermission('REVIEW_REPORTS');
    const hasAuditTrailPermission = useCheckPermission('REVIEW_AUDIT');
    const hasLogsPermission = useCheckPermission('LOGS');
    const hasMonitoringPermission = useCheckPermission(
        tenancy === 'SINGLE' ? 'REVIEW_MONITORING' : 'SEARCH_REVIEW_MONITORING'
    );
    const hasManualSearchPermission = useCheckPermission(
        tenancy === 'SINGLE' ? 'MANUAL_SEARCH' : 'MANUAL_SEARCH_MULTI'
    );

    const routes = [
        {
            path: '',
            element: Home,
        },
        {
            path: '/rules/*',
            element: RulesPage,
        },
        {
            path: '/rule-dashboard/:id',
            element: RuleDashboardPage,
        },
    ];

    if (hasMonitoringPermission) {
        routes.push({
            path: '/monitoring',
            element: MonitoringQueuePage,
        });

        routes.push({
            path: '/flag-details/:id',
            element: FlagDetails,
        });
    }

    if (hasReportsPermission) {
        routes.push({
            path: '/reports/*',
            element: ReportsPage,
        });
        routes.push(({
            path: '/reports-single/*',
            element: SingleReports,
        } ))
        routes.push(({
            path: '/reports-multiple/*',
            element: MultipleReport,
        } ))
        routes.push(({
            path: '/reports-daily-overlimit/*',
            element: DailyForOverlimit,
        } ))
        routes.push(({
            path: '/reports-for-monitoring/*',
            element: ForMonitoring,
        } ))
        routes.push({
            path: '/reports-custom/*',
            element: Custom,
        })
    }

    if (hasAuditTrailPermission) {
        routes.push({
            path: '/audit-trail',
            element: AuditTrailPage,
        });
    }

    if (hasLogsPermission) {
        routes.push({
            path: 'activity-log',
            element: ActivityLog,
        });
    }

    if (hasManualSearchPermission) {
        routes.push({
            path: 'manual-search',
            element: ManualSearch,
        });
        routes.push({
            path: 'advanced-search',
            element: AdvancedSearch,
        });
    }


    routes.push({
        path: '/settings',
        element: Settings,
    });

    return routes.map((route) => {
        return {
            ...route,
            element: withNavigationWatcher(route.element, route.path),
        };
    });
}
