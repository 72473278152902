import React, { useState } from 'react';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import Button from 'devextreme-react/button';
import UserPanel from '../user-panel/UserPanel';
import './Header.scss';
import { ContextMenu, Popup, SelectBox } from 'devextreme-react';
import { Position } from 'devextreme-react/data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { changeLanguage } from '../../redux/features/appSettings/language';
import { useGetLocalizedLabel } from '../../utils/vars';
import { useKeycloak } from '../../App';
import { rolesLabels } from '../../utils/vars';
import { clientBaseUrl } from '../../services/urls';
import { useActionsRoleContext } from '../../contexts/actionsRoleProvider'; 

export default function Header({ menuToggleEnabled, title, toggleMenu }) {
    const [settingsPopupOpen, setSeetingsPopupOpen] = useState(false);

    return (
        <header className={'header-component'}>
            <Toolbar className={'header-toolbar'}>
                <Item
                    visible={menuToggleEnabled}
                    location={'before'}
                    widget={'dxButton'}
                    cssClass={'menu-button'}
                >
                    <Button
                        icon="menu"
                        stylingMode="text"
                        onClick={toggleMenu}
                    />
                </Item>
                <Item
                    location={'before'}
                    cssClass={'header-title'}
                    text={title}
                    visible={!!title}
                />
                <Item
                    location={'after'}
                    locateInMenu={'auto'}
                    menuItemTemplate={'userPanelTemplate'}
                >
                    <UserMenu
                        onSettingsOpen={() => {
                            setSeetingsPopupOpen(true);
                        }}
                    />
                    {/* <Button
            className={'user-button authorization'}
            width={210}
            height={'100%'}
            stylingMode={'text'}
          >
            <UserPanel menuMode={'context'} />
          </Button> */}
                </Item>
                {/* <Template name={'userPanelTemplate'}>
                    <UserPanel menuMode={'list'} />
                </Template> */}
            </Toolbar>
            <AppSettingsPopup
                close={() => setSeetingsPopupOpen(false)}
                open={settingsPopupOpen}
            />
        </header>
    );
}

function UserMenu({ onSettingsOpen }) {
    const { logout } = useKeycloak();
    const { name } = useKeycloak();
    const { role } = useActionsRoleContext();
    const getLocalized = useGetLocalizedLabel();

    const tenant = JSON.parse(localStorage.getItem('tenantSettings') || {});

    function logoutHandler() {
        localStorage.removeItem('tenantSettings');
        logout({ redirectUri: clientBaseUrl });
    }
    const menuItems = [
        // {
        //   text: 'Profile',
        //   icon: 'user',
        //   onClick: navigateToProfile
        // },
        {
            text: getLocalized('logout'),
            icon: 'runner',
            onClick: logoutHandler,
        },
    ];

    return (
        <>
            <div className="user-section">
                <p>
                    {name} | {rolesLabels[role]} | {tenant.name}
                </p>
                <Button icon="user" />
            </div>
            <ContextMenu
                items={menuItems}
                target={'.user-section'}
                showEvent={'dxclick'}
                width={210}
                cssClass={'user-menu'}
            >
                <Position my={'top center'} at={'bottom center'} />
            </ContextMenu>
        </>
    );
}

function AppSettingsPopup({ open, close }) {
    const [selectedLanguage, setSelectedLanguage] = useState();
    const lg = useSelector((state) => state.language);
    const dispatch = useDispatch();
    const languageValues = {
        Bulgarian: 'bg',
        English: 'en',
    };
    const items = ['Bulgarian', 'English'];
    let defaultLanguage = '';
    Object.keys(languageValues).forEach((k) => {
        if (languageValues[k] === lg) {
            defaultLanguage = k;
        }
    });
    const getLocalized = useGetLocalizedLabel();

    function saveBtnHandler() {
        localStorage.setItem('tm-language', selectedLanguage);
        dispatch(changeLanguage(selectedLanguage));

        close();
    }

    return (
        <Popup
            dragEnabled={false}
            title={getLocalized('settings')}
            hideOnOutsideClick={true}
            visible={open}
            onHiding={close}
            width={400}
            height={'auto'}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                }}
            >
                <div style={{ flexGrow: 1 }}>
                    <div className="language-section">
                        <p>{getLocalized('language')}</p>
                        <SelectBox
                            defaultValue={defaultLanguage}
                            width={200}
                            items={items}
                            onValueChange={(v) =>
                                setSelectedLanguage(languageValues[v])
                            }
                        />
                    </div>
                </div>
                <div
                    style={{
                        marginBottom: '10px',
                        display: 'flex',
                        justifyContent: 'end',
                    }}
                >
                    <Button
                        type="default"
                        text={getLocalized('save')}
                        onClick={saveBtnHandler}
                    />
                </div>
            </div>
        </Popup>
    );
}
