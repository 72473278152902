import { useCallback, useRef, useState } from "react";

export default function useThrottle(delay) {
    const [value, setValue] = useState('');
    const timeoutRef = useRef(null);

    const throttledSetState = useCallback(
        (newValue) => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
            timeoutRef.current = setTimeout(() => {
                setValue(newValue);
            }, delay);
        },
        [delay]
    );

    const handleChange = useCallback(
        (event) => {
            const newValue = event.target.value;
            throttledSetState(newValue);
        },
        [throttledSetState]
    );

    return [value, handleChange];
}