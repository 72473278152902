import Stack from '@mui/system/Stack';
import { Popover } from 'devextreme-react';
import { nanoid } from 'nanoid';
import { useState } from 'react';
import {
    addConditionFunction,
    deleteEntityFieldFunction,
    updateField,
} from '../../../../../services/rules';
import { useFunctionsDataContext } from '../../../contexts/functionsDataContext';
import { useRuleContext } from '../../../ruleContext';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { useToastContext } from '../../../../../components/toast/toast';
import { getAxiosResponseError } from '../../../../../utils/functions';
import { useFieldsGroupContext } from '../fieldsGroupContext';
import { useGetDefaultPutData } from '../../../../../hooks/useGetDefaultPostData';

export default function MoreActions({
    setNameEditMode,
    parentFunctionId,
    showFunctionsPopover,
    setFieldType,
    showChangeAlias,
    id,
    fieldType,
    isRightField,
    allowValueEntry,
    allowDateEntry
}) {
    const [popoverOpen, setPopoverOpen] = useState(false);
    const [functionsPopoverOpen, setFunctionsPopoverOpen] = useState(false);
    const { refetchRule } = useRuleContext();
    const { functionsData } = useFunctionsDataContext();
    const { showErrorToast } = useToastContext();
    const popId = nanoid();
    const getDefaultPutData = useGetDefaultPutData();

    const liStyle = {
        listStyleType: 'none',
        borderBottom: '1px solid #d7d7d7',
        padding: '4px',
    };

    const functionsLis = functionsData?.map((e, i) => (
        <li
            key={i}
            style={{
                listStyleType: 'none',
                padding: '7px',
            }}
            onClick={() => {
                addFunctionHandler(e.id);
            }}
        >
            {e.name}
        </li>
    ));

    if (parentFunctionId) {
        functionsLis?.push(
            <li
                key={-1}
                style={{
                    listStyleType: 'none',
                    padding: '7px',
                }}
                onClick={deleteFunctionHandler}
            >
                Delete
            </li>
        );
    }

    async function changeFieldType(type) {
        const defaultData = getDefaultPutData();
        const putData = {...defaultData};

        // setFieldType(type);
        if (isRightField) {
            putData.type = type;
        } else {
            putData.leftType = type;
        }

        await updateField(putData);
        await refetchRule();
        setPopoverOpen(false);
        setFunctionsPopoverOpen(false);
    }

    const lis = (
        <>
            {showFunctionsPopover && (
                <li
                    className="hover-style"
                    style={liStyle}
                    onMouseEnter={() => setFunctionsPopoverOpen(true)}
                >
                    <Stack flexDirection={'row'} alignItems={'center'}>
                        <p style={{ margin: 0 }}>Functions</p>
                        <ArrowRightIcon />
                    </Stack>
                </li>
            )}
            {fieldType !== 'FIELD' && (
                <li style={liStyle} onClick={() => changeFieldType('FIELD')}>
                    Entity
                </li>
            )}
            {allowValueEntry && fieldType !== 'VALUE' && (
                <li style={liStyle} onClick={() => changeFieldType('VALUE')}>
                    Value
                </li>
            )}
            {fieldType !== 'FORMULA' && (
                <li style={liStyle} onClick={() => changeFieldType('FORMULA')}>
                    Formula
                </li>
            )}
             {fieldType !== 'DATE' &&  allowDateEntry && (
                <li style={liStyle} onClick={() => changeFieldType('DATE')}>
                    Date
                </li>
            )}
            {showChangeAlias && (
                <li
                    onMouseEnter={() => setFunctionsPopoverOpen(false)}
                    style={{ ...liStyle, borderBottom: 'none' }}
                    onClick={() => {
                        setNameEditMode(true);
                        setPopoverOpen(false);
                    }}
                >
                    Change alias
                </li>
            )}
        </>
    );

    async function addFunctionHandler(functionId) {
        try {
            await addConditionFunction({
                functionId,
                parentEntityFieldFunctionId: parentFunctionId || null,
                entityFieldId: id,
            });
            setPopoverOpen(false);
            setFunctionsPopoverOpen(false);
            refetchRule();
        } catch (err) {
            console.log(err);
        }
    }

    async function deleteFunctionHandler() {
        try {
            await deleteEntityFieldFunction({ id: parentFunctionId });
            refetchRule();
        } catch (err) {
            console.error(err);
            showErrorToast(getAxiosResponseError(err));
        }
    }

    return (
        <Stack flexDirection={'row'} justifyContent={'end'}>
            <div
                id={`right-field-popper-${popId}`}
                className="more-icon-wrapper"
                onClick={() => setPopoverOpen(true)}
            >
                <i className="dx-icon-more" />
            </div>
            <Popover
                visible={popoverOpen}
                target={`#right-field-popper-${popId}`}
                position="bottom"
                width={'auto'}
                onHiding={() => setPopoverOpen(false)}
            >
                <ul
                    className="right-field-type-popover"
                    style={{ padding: 0, fontSize: '17px' }}
                    id={`right-field-functions-popper-${popId}`}
                >
                    {parentFunctionId ? functionsLis : lis}
                </ul>
            </Popover>
            <Popover
                visible={functionsPopoverOpen}
                target={
                    parentFunctionId
                        ? `#right-field-popper-${popId}`
                        : `#right-field-functions-popper-${popId}`
                }
                position="right"
                width={'auto'}
                onHiding={() => setFunctionsPopoverOpen(false)}
            >
                <ul
                    className="right-field-type-popover"
                    style={{ padding: 0, fontSize: '17px' }}
                >
                    {functionsLis}
                </ul>
            </Popover>
        </Stack>
    );
}
