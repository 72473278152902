import { createSlice } from '@reduxjs/toolkit';

const reportsSelectedSlice = createSlice({
    name: 'reportsSelected',
    initialState: {
        allSelected: false,
        selected: [],
    },
    reducers: {
        removeId: (state, action) => {
            const id = action.payload;
            const index = state.selected.findIndex((e) => e === id);
            state.selected.splice(index, 1);
        },
        addId: (state, action) => {
            const id = action.payload;
            state.selected.push(id);
        },
        selectAll: (state) => {
            state.allSelected = true;
        },
        unSelectAll: (state) => {
            state.selected = [];
        },
        uncheckHeader: (state) => {
            state.allSelected = false;
        },
    },
});

export default reportsSelectedSlice.reducer;

export const { removeId, addId, selectAll, unSelectAll, uncheckHeader } =
    reportsSelectedSlice.actions;
