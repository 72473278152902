import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import * as entitySevice from '../../../services/entity';
import * as ruleService from '../../../services/rules';
import Loader from '../../../components/loader/loader';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Xwrapper } from 'react-xarrows';
import '../ruleDashboard.scss';
import EntitiesDropTarget from '../entitiesDropTarget/entitiesDropTarget';
import ConditionsDropTarget from '../conditionsDropTarget/conditionsDropTarget';
import { Button, LoadPanel, Toast } from 'devextreme-react';
import DetailsPopup from '../detailsPopup/detailsPopup';
import { useDispatch, useSelector } from 'react-redux';
import { errorMsg, toastDisplayTime } from '../../../utils/vars';
import GroupBy from '../groupBy/groupBy';
import { Paper } from '@mui/material';
import Xarrow from 'react-xarrows';
import Stack from '@mui/material/Stack';
import { idGenerator } from 'generate-custom-id';
import ConfirmPopup from '../../../components/confirmPopup/confirmPopup';
import { useRuleContext } from '../ruleContext';
import QueryName from '../queryName/queryName';
import LimitSection from './../limitSection/limitSection';
import OrderByDropTarget from './../orderBy/orderBy';
import QueryContext from '../conditionsDropTarget/condition/queryContext';
import { colors } from '../../../utils/colors';
import { useToastContext } from '../../../components/toast/toast';
import { getAxiosResponseError } from '../../../utils/functions';

export default function SubQuery({ type, queryData, startPointId }) {
    const navigate = useNavigate();
    let { id } = useParams();
    const [ruleId, setRuleId] = useState(id);
    const [isLoading, setIsLoading] = useState(false);
    const [detailsPopupOpen, setDetailsPopupOpen] = useState(false);
    const [ruleHistory, setRuleHistory] = useState();
    const [isLoadingHistory, setIsLoadingHistory] = useState(true);
    const [refetchRuleHistory, setRefetchRuleHistory] = useState(0);
    const theme = useSelector((state) => state.theme);
    const leftId = idGenerator('example', 4);
    const rightId = idGenerator('example', 4);
    const hasSubQueries = !!queryData?.queries?.length;
    const [confirmPopupOpen, setConfirmPopupOpen] = useState(false);
    const { fetchRule, allowRuleEdit } = useRuleContext();
    const paperStyles = {
        padding: '10px',
        position: 'relative',
        background: colors[theme]?.subQueryBackground,
        color: colors[theme]?.textColor,
    };
    const {showErrorToast} = useToastContext();

    useEffect(() => {
        if (type === 'search') {
            setIsLoadingHistory(false);
        }

        if (ruleId && type !== 'search') {
            ruleService
                .getRuleHistory(ruleId)
                .then((res) => {
                    setIsLoadingHistory(false);
                    setRuleHistory(res);
                })
                .catch((err) => {
                    console.error(err);
                    setRuleHistory(null);
                });
        }
    }, [ruleId, refetchRuleHistory]);

    const {
        data: allEntitiesData,
        error,
        isLoading: loadingEntitiesData,
    } = useQuery('all-entities', entitySevice.getAll, {
        refetchOnWindowFocus: false,
        retry: 1,
    });

    if (error) {
        navigate('/');
        return null;
    }

    async function addSubQuery() {
        try {
            await ruleService.postSubQuery(queryData.id);
            fetchRule();
        } catch (err) {
            console.error(err);
            showErrorToast(getAxiosResponseError(err))
        }
    }

    async function deleteHandler() {
        try {
            await ruleService.deleteSubQuery(queryData.id);
            fetchRule();
        } catch (err) {
            console.error(err);
            showErrorToast(getAxiosResponseError(err))
        }
    }

    return (
        <QueryContext queryId={queryData.id} queryData={queryData}>
            <div className="flex-container gap-30 ">
                <div className={`sub-query query-width ${theme}`}>
                    <Paper sx={paperStyles}>
                        <Stack
                            flexDirection={'row'}
                            justifyContent={'space-between'}
                        >
                            <QueryName initialValue={queryData?.alias} />
                            {allowRuleEdit && (
                                <Stack flexDirection={'row'} gap={'10px'}>
                                    <Button
                                        text="Subquery"
                                        onClick={addSubQuery}
                                        stylingMode="outlined"
                                        type="default"
                                    />
                                    <Button
                                        icon="trash"
                                        stylingMode="outlined"
                                        type="default"
                                        onClick={() =>
                                            setConfirmPopupOpen(true)
                                        }
                                    />
                                </Stack>
                            )}
                        </Stack>
                        {!queryData || loadingEntitiesData ? (
                            <Loader height={'40vh'} />
                        ) : (
                            <DndProvider backend={HTML5Backend}>
                                <Xwrapper>
                                    <div>
                                        <EntitiesDropTarget
                                            isSubquery={true}
                                            queryData={queryData}
                                            ruleId={ruleId}
                                            allEntitiesData={allEntitiesData}
                                            hasSubQueries={true}
                                        />
                                        <ConditionsDropTarget
                                            isSubquery={true}
                                            forJoin={false}
                                            hasSubQueries={true}
                                            isSearch={type === 'search'}
                                            ruleId={ruleId}
                                            refetch={() => {
                                                setIsLoading(true);
                                                fetchRule();
                                            }}
                                            conditions={
                                                queryData.conditionGroups
                                            }
                                        />
                                        <ConditionsDropTarget
                                            isSubquery={true}
                                            forJoin={true}
                                            hasSubQueries={true}
                                            isSearch={type === 'search'}
                                            ruleId={ruleId}
                                            query={queryData}
                                            refetch={() => {
                                                setIsLoading(true);
                                                fetchRule();
                                            }}
                                            conditions={queryData.joinGroups}
                                        />

                                        <GroupBy
                                            isSubquery={true}
                                            ruleId={ruleId}
                                            isGroupsSection={true}
                                            dataFields={queryData.dataFields}
                                            groups={queryData.groups}
                                        />
                                        <Stack flexDirection={'row'} gap="10px">
                                            <OrderByDropTarget
                                                orderByData={queryData?.orderBy}
                                                ruleId={ruleId}
                                                isSubquery={true}
                                            />
                                            <LimitSection isSubquery={true} />
                                        </Stack>
                                    </div>
                                    <span
                                        id={leftId}
                                        className={
                                            'left-query-connection-point connection-point '
                                        }
                                    ></span>
                                    {hasSubQueries && (
                                        <span
                                            id={rightId}
                                            className={
                                                'right-query-connection-point connection-point '
                                            }
                                        ></span>
                                    )}
                                    <Xarrow
                                        curveness={0}
                                        strokeWidth={1}
                                        start={startPointId}
                                        end={leftId}
                                        showHead={false}
                                        color={'#0099cc'}
                                    />
                                    <LoadPanel
                                        shadingColor="rgba(0,0,0,0.4)"
                                        position={'#entites-drop-target'}
                                        visible={isLoading || isLoadingHistory}
                                        showIndicator={true}
                                        shading={true}
                                        showPane={true}
                                    />
                                    <DetailsPopup
                                        isLoading={
                                            isLoading || isLoadingHistory
                                        }
                                        open={detailsPopupOpen}
                                        onClose={() =>
                                            setDetailsPopupOpen(false)
                                        }
                                        status={queryData.status}
                                        ruleHistory={ruleHistory}
                                        refetchRuleHistory={() =>
                                            setRefetchRuleHistory(
                                                (oldState) => oldState + 1
                                            )
                                        }
                                    />

                                </Xwrapper>
                            </DndProvider>
                        )}
                    </Paper>
                    {/* {double && <SubQuery queryData={queryData} inner={true}/>} */}
                </div>
                <div>
                    {queryData?.queries?.map((e, i) => (
                        <SubQuery
                            key={i}
                            queryData={e}
                            startPointId={rightId}
                        />
                    ))}
                </div>
                <ConfirmPopup
                    content={'Delete subquery'}
                    onConfirm={deleteHandler}
                    onCancel={() => setConfirmPopupOpen(false)}
                    open={confirmPopupOpen}
                />
            </div>
        </QueryContext>
    );
}
