import { Button, Popup } from 'devextreme-react';
import React from 'react';
import { useGetLocalizedLabel } from '../../utils/vars';

export default function ConfirmPopup({ content, onConfirm, onCancel, open }) {
    const getLocalized = useGetLocalizedLabel();

    return (
        <Popup visible={open} width={250} height="auto" showTitle={false}>
            <p
                style={{
                    fontSize: '20px',
                    margin: '20px',
                    textAlign: 'center',
                }}
            >
                {content}
            </p>
            <div style={{marginBottom : '10px', display: 'flex', justifyContent: 'space-between'}}>
                <Button
                    text={getLocalized('confirm')}
                    onClick={onConfirm}
                    type={'success'}
                    stylingMode="outlined"
                />
                <Button
                    text={getLocalized('cancel')}
                    onClick={onCancel}
                    type={'danger'}
                    stylingMode="outlined"
                />
            </div>
        </Popup>
    );
}
