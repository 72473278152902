// import { Paper } from '@mui/material';
import { LoadPanel, Toast } from 'devextreme-react';
import { useEffect, useState } from 'react';
import { useDrop } from 'react-dnd';
import { useDispatch, useSelector } from 'react-redux';
import * as entityService from '../../../services/entity';
import * as ruleService from '../../../services/rules';
import {
    errorMsg,
    languageLables,
    toastDisplayTime,
} from '../../../utils/vars';
import Entity from '../entity/entity';
import { useXarrow } from 'react-xarrows';
import SelectEntity from '../selectEntityAccordion/selectEntityAccordion';
import ReactResizeDetector from 'react-resize-detector';
import { withResizeDetector } from 'react-resize-detector';
import { useRef } from 'react';
import { useRuleContext } from '../ruleContext';
import { useToastContext } from '../../../components/toast/toast';
import { getAxiosResponseError } from '../../../utils/functions';
import {
    selectDisplayedEntities,
    setEntities,
} from '../../../redux/features/rule/displayedEntities';

function EntitiesDropTarget({ queryData, hasSubQueries, isSubquery }) {
    const [refetchDisplayedEntities, setRefetchDisplayedEntities] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const lg = useSelector((state) => state.language);
    const theme = useSelector((state) => state.theme);
    let wrapperClasses = 'drop-target paper entities-drop-target';
    if (isSubquery) {
        wrapperClasses += ' paper-subquery';
    }
    const [addEntityOpen, setAddEntityOpen] = useState(false);
    const ref = useRef();
    const updateArrows = useXarrow();
    const { allEntitiesData, allowRuleEdit } = useRuleContext();
    const { showErrorToast } = useToastContext();
    const dispatch = useDispatch();
    const queryId = queryData.id;

    const displayedEntities = useSelector(selectDisplayedEntities)[queryId];

    if (theme === 'dark') {
        wrapperClasses += ' dark-theme-background';
    }

    wrapperClasses += theme === 'dark' ? ' paper-dark-theme' : ' paper';

    if (addEntityOpen) {
        wrapperClasses += ' open';
    }

    useEffect(() => {
        fetchDisplayedEntities();

        return () => dispatch(setEntities({}));
    }, [refetchDisplayedEntities]);

    async function fetchDisplayedEntities() {
        try {
            const entData = await ruleService.getDisplayedEntities(
               queryId
            );
            dispatch(setEntities({ queryId, data: entData }));
            setIsLoading(false);
        } catch (err) {
            console.error(err);
        }
    }

    const enitites = displayedEntities?.map((e, i) => (
        <Entity
            e={e}
            from={'entity-drop-target'}
            allowRemove={true}
            removeEntity={removeEntity}
            diplayedEntityId={e.diplayedEntityId}
            name={e.name}
            data={e.entity}
            defaultConnections={e.innerDisplayedEntities}
            key={i}
            ruleEntityId={e.ruleEntityId}
            isTopLevel={true}
            innerDisplayedEntities={e.innerDisplayedEntities}
            refetchEntites={refetch}
            parentRef={ref}
        />
    ));

    async function removeEntity(id) {
        try {
            await entityService.detachEntity(id);
            refetch();
        } catch (err) {
            console.error(err);
            showErrorToast(getAxiosResponseError(err));
        }
    }

    async function addEntity(entityId) {
        try {
            await ruleService.attachEntity({ entityId, ruleId: queryData?.id });
            refetch();
            setAddEntityOpen(false);
        } catch (err) {
            console.error(err);
            showErrorToast(getAxiosResponseError(err));
        }
    }

    const [_collectedProps, drop] = useDrop(() => ({
        accept: 'create-rule',
        drop: (item, monitor) => {
            addEntity(item.data.id);
        },
    }));

    function refetch() {
        setIsLoading(true);
        setRefetchDisplayedEntities((oldState) => oldState + 1);
    }

    return (
        <div
            className={`entities-wrapper ${
                hasSubQueries && 'with-queries-width'
            }`}
        >
            {/* <div className="width-wrapper"> */}
            <p className="section-label">
                {languageLables.selectedEntities[lg]}
            </p>
            <div id="entites-drop-target" ref={drop} className={wrapperClasses}>
                {allowRuleEdit && (
                    <div className="default-entities">
                        <SelectEntity
                            addEntityOpen={addEntityOpen}
                            setAddEntityOpen={setAddEntityOpen}
                            entities={allEntitiesData}
                            refetch={refetch}
                        />
                    </div>
                )}

                <div
                    className="entities-section"
                    onScroll={updateArrows}
                    ref={ref}
                >
                    {enitites}
                </div>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={'#entites-drop-target'}
                    visible={isLoading}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                />
            </div>
            {/* </div> */}
        </div>
    );
}

const WithResize = withResizeDetector(EntitiesDropTarget);

export default WithResize;
