import { createSlice } from "@reduxjs/toolkit";


const groupsSectionSlice = createSlice({
    name: 'showGroups',
    initialState: false,
    reducers: {
        showHideGroups: (state) => {
            return !state
        },
    },
});

export const { showHideGroups } = groupsSectionSlice.actions;

export default groupsSectionSlice.reducer;