import { useActionsRoleContext } from '../contexts/actionsRoleProvider';

export const useCheckPermission = (action) => {
    const { actionsData, role } = useActionsRoleContext();
    // const { actionsData } = useActionsRoleContext();

    // const role = 'AML_CORDINATOR';
    // const role = 'AML_MANAGER';
    // const role = 'ADMIN';
    // const role = 'AUDITOR';

    // console.log('actionsData', actionsData);

    if (role === 'GRANT_ADMIN') {
        return true;
    }

    if (action === 'UNLOCK') {
        return role === 'AML_MANAGER';
    }

    if (action === 'ASSIGN_TO') {
        return role === 'AML_MANAGER';
    }

    const actionObj = actionsData.find((e) => e.action === action);
    const roleObj = actionObj?.roles.find((e) => e.role === role);

    return roleObj.selected;
};
