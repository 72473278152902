import DefaultSearch from './defaultSearch';


export default function ManualSearch() {
    //TODO: refactor (there were other components also before)

    return (
        <div className={'manual-search-wrapper'}>
            <DefaultSearch />
        </div>
    );
}


