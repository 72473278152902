import { Button, Popup, TextArea } from "devextreme-react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useToastContext } from "../../../components/toast/toast";
import { escalateFlag } from "../../../services/flag";

export default function Escalate({ refetch }) {
    const [escalatePopupVisible, setEscalatePopupVisible] = useState(false);
    const [escalateText, setIsEscalateText] = useState('');
    const { showErrorToast, showSuccessToast } = useToastContext();
    const { id } = useParams();

    async function escalateHandler() {
        try {
            await escalateFlag({ id, text: escalateText });
            refetch();
            showSuccessToast('Flag successfully escalated.')
        } catch (err) {
            showErrorToast(err?.response?.data?.message)
        }
    }

    return (
        <>
            <Popup
                visible={escalatePopupVisible}
                width={300}
                height={150}
                showTitle={false}
            >
                <div style={{ marginTop: '5px' }}>
                    <TextArea
                        label="Enter text"
                        stylingMode="underlined"
                        value={escalateText}
                        onInput={(e) => setIsEscalateText(e.event.target.value)}
                    />
                </div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        marginTop: '10px',
                    }}
                >
                    <Button
                        text="Cancel"
                        onClick={() => {
                            setIsEscalateText('');
                            setEscalatePopupVisible(false);
                        }}
                        type="danger"
                        stylingMode="outlined"
                    />
                    <Button
                        text="Confirm"
                        type="success"
                        stylingMode="outlined"
                        onClick={escalateHandler}
                    />
                </div>
            </Popup>
            <Button
                text="Escalate"
                type="default"
                stylingMode="outlined"
                onClick={() => setEscalatePopupVisible(true)}
            />
        </>
    );
}