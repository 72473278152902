import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import appInfo from './app-info';
import useGetRoutes from './app-routes';
import { SideNavOuterToolbar as SideNavBarLayout } from './layouts';
import Error from './pages/error/error';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Toast from './components/toast/toast';

export default function Content() {
    const routes = useGetRoutes();
    const errorFetchingActions = useSelector(state => state.errorFetchingActions);
    const navigate = useNavigate();

    useEffect(() => {
        if(errorFetchingActions ) {
            navigate('error')
        }
    }, []);

    return (
        <SideNavBarLayout title={appInfo.title}>
            <Routes>
                {errorFetchingActions ? (
                   <Route path={'error'} element={<Error/>}/>
                ) : (
                    <>
                        {routes.map(({ path, element }) => (
                            <Route key={path} path={path} element={element} />
                        ))}

                        <Route path="*" element={<Navigate to="/" />} />
                    </>
                )}
            </Routes>
            <Toast/>
        </SideNavBarLayout>
    );
}
