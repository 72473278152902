import { Button, x } from 'devextreme-react';
import React, { useState } from 'react';
import { useGetLocalizedLabel } from '../../utils/vars';
import { changeRuleStatus } from '../../services/rules';
import { getAxiosResponseError } from '../../utils/functions';
import moment from 'moment';
import { useToastContext } from '../toast/toast';

export default function ActivateDeactivate({ status, id, refetchRules }) {
    const [isLoading, setIsLoading] = useState(false);
    const isActive = status === 'ACTIVATED';
    const { showErrorToast, showSuccessToast } = useToastContext();
    const getLocalized = useGetLocalizedLabel();

    async function onClick(ev) {
        ev.event.stopPropagation();

        const postData = {
            id,
            date: moment(new Date()).format('YYYY-MM-DD'),
            status: isActive ? 'DEACTIVATED' : 'ACTIVATED',
            period: 'DAY',
        };

        setIsLoading(true);
        try {
            await changeRuleStatus(postData);
            setIsLoading(false);
            refetchRules();
            showSuccessToast(
                `Report has been ${isActive ? 'deactivated' : 'activated'}`
            );
        } catch (err) {
            console.error(err);
            showErrorToast(getAxiosResponseError(err));
        }
    }

    return (
        <>
            <Button
                disabled={isLoading}
                type="default"
                stylingMode="outlined"
                text={
                    isActive
                        ? getLocalized('deactivate')
                        : getLocalized('activate')
                }
                onClick={onClick}
            />
        </>
    );
}
