export default function findLabel(arr, target, lg) {
    let label = '';

    arr.forEach((e) => {
        if (e.Key === target) {
            label = e.LocalizedValue[lg];
        }
    });

    return label;
}
