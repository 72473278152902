import { createSlice } from '@reduxjs/toolkit';

const filtersSlice = createSlice({
    name: 'reportFilters',
    initialState: {},
    reducers: {
        setFilters: (_state, action) => {
            return  action.payload;
        },
    },
});

export default filtersSlice.reducer;

export const { setFilters } = filtersSlice.actions;
