import { useRef } from 'react';
import { useDrag } from 'react-dnd';
import { useDispatch } from 'react-redux';
import {
    clearConditionPlaceholder,
    removeHoverField,
} from '../../redux/features/dashboard/rule';

export function withDraggable(Component) {
    return function WithDraggable(props) {
        const dispatch = useDispatch();
        const ref = useRef(null);
        const [{ isDragging, handlerId }, connectDrag] = useDrag({
            type: props.from || 'child-entity',
            item: {
                data: props.data,
                displayedEntityId: props.diplayedEntityId,
            },
            collect: (monitor) => {
                const result = {
                    handlerId: monitor.getHandlerId(),
                    isDragging: monitor.isDragging(),
                };
                return result;
            },
            end: (_item, monitor) => {
                const isDropHandled = monitor.didDrop();

                if (!isDropHandled) {
                    dispatch(clearConditionPlaceholder({ type: 'joinGroups' }));
                    dispatch(
                        clearConditionPlaceholder({ type: 'conditionGroups' })
                    );
                    dispatch(
                        removeHoverField({
                            type: 'joinGroups',
                        })
                    );
                    dispatch(
                        removeHoverField({
                            type: 'conditionGroups',
                        })
                    );
                }
            },
        });
        connectDrag(ref);

        return (
            <span className="drag-item" ref={ref}>
                <Component {...props} />
            </span>
        );
    };
}
