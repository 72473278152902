import { updateOrderBy } from '../../../../services/rules';
import { useRuleContext } from '../../ruleContext';

export default function Chip({ name, onDelete, sortType, id }) {
    const { refetchRule, allowRuleEdit } = useRuleContext();

    async function sortTypeHandler() {
        if (!allowRuleEdit) {
            return;
        }
        try {
            await updateOrderBy({
                functionId: null,
                sortType: sortType === 'DESC' ? 'ASC' : 'DESC',
                id,
            });
            refetchRule();
        } catch (err) {
            console.error(err);
        }
    }

    return (
        <div className="my-chip">
            <div className="chip-flex-wrapper">
                <div>
                    <p>{name}</p>
                </div>
                <p className="divider">|</p>
                <div className="flex-container">
                    <p onClick={sortTypeHandler} className={allowRuleEdit && "sort-type"}>
                        {sortType}
                    </p>
                    {allowRuleEdit && (
                        <div
                            className="chip-delete-wrapper"
                            onClick={(ev) => {
                                ev.stopPropagation();
                                onDelete();
                            }}
                        >
                            <i className="dx-icon-close"></i>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
