import { NumberBox, SelectBox } from 'devextreme-react';
import { updateField } from '../../../../../services/rules';
import { useRuleContext } from '../../../ruleContext';
import Stack from '@mui/material/Stack';
import { useFieldsGroupContext } from '../fieldsGroupContext';
import { useToastContext } from '../../../../../components/toast/toast';
import { getAxiosResponseError } from '../../../../../utils/functions';
import { useGetDefaultPutData } from '../../../../../hooks/useGetDefaultPostData';

export default function Formula({
    savedFunctions,
    nameEditMode,
    dropField,
    nameEdit,
    functionWrapper,
    left,
}) {
    const { refetchRule } = useRuleContext();
    const selectValues = [
        { label: '*', value: 'MULTIPLY' },
        { label: '/', value: 'DIVIDE' },
        { label: '+', value: 'SUM' },
        { label: '-', value: 'SUBTRACT' },
    ];
    const { fieldsGroupData } = useFieldsGroupContext();
    const value = left
        ? fieldsGroupData?.leftPart?.value
        : fieldsGroupData?.rightPart?.value;
    const sign = left
        ? fieldsGroupData?.leftPart?.sign
        : fieldsGroupData?.rightPart?.sign;

    const { showErrorToast } = useToastContext();
    const getDefaultPutData = useGetDefaultPutData();

    async function saveHandler(newValue, newSign) {
        const defaultData = getDefaultPutData();
        const putData = { ...defaultData };

        if (left) {
            putData.leftValue = newValue;
            putData.leftSign = newSign || 'MULTIPLY';
        } else {
            putData.value = newValue;
            putData.sign = newSign || 'MULTIPLY';
        }
        try {
            await updateField(putData);

            refetchRule();
        } catch (err) {
            console.error(err);
            showErrorToast(getAxiosResponseError(err));
        }
    }

    let element = null;

    if (nameEditMode) {
        element = nameEdit;
    } else {
        element = !!savedFunctions ? functionWrapper : dropField;
    }

    return (
        <Stack alignItems={'end'} flexDirection={'row'} gap="10px">
            {element}

            <div style={{ width: '50px' }}>
                <SelectBox
                    width={'50px'}
                    stylingMode="underlined"
                    items={selectValues}
                    displayExpr={'label'}
                    valueExpr={'value'}
                    onValueChange={(v) => {
                        saveHandler(value, v);
                    }}
                    defaultValue={sign || 'MULTIPLY'}
                />
            </div>
            <NumberBox
                defaultValue={!!value && !!sign ? Number(value) : null}
                width={'60px'}
                stylingMode="underlined"
                onChange={(e) => saveHandler(e.event.target.value, sign)}
            />
        </Stack>
    );
}
