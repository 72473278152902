import { TextBox } from "devextreme-react";
import { editFieldName } from "../../../../../services/rules";
import { useRuleContext } from "../../../ruleContext";

export default function NameEdit({ setNameEditMode, defaultValue, id }) {
    const { refetchRule } = useRuleContext();
    
    async function onNameSave(nameValue) {
        try {
            await editFieldName({ id, name: nameValue });
            refetchRule();
            setNameEditMode(false);
        } catch (err) {
            console.error(err);
        }
    }

    return (
        <TextBox
            onFocusOut={(ev) => onNameSave(ev.event.target.value)}
            stylingMode="underlined"
            defaultValue={defaultValue}
        />
    );
}