import React from 'react';
import { clientBaseUrl } from '../../services/urls';
import './error.scss';

export default function Error() {
    return (
        <div className="error-page-wrapper">
            <p>Something happened.</p>
            <a
                onClick={() => {
                    window.location.href = clientBaseUrl;
                    window.location.reload();
                }}
            >
                Go to home page.
            </a>
        </div>
    );
}
