import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { withDraggable } from '../../../../components/withDraggable/withDraggable';
import { ScrollView, TextBox } from 'devextreme-react';
import ConfirmPopup from '../../../../components/confirmPopup/confirmPopup';
import SaveBtn from '../../../../components/saveBtn/saveBtn';
import { editEntitiyName } from '../../../../services/rules';
import { useSelector } from 'react-redux';
import { useXarrow } from 'react-xarrows';
import { useRuleContext } from '../../ruleContext';
import { useQueryContext } from '../../conditionsDropTarget/condition/queryContext';

export default function Accordion({
    open,
    onClose,
    data,
    removeEntity,
    allowRemove,
    from,
    startPointId,
    leftId,
    rightId,
    hasConnections,
    parentOpen,
    onOpen,
    diplayedEntityId,
    isTopLevel,
    name,
}) {
    const DraggbleListItem = withDraggable(MyItem);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [nameInputValue, setNameInputValue] = useState(name || data?.name);
    const theme = useSelector((state) => state.theme);
    const [arrowsUpdated, setArrowsUpdated] = useState(false);
    const { allowRuleEdit } = useRuleContext();
    
    let wrapperClasses = 'accordion';

    if (theme === 'dark') {
        wrapperClasses += ' accordion-dark-background';
    } else {
        wrapperClasses += ' light';
    }
    const updateArrows = useXarrow();

    if (!arrowsUpdated && data) {
        updateArrows();
        setArrowsUpdated(true);
    }

    function MyItem({ data }) {
        return (
            <div
                className={allowRuleEdit ? 'menu-item grab-item' : 'menu-item'}
            >
                {allowRuleEdit && (
                    <span>
                        <i className="dx-icon-dragvertical"></i>
                    </span>
                )}

                <div>{data.name}</div>
            </div>
        );
    }

    const fields = data?.fields?.map((e, i) => {
        return allowRuleEdit ? (
            <DraggbleListItem
                from={from}
                data={e}
                key={i}
                diplayedEntityId={diplayedEntityId}
            />
        ) : (
            <MyItem data={e} key={i} />
        );
    });

    async function onNameSave() {
        try {
            setEditMode(false);
            await editEntitiyName({
                id: diplayedEntityId,
                name: nameInputValue,
            });
        } catch (error) {}
    }

    return (
        <div className={wrapperClasses}>
            {startPointId && (
                <span
                    id={leftId}
                    className={
                        'left-connection-point connection-point ' + leftId
                    }
                ></span>
            )}

            {open && hasConnections && (
                <span
                    id={rightId}
                    className={
                        'right-connection-point connection-point ' + rightId
                    }
                ></span>
            )}

            {allowRemove && allowRuleEdit && (
                <span
                    className="remove-icon-wrapper"
                    onClick={(ev) => {
                        setDialogOpen(true);
                    }}
                >
                    <i className="dx-icon-remove"></i>
                </span>
            )}
            <section
                className={
                    open
                        ? `accordion-wrapper open ${theme}`
                        : `accordion-wrapper ${theme}`
                }
            >
                <div
                    className={`accordion-header`}
                    onClick={() => {
                        if (diplayedEntityId && allowRuleEdit) {
                            setEditMode(true);
                        }
                    }}
                >
                    {editMode ? (
                        <div style={{ display: 'flex' }}>
                            <TextBox
                                value={nameInputValue}
                                stylingMode="underlined"
                                onInput={(e) =>
                                    setNameInputValue(
                                        e.event.currentTarget.value
                                    )
                                }
                            />
                            <SaveBtn active={true} onSave={onNameSave} />
                        </div>
                    ) : (
                        <p>{nameInputValue}</p>
                    )}

                    {!isTopLevel && allowRuleEdit && (
                        <span
                            className={
                                open ? `flipIn arrow-wrapper` : `arrow-wrapper`
                            }
                            onClick={(ev) => {
                                ev.stopPropagation();
                                if (open) {
                                    onClose();
                                } else {
                                    onOpen();
                                }
                            }}
                        >
                            <i className="dx-icon-chevrondown"></i>
                        </span>
                    )}
                </div>
                <ScrollView showScrollbar={'always'}>
                    <div
                        className="accordion-content"
                        style={{ maxHeight: '200px' }}
                    >
                        {fields}
                    </div>
                </ScrollView>
            </section>
            <ConfirmPopup
                content={'Delete entity'}
                open={dialogOpen}
                onConfirm={() => {
                    removeEntity(diplayedEntityId);
                    setDialogOpen(false);
                }}
                onCancel={() => setDialogOpen(false)}
            />
        </div>
    );
}
