import { Button, SelectBox, Toast, Tooltip } from 'devextreme-react';
import DxChart, {
    Export,
    Series,
    Tooltip as ChartTooltip,
} from 'devextreme-react/chart';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
    errorMsg,
    flagStatuses,
    languageLables,
    reportsLabels,
    resolutionStatuses,
    useGetLocalizedLabel,
} from '../../utils/vars';
import './auditTrails.scss';
import { utils, writeFile } from 'xlsx';
import { useQuery } from 'react-query';
import { getChartData, getDropdownData } from '../../services/auditTrail';
import Loader from '../../components/loader/loader';
import useGetLabel from '../../hooks/useFindLabel';
import { useEffect } from 'react';
import { useKeycloak } from '../../App';
import moment from 'moment';
import { useToastContext } from '../../components/toast/toast';

export default function AuditTrail() {
    const { name } = useKeycloak();
    const lg = useSelector((state) => state.language);
    const chartTypeItems = [
        { label: 'Show as percentage', value: 'percentage' },
        { label: 'Show as count', value: 'count' },
    ];
    const {
        data: dropdownData,
        isLoading,
        error,
    } = useQuery('audit-trail-dropdown', getDropdownData, {
        refetchOnWindowFocus: false,
        retry: 1,
    });
    const [groupByValue, setGroupByValue] = useState([]);
    const [selectValue, setSelectValue] = useState([]);
    const [chartData, setChartData] = useState(null);
    const [chartType, setChartType] = useState('count');
    const theme = useSelector((state) => state.theme);
    const sectionClasses =
        theme === 'light'
            ? 'section light-theme-background'
            : 'section dark-theme-background';
    const { showErrorToast } = useToastContext();
    const getLocalized = useGetLocalizedLabel();

    useEffect(() => {
        if (error) {
            showErrorToast(error?.response?.data?.message || errorMsg);
        }
    }, [error]);

    async function generateChartHandler() {
        try {
            const postData = {
                groupByTypes: groupByValue.map((e) => e.type),
                selectTypes: selectValue.map((e) => e.type),
            };

            selectValue.forEach((e) => {
                postData[e.postValueType] = e.value;
            });
            groupByValue.forEach((e) => {
                postData[e.postValueType] = e.value;
            });
            const res = await getChartData(postData);
            setChartData(res);
        } catch (err) {
            showErrorToast(err?.response?.data?.message || errorMsg);
        }
    }

    if (isLoading) {
        return <Loader height={'300px'} />;
    }

    function hasTenant() {
        return (
            selectValue.some((e) => e.type === 'TENANT') ||
            groupByValue.some((e) => e.type === 'TENANT')
        );
    }

    function exportTable() {
        const headerRow = ['', 'Count', 'Percentage'];

        const mappedData = chartData?.map((e) => {
            let name = `${e.name} - ${e.groupBy}`;

            return [name, e.count, e.percentage];
        });

        mappedData.unshift(headerRow);

        const employeeTitle = `${name} - ${moment(new Date()).format(
            'DD/MM/yyyy'
        )}`;
        const selectTitle = getCriteriaTitle('select');
        const groupByTitle = getCriteriaTitle('groupBy');

        mappedData.unshift([]);
        mappedData.unshift([groupByTitle]);
        mappedData.unshift([selectTitle]);
        mappedData.unshift([employeeTitle]);

        const workbook = utils.book_new();

        const worksheet = utils.aoa_to_sheet(mappedData, {});

        const columnWidths = [{ wch: 20 }, { wch: 10 }];
        for (let i = 0; i <= mappedData.length - 1; i++) {
            const rowData = mappedData[i];
            const nameLength = rowData.toString().length;

            if (nameLength > columnWidths[0].wch) {
                columnWidths[0].wch = nameLength;
            }
        }
        worksheet['!cols'] = columnWidths;
        utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        writeFile(workbook, 'chart.xlsx');

        function getCriteriaTitle(type) {
            let t = '';

            switch (type) {
                case 'select':
                    t += 'Select criteria: ';
                    selectValue.forEach((e, i) => {
                        t += `${e.type} - ${e.label}`;
                        if (selectValue.length - 1 !== i) {
                            t += ', ';
                        }
                    });
                    break;

                case 'groupBy':
                    t += 'Group by: ';
                    groupByValue.forEach((e, i) => {
                        t += `${e.type} - ${e.label}`;
                        if (selectValue.length - 1 !== i) {
                            t += ', ';
                        }
                    });
                    break;
                default:
                    break;
            }

            return t;
        }
    }

    let generateBtnDisabled = false;

    if (!selectValue?.length || !groupByValue?.length || !hasTenant()) {
        generateBtnDisabled = true;
    } else {
        const totalSelected = selectValue
            .filter((e) => e.type !== 'TENANT')
            .concat(groupByValue.filter((e) => e.type !== 'TENANT')).length;

        if (totalSelected > 2 && chartType === 'percentage') {
            generateBtnDisabled = true;
        }
    }

    return (
        <div className="audit-trail-wrapper">
            {!error && (
                <>
                    <div className="header-btns">
                        <div className="export-clear-btns-wrapper flex-container">
                            <div>
                                <Button
                                    disabled={
                                        !selectValue?.length &&
                                        !groupByValue?.length
                                    }
                                    text={getLocalized('clear')}
                                    onClick={() => {
                                        setGroupByValue([]);
                                        setChartData(null);
                                        setSelectValue([]);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="flex-container select-btn-header-wrapper">
                            <SelectBox
                                stylingMode="underlined"
                                displayExpr={'label'}
                                valueExpr={'value'}
                                defaultValue={'count'}
                                items={chartTypeItems}
                                onValueChange={(v) => setChartType(v)}
                            />
                            <Button
                                disabled={generateBtnDisabled}
                                type="default"
                                text={getLocalized('generateChart')}
                                onClick={generateChartHandler}
                            />
                        </div>
                    </div>
                    <div className={`header ${sectionClasses}`}>
                        <SelectGroupSection
                            groupByValue={groupByValue}
                            selectValue={selectValue}
                            {...dropdownData}
                            setSelectValue={setSelectValue}
                            selectOrGroup={'select'}
                        />
                        <SelectGroupSection
                            selectValue={selectValue}
                            groupByValue={groupByValue}
                            setGroupByValue={setGroupByValue}
                            {...dropdownData}
                            selectOrGroup={'group'}
                        />
                    </div>
                    {!!chartData && (
                        <div className={sectionClasses}>
                            <Chart
                                groupBy={groupByValue.type}
                                chartType={chartType}
                                chartData={chartData}
                                selectType={selectValue.type}
                                exportTable={exportTable}
                            />
                        </div>
                    )}
                </>
            )}
        </div>
    );
}
function Chart({ chartData, selectType, exportTable, chartType, groupBy }) {
    const mappedData = chartData?.map((e, i) => {
        // console.log(e);
        return {
            ...e,
            name: `${transformName(e.name || '', i)}`,
            tooltipName: `${e.name} - ${e.groupBy}`,
        };
    });

    function transformName(select, i) {
        const ruleName = select?.split('|')[0];
        let transformedSelectName = '';

        if (!ruleName) {
            transformedSelectName = ruleName;
        } else {
            transformedSelectName = ruleName.slice(0, 4);
        }

        return `${transformedSelectName}.. ${i}`;
    }

   const getLocalized = useGetLocalizedLabel()

    function ExportExcel() {
        const [exportExcelTooltipOpen, setExportExcelTooltipOpen] =
            useState(false);

        return (
            <div
                id="export-excel"
                className="export-excel-wrapper"
                onMouseEnter={() => {
                    if (!!chartData) {
                        setExportExcelTooltipOpen(true);
                    }
                }}
                onMouseLeave={() => setExportExcelTooltipOpen(false)}
            >
                <Button
                    stylingMode="outlined"
                    icon="exportxlsx"
                    onClick={exportTable}
                    disabled={!chartData}
                />
                <Tooltip
                    visible={exportExcelTooltipOpen}
                    target={'#export-excel'}
                >
                    {getLocalized('exportExcel')}
                </Tooltip>
            </div>
        );
    }

    return (
        <div className="chart-wrapper">
            <ExportExcel />
            <DxChart id="chart" dataSource={mappedData}>
                <Export enabled={true} />
                <Series
                    valueField={chartType}
                    argumentField="name"
                    type="bar"
                    showInLegend={false}
                    color="#ffaa66"
                />
                <ChartTooltip
                    enabled={true}
                    customizeTooltip={(arg) => {
                        return {
                            text: `${arg.point.data.tooltipName}`,
                        };
                    }}
                />
            </DxChart>
        </div>
    );
}

function SelectGroupSection({
    selectValue,
    statuses,
    rules,
    tenants,
    resolutions,
    setSelectValue,
    reports,
    selectOrGroup,
    groupByValue,
    setGroupByValue,
}) {
    const lg = useSelector((state) => state.language);
    const statusesItems = statuses?.map((e) => {
        return { value: e, label: flagStatuses[e] };
    });
    statusesItems && statusesItems.push({ value: 'ALL', label: 'All' });
    const rulesItems = rules?.map((e) => {
        return { value: e.id, label: e.name };
    });
    rulesItems && rulesItems.push({ value: 'ALL', label: 'All' });

    const reportItems = reports?.map((e) => {
        return { value: e, label: reportsLabels[e] };
    });
    reportItems && reportItems.push({ value: 'ALL', label: 'All' });

    const tenantItems = tenants?.map((e) => {
        return { value: e.id, label: e.name };
    });
    tenantItems && tenantItems.push({ value: 'ALL', label: 'All' });
    const getLocalized = useGetLocalizedLabel();

    const postValueTypes = {
        RULE: 'rules',
        REPORT: 'reports',
        RESOLUTION: 'resolutions',
        STATUS: 'statuses',
        TENANT: 'tenants',
    };

    const postGroupByTypes = {
        RULE: 'groupRules',
        REPORT: 'groupReports',
        RESOLUTION: 'groupResolutions',
        STATUS: 'groupStatuses',
        TENANT: 'groupTenants',
    };

    const resolutionItems = resolutions?.map((e) => {
        return { value: e, label: resolutionStatuses[e] };
    });
    resolutionItems && resolutionItems.push({ value: 'ALL', label: 'All' });

    const allItems = {
        RULE: rulesItems,
        REPORT: reportItems,
        RESOLUTION: resolutionItems,
        STATUS: statusesItems,
        TENANT: tenantItems,
    };

    function selectChangeHandler(v, type) {
        const item = allItems[type].find((e) => e.value === v);
        if (selectOrGroup === 'select') {
            const data = {
                value: v === 'ALL' ? [] : [v],
                selected: v,
                type,
                postValueType: postValueTypes[type],
                label: item.label,
            };
            setSelectValue((oldState) => {
                const arrCopy = [...oldState];
                const i = arrCopy.findIndex((e) => e.type === type);
                if (i === -1) {
                    arrCopy.push(data);
                } else {
                    arrCopy[i] = data;
                }
                return arrCopy;
            });
        } else {
            const data = {
                value: v === 'ALL' ? [] : [v],
                selected: v,
                type,
                postValueType: postGroupByTypes[type],
                label: item.label,
            };
            setGroupByValue((oldState) => {
                const arrCopy = [...oldState];
                const i = arrCopy.findIndex((e) => e.type === type);
                if (i === -1) {
                    arrCopy.push(data);
                } else {
                    arrCopy[i] = data;
                }
                return arrCopy;
            });
        }
    }

    function getValue(type) {
        let value;
        if (selectOrGroup === 'select') {
            selectValue.forEach((e) => {
                if (e.type === type) {
                    value = e.selected;
                }
            });
        } else {
            groupByValue.forEach((e) => {
                if (e.type === type) {
                    value = e.selected;
                }
            });
        }
        return value;
    }

    function isDisabled(type) {
        let disabled = false;

        if (selectOrGroup === 'select') {
            if (groupByValue?.some((e) => e.type === type)) {
                disabled = true;
            }
        } else {
            if (selectValue?.some((e) => e.type === type)) {
                disabled = true;
            }
        }

        return disabled;
    }

    return (
        <div>
            <h3>{selectOrGroup === 'select' ? 'Select' : 'Group by'}</h3>
            <div className="audit-trail-dropdowns">
                <div className="select-wrapper">
                    <SelectBox
                        stylingMode="underlined"
                        disabled={isDisabled('RULE')}
                        value={getValue('RULE')}
                        label={getLocalized('rule')}
                        items={rulesItems}
                        displayExpr={'label'}
                        valueExpr={'value'}
                        onValueChange={(v) => {
                            selectChangeHandler(v, 'RULE');
                        }}
                    />
                </div>
                <div className="select-wrapper">
                    <SelectBox
                        stylingMode="underlined"
                        disabled={isDisabled('STATUS')}
                        value={getValue('STATUS')}
                        items={statusesItems}
                        displayExpr={'label'}
                        valueExpr={'value'}
                        label={getLocalized('status')}
                        onValueChange={(v) => selectChangeHandler(v, 'STATUS')}
                    />
                </div>
                <div className="select-wrapper">
                    <SelectBox
                        stylingMode="underlined"
                        disabled={isDisabled('RESOLUTION')}
                        value={getValue('RESOLUTION')}
                        label={getLocalized('resolution')}
                        items={resolutionItems}
                        displayExpr={'label'}
                        valueExpr={'value'}
                        onValueChange={(v) =>
                            selectChangeHandler(v, 'RESOLUTION')
                        }
                    />
                </div>
                <div className="select-wrapper">
                    <SelectBox
                        stylingMode="underlined"
                        disabled={isDisabled('REPORT')}
                        value={getValue('REPORT')}
                        label={getLocalized('report')}
                        items={reportItems}
                        displayExpr={'label'}
                        valueExpr={'value'}
                        onValueChange={(v) => selectChangeHandler(v, 'REPORT')}
                    />
                </div>
                <div className="select-wrapper">
                    <SelectBox
                        stylingMode="underlined"
                        disabled={isDisabled('TENANT')}
                        value={getValue('TENANT')}
                        label={'* Tenant'}
                        items={tenantItems}
                        displayExpr={'label'}
                        valueExpr={'value'}
                        onValueChange={(v) => selectChangeHandler(v, 'TENANT')}
                    />
                </div>
            </div>
        </div>
    );
}
