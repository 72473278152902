import { TextBox } from 'devextreme-react';
import { useState } from 'react';
import { useDrop } from 'react-dnd';
import { useDispatch } from 'react-redux';

import { updateField } from '../../../../../services/rules';
import { useRuleContext } from '../../../ruleContext';
import { useConditionContext } from '../conditionsContext';
import { addHoverField } from '../../../../../redux/features/dashboard/rule';
import NameEdit from '../NameEdit/nameEdit';
import { useQueryContext } from '../queryContext';
import Formula from '../formula/formula';
import MoreActions from './moreActions';
import FunctionWrapper from './fieldFunctionWrapper';
import { useFieldsGroupContext } from '../fieldsGroupContext';
import { useGetDefaultPutData } from '../../../../../hooks/useGetDefaultPostData';
import { getRightType } from '../../../../../utils/functions';

export default function LeftField() {
    const { fieldsGroupData } = useFieldsGroupContext();
    const fieldData = fieldsGroupData?.leftPart;
    const entityData = fieldData?.entityField;
    const savedFunctions = entityData?.entityFieldFunction;
    const [nameEditMode, setNameEditMode] = useState(false);
    const { allowRuleEdit } = useRuleContext();

    // const [fieldType, setFieldType] = useState(fieldData?.type);

    const fieldType = fieldData?.type;

    const nameEdit = (
        <NameEdit
            setNameEditMode={setNameEditMode}
            defaultValue={entityData?.name || entityData?.field.name}
            id={entityData?.id}
        />
    );

    let element = null;

    if (nameEditMode) {
        element = nameEdit;
    } else {
        const dropField = <DropField />;
        const functionWrapper = (
            <FunctionWrapper
                functionData={savedFunctions}
                content={dropField}
                setNameEditMode={setNameEditMode}
                id={entityData?.id}
            />
        );

        switch (fieldType) {
            case 'FIELD':
                element = savedFunctions ? functionWrapper : dropField;
                break;
            case 'FORMULA':
                element = (
                    <Formula
                        savedFunctions={savedFunctions}
                        nameEditMode={nameEditMode}
                        dropField={dropField}
                        nameEdit={nameEdit}
                        functionWrapper={functionWrapper}
                        left={true}
                    />
                );
                break;
            default:
                break;
        }
    }

    return (
        <div className="field-wrapper left-field-wrapper">
            {allowRuleEdit && (
                <MoreActions
                    id={entityData?.id}
                    setNameEditMode={setNameEditMode}
                    showFunctionsPopover={!savedFunctions}
                    fieldType={fieldType}
                    // setFieldType={setFieldType}
                    showChangeAlias={true}
                    allowValueEntry={false}
                />
            )}

            {element}
        </div>
    );
}

function DropField() {
    const { fieldsGroupData } = useFieldsGroupContext();
    const fieldData = fieldsGroupData?.leftPart;
    const entityData = fieldData?.entityField;
    const dispatch = useDispatch();
    const { refetchRule } = useRuleContext();
    const { forJoin, currentGroupId } = useConditionContext();
    const { queryId } = useQueryContext();
    const getDefaultPutData = useGetDefaultPutData();
    const defaultData = getDefaultPutData();

    const [_collectedProps, drop] = useDrop(() => ({
        accept: 'entity-drop-target',
        drop: (item, monitor) => {
            console.log('Drop on readonly field');

            onEntityDrop(item.displayedEntityId, item.data, { ...defaultData });

            return {};
        },
        hover: (_item, monitor) => {
            dispatch(
                addHoverField({
                    queryId,
                    conditionGroupId: currentGroupId,
                    type: forJoin ? 'joinGroups' : 'conditionGroups',
                    fieldType: 'leftPart',
                    fieldId: fieldsGroupData.id,
                    hovered: true,
                })
            );

            // dispatch(clearConditionPlaceholder({type: 'joinGroups'}));
            // dispatch(clearConditionPlaceholder({type: 'conditionGroups'}));
        },
    }));


    async function onEntityDrop(leftDisplayedEntityId, field, putData) {
        const type = getRightType(
            field?.type,
            entityData?.field?.type,
            putData.type
        );

        putData.leftFieldId = field?.id;
        putData.leftDisplayedEntityId = leftDisplayedEntityId;
        putData.type = type;

        

        if (field.type === 'DATE') {
            putData.conditionInterval = 'DAY_BEFORE_NOW';
        }

        try {
            await updateField(putData);
            refetchRule();
        } catch (err) {
            console.error(err);
        }
    }

    return (
        <>
            {fieldData?.hovered ? (
                <div ref={drop} className="entitiy-drop-field">
                    Drop entity here
                </div>
            ) : (
                <div ref={drop}>
                    <TextBox
                        elementAttr={{
                            id: 'elementId',
                            class: 'class-name-test',
                        }}
                        stylingMode="outlined"
                        disabled
                        value={entityData?.name || entityData?.field?.name}
                    />
                </div>
            )}
        </>
    );
}
