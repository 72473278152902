import React, { useState } from 'react';
import { useCheckPermission } from '../../../hooks/useCheckPermission';
import CustomStore from 'devextreme/data/custom_store';
import axios from '../../../services/axios/config';
import Loader from '../../../components/loader/loader';
import { DataGrid } from 'devextreme-react';
import { Column } from 'devextreme-react/data-grid';
import { errorMsg, resolutionStatuses } from '../../../utils/vars';
import ExportButtons from '../exportButtons/exportButtons';

export default function ForMonitoring() {
    const [downloadLoading, setDownloadLoading] = useState(false);
    const [hasFetchError, setHasFetchError] = useState(true);
    const exportPermission = useCheckPermission('DOWNLOAD_REPORT');

    const customStore = new CustomStore({
        key: '',
        load(loadOptions) {
            const skip = loadOptions.skip;
            const take = loadOptions.take;
            const page = skip / take;
            return axios
                .post(`report?page=${page}&size=${take}`, {
                    type: 'FOR_MONITORING',
                    forExport: false,
                    reportExportType: 'PDF',
                })
                .then((res) => {
                    const data = res.data;
                    setHasFetchError(false);
                    return {
                        data: data.data,
                        totalCount: data.elements,
                    };
                })
                .catch((err) => {
                    console.error(err);
                    setHasFetchError(true);
                    throw errorMsg;
                });
        },
    });

    if (downloadLoading) {
        return <Loader height={'70vh'} />;
    }

    return (
        <div className="reports-wrapper for-monitoring-wrapper">
            <div className="flex-container download-btns">
                {exportPermission && (
                    <ExportButtons
                        hasFilters={true}
                        disabled={hasFetchError}
                        setLoading={setDownloadLoading}
                        reportType={'FOR_MONITORING'}
                    />
                )}
                {/* <Button
                      text={languageLables.generateReport[lg]}
                      type="default"
                  /> */}
            </div>
            <DataGrid
                allowColumnResizing={true}
                dataSource={customStore}
                remoteOperations={{ paging: true }}
            >
                <Column
                    allowSorting={false}
                    caption="Flag ID"
                    dataField={'flagId'}
                    alignment={'left'}
                />
                <Column
                    allowSorting={false}
                    caption="Resolution"
                    cellRender={(data) =>
                        resolutionStatuses[data.data?.resolution]
                    }
                />
                <Column
                    allowSorting={false}
                    caption="Resolution date"
                    dataField={'resolutionDate'}
                    dataType={'datetime'}
                />
                <Column
                    allowSorting={false}
                    caption="Due date"
                    dataField={'dueDate'}
                    dataType={'datetime'}
                />
                <Column
                    allowSorting={false}
                    caption="Execution check"
                    dataField={'executionCheck'}
                />
                <Column
                    allowSorting={false}
                    caption="Tenant"
                    dataField={'tenant'}
                />
            </DataGrid>
        </div>
    );
}
