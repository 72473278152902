import { createContext, useContext, useEffect, useState } from 'react';

const context = createContext();

export default function FieldsGroupContextProvider({ children, ...values }) {
    const [fieldsGroupData, setFieldsGroupData] = useState(null);

    useEffect(() => {
        setFieldsGroupData(values.fieldsGroupData)
    }, [values.fieldsGroupData]);

    return (
        <context.Provider value={{ ...values, fieldsGroupData }}>{children}</context.Provider>
    );
}

export const useFieldsGroupContext = () => useContext(context);
