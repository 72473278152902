import { createSlice } from '@reduxjs/toolkit';
import { act } from 'react-dom/test-utils';

function updateQueryReducer(state, action) {
    const { queryId, update } = action.payload;
    if (!queryId) {
        console.error("'queryId' is required!");
    }

    function updateQuery(queries) {
        for (const i in queries) {
            const q = queries[i];

            if (q.id === queryId) {
                queries[i] =
                    typeof update === 'function'
                        ? update(q)
                        : { ...q, ...update };
                return;
            }
        }

        for (const q of queries) {
            q.queries && updateQuery(q.queries);
        }
    }

    updateQuery(state);

    return state;
}

function updateQueryConditionsReducer(
    state,
    queryId,
    conditionGroupsUpdater,
    forJoin
) {
    console.log('forJoin', forJoin);
    updateQueryReducer(state, {
        payload: {
            queryId,
            update: (q) => {
                console.log('Call updater for', JSON.parse(JSON.stringify(q)));
                if (forJoin) {
                    conditionGroupsUpdater(q.joinGroups);
                } else {
                    conditionGroupsUpdater(q.conditionGroups);
                }
                console.log('Updated query: ', JSON.parse(JSON.stringify(q)));
                return q;
            },
        },
    });
}

const conditionsSlice = createSlice({
    name: 'queries',
    initialState: [],
    reducers: {
        setConditions: (state, action) => {
            const { queryId, conditions } = action.payload;

            updateQueryReducer(state, {
                payload: {
                    queryId,
                    update: { conditions },
                },
            });

            return state;
        },
        setQueries: (_, action) => action.payload,
        updateQuery: updateQueryReducer,
        addGroup: (state, action) => {
            const { queryId, parentConditionGroupId, obj, forJoin } =
                action.payload;

            // console.log(
            //     JSON.parse(JSON.stringify(state)),
            //     queryId,
            //     parentConditionGroupId,
            //     obj
            // );

            function updateFields(groups) {
                if (!parentConditionGroupId) {
                    groups.push(obj);
                    return;
                }

                groups?.forEach((e) => {
                    if (e.id == parentConditionGroupId) {
                        if (e.groups) {
                            e.groups.push(obj);
                        } else {
                            e.groups = [obj];
                        }
                    } else if (e.groups) {
                        updateFields(e.groups);
                    }
                });
            }

            updateQueryConditionsReducer(state, queryId, updateFields, forJoin);

            console.log(JSON.parse(JSON.stringify(state)));
            return state;
        },
        deleteGroup: (state, action) => {
            const { queryId, groupId, forJoin } = action.payload;

            function deleteGroupHandler(conditions) {
                conditions.forEach((conditionGroup, i) => {
                    let index = -1;
                    if (conditionGroup.id === groupId) {
                        index = i;
                    } else if (!!conditionGroup.groups) {
                        deleteGroupHandler(conditionGroup.groups);
                    }
                    if (index !== -1) {
                        conditions.splice(index, 1);
                    }
                });
            }

            updateQueryConditionsReducer(
                state,
                queryId,
                deleteGroupHandler,
                forJoin
            );

            return state;
        },
        addField: (state, action) => {
            const { conditionId, obj, queryId, forJoin } = action.payload;

            function updateFields(groups) {
                groups?.forEach((e) => {
                    if (e.id == conditionId) {
                        e.fields?.push(obj);
                    } else if (e.groups) {
                        updateFields(e.groups);
                    }
                });
            }

            updateQueryConditionsReducer(state, queryId, updateFields, forJoin);

            return state;
        },
        updateCondition: (state, action) => {
            const { fieldId, update, queryId, forJoin } = action.payload;

            console.log('update', update);
            // To do: optimise
            function dropEntitiyHandler(conditionGroups) {
                conditionGroups.forEach((group) => {
                    group.fields.forEach((field, i) => {
                        if (field.id === fieldId) {
                            field.isSaved = false;
                            group.fields[i] = { ...field, ...update };
                            console.log(JSON.parse(JSON.stringify(group)));
                        }
                    });

                    if (!!group.groups) {
                        dropEntitiyHandler(group.groups);
                    }
                });

                console.log(JSON.parse(JSON.stringify(conditionGroups)));
            }

            updateQueryConditionsReducer(
                state,
                queryId,
                dropEntitiyHandler,
                forJoin
            );

            console.log(JSON.parse(JSON.stringify(state)));

            return state;
        },
        addFunction: (state, action) => {
            const {
                fieldGroupId,
                type,
                parentFunctionId,
                obj,
                queryId,
                forJoin,
            } = action.payload;

            console.log(action.payload);

            function findGroupHandler(conditions) {
                conditions.forEach((condition) => {
                    condition.fields.forEach((fieldGroup) => {
                        if (fieldGroup.id === fieldGroupId) {
                            fieldGroup.isSaved = false;
                            if (!fieldGroup[type]) {
                                fieldGroup[type] = obj;
                            } else {
                                addFunctionHandler(fieldGroup[type]);
                            }
                        }

                        function addFunctionHandler(functionObj) {
                            if (functionObj.id === parentFunctionId) {
                                functionObj.childConditionFieldFunction = obj;
                            } else if (
                                !!fieldGroup.childConditionFieldFunction
                            ) {
                                addFunctionHandler(
                                    fieldGroup.childConditionFieldFunction
                                );
                            }
                        }

                        if (!!condition.groups) {
                            findGroupHandler(condition.groups);
                        }
                    });
                });
            }

            updateQueryConditionsReducer(
                state,
                queryId,
                findGroupHandler,
                forJoin
            );

            return state;
        },
        deleteFunction: (state, action) => {
            const { fieldGroupId, type, parentFunctionId, queryId, forJoin } =
                action.payload;
            console.log(fieldGroupId, type, parentFunctionId);

            function findGroupHandler(conditions) {
                conditions.forEach((condition) => {
                    condition.fields.forEach((fieldGroup) => {
                        if (fieldGroup.id === fieldGroupId) {
                            if (parentFunctionId) {
                                deleteHandler(fieldGroup[type]);
                            } else {
                                fieldGroup[type] = null;
                            }
                        }

                        function deleteHandler(functionObj) {
                            if (functionObj.id === parentFunctionId) {
                                console.log('functionObj');
                                functionObj.childConditionFieldFunction = null;
                            } else if (
                                !!fieldGroup.childConditionFieldFunction
                            ) {
                                deleteHandler(
                                    fieldGroup.childConditionFieldFunction
                                );
                            }
                        }

                        if (!!condition.groups) {
                            findGroupHandler(condition.groups);
                        }
                    });
                });
            }

            updateQueryConditionsReducer(
                state,
                queryId,
                findGroupHandler,
                forJoin
            );

            return state;
        },
        removeFunctions: (state, action) => {
            const { fieldGroupId, type, queryId, forJoin } = action.payload;

            function findGroupHandler(conditions) {
                conditions.forEach((condition) => {
                    condition.fields.forEach((fieldGroup) => {
                        if (fieldGroup.id === fieldGroupId) {
                            fieldGroup[type] = null;
                        }

                        if (!!condition.groups) {
                            findGroupHandler(condition.groups);
                        }
                    });
                });
            }

            updateQueryConditionsReducer(
                state,
                queryId,
                findGroupHandler,
                forJoin
            );

            return state;
        },
        deleteField: (state, action) => {
            const { fieldGroupId, type, queryId, forJoin } = action.payload;

            function deleteHandler(conditions) {
                conditions.forEach((condition) => {
                    condition.fields.forEach((fieldGroup) => {
                        if (fieldGroup.id === fieldGroupId) {
                            fieldGroup[type] = null;
                            if (type === 'rightEntityField') {
                                fieldGroup.rightDisplayedEntityId = null;
                                fieldGroup.rightFieldId = null;
                                fieldGroup.rightConditionFieldFunction = null;
                            } else {
                                fieldGroup.leftDisplayedEntityId = null;
                                fieldGroup.leftFieldId = null;
                                fieldGroup.leftConditionFieldFunction = null;
                            }
                        }
                    });

                    if (!!condition.groups) {
                        deleteHandler(condition.groups);
                    }
                });
            }

            updateQueryConditionsReducer(
                state,
                queryId,
                deleteHandler,
                forJoin
            );

            return state;
        },
        deleteCondition: (state, action) => {
            const { fieldId, queryId, forJoin } = action.payload;

            function deleteHandler(conditions) {
                conditions.forEach((condition) => {
                    let isFound = false;

                    condition.fields = condition.fields.filter((field) => {
                        if (field.id === fieldId) {
                            isFound = true;
                            return false;
                        }

                        return true;
                    });

                    if (!isFound && !!condition.groups) {
                        deleteHandler(condition.groups);
                    }
                });
            }

            updateQueryConditionsReducer(
                state,
                queryId,
                deleteHandler,
                forJoin
            );

            return state;
        },
        updateValue: (state, action) => {
            const { fieldGroupId, value, queryId, forJoin } = action.payload;
            console.log('payload', action.payload);
            function updateValueHandler(conditions) {
                conditions.forEach((condition) => {
                    condition.fields.forEach((fieldGroup) => {
                        if (fieldGroup.id === fieldGroupId) {
                            fieldGroup.isSaved = false;
                            fieldGroup.value = value;
                        }

                        if (!!condition.groups) {
                            updateValueHandler(condition.groups);
                        }
                    });
                });
            }

            updateQueryConditionsReducer(
                state,
                queryId,
                updateValueHandler,
                forJoin
            );
            return state;
        },
        updateConditionInterval: (state, action) => {
            const { fieldGroupId, conditionInterval, queryId, forJoin } =
                action.payload;

            function updateValueHandler(conditions) {
                conditions.forEach((condition) => {
                    condition.fields.forEach((fieldGroup) => {
                        console.log(JSON.parse(JSON.stringify(fieldGroup)));
                        if (fieldGroup.id === fieldGroupId) {
                            fieldGroup.isSaved = false;
                            fieldGroup.conditionInterval = conditionInterval;
                        }

                        if (!!condition.groups) {
                            updateValueHandler(condition.groups);
                        }
                    });
                });
            }

            updateQueryConditionsReducer(
                state,
                queryId,
                updateValueHandler,
                forJoin
            );
            return state;
        },
        setComponentFieldType: (state, action) => {
            const { fieldGroupId, type, fieldType, queryId, forJoin } =
                action.payload;

            function updateField(conditions) {
                conditions.forEach((condition) => {
                    condition.fields.forEach((fieldGroup) => {
                        // console.log(JSON.parse(JSON.stringify(fieldGroup)));
                        if (fieldGroup.id === fieldGroupId) {
                            if (type === 'rightEntityField') {
                                fieldGroup.rightComponentFieldType = fieldType;
                            } else {
                                fieldGroup.leftComponentFieldType = fieldType;
                            }
                        }
                    });

                    if (!!condition.groups) {
                        updateField(condition.groups);
                    }
                });
            }

            updateQueryConditionsReducer(state, queryId, updateField, forJoin);
            return state;
        },
        saveQuery: (state, action) => {
            const { queryId, forJoin } = action.payload;

            function saveHandler(conditionGroups) {
                conditionGroups.forEach((group) => {
                    group.fields.forEach((field, i) => {
                        field.isSaved = true;
                        // if (field.id === fieldId) {
                        //     field.isSaved = false;
                        //     group.fields[i] = { ...field, ...update };
                        //     console.log(JSON.parse(JSON.stringify(group)));
                        // }
                    });

                    if (!!group.groups) {
                        saveHandler(group.groups);
                    }
                });
            }

            updateQueryConditionsReducer(state, queryId, saveHandler, forJoin);
            return state;
        },
    },
});

export default conditionsSlice.reducer;

export const {
    setConditions,
    addGroup,
    addField,
    dropEntity,
    deleteField,
    deleteCondition,
    addFunction,
    updateValue,
    setComponentFieldType,
    deleteGroup,
    updateConditionInterval,
    updateCondition,
    removeFunctions,
    deleteFunction,
    setQueries,
    updateQuery,
    saveQuery
} = conditionsSlice.actions;
