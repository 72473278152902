import { createSlice } from '@reduxjs/toolkit';

const initialLanguage = localStorage.getItem('tm-language');
 const languageSlice = createSlice({
    name: 'language',
    initialState: initialLanguage || 'en-US',
    reducers: {
        changeLanguage: (_state, action) => {
            return action.payload
        },
    },
});

export const { changeLanguage } = languageSlice.actions;

export default languageSlice.reducer;

export const selectLanguage = state => state.language;
