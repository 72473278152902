import { createSlice } from '@reduxjs/toolkit';

const ruleSlice = createSlice({
    name: 'ruleData',
    initialState: {},
    reducers: {
        updateRuleData: (_state, action) => {
            return action.payload;
        },
        addConditionPlaceholder: (state, action) => {
            const { queryId, conditionGroupId, isNestedGroup, type } =
                action.payload;

            addPlaceholder(state.queries);
            return state;

            function addPlaceholder(queries) {
                queries.forEach((e) => {
                    if (e.id === queryId) {
                        if (!conditionGroupId && !e[type]?.length) {
                            e[type]?.push({ withPlaceholder: true });
                        }

                        modifyGroup(e[type]);
                    } else if (!!e.queries?.length) {
                        addPlaceholder(e.queries);
                    }

                    function modifyGroup(groups) {
                        groups.forEach((group) => {
                            if (group.id === conditionGroupId) {
                                const lastGroup =
                                    !!group.groups?.length &&
                                    group.groups[group.groups.length - 1];

                                if (isNestedGroup) {
                                    if (
                                        !lastGroup ||
                                        lastGroup !== 'placeholder'
                                    ) {
                                        group.groups?.push('placeholder');
                                    }
                                } else {
                                    group.withPlaceholder = true;
                                }
                            }

                            if (!!group.groups?.length) {
                                modifyGroup(group.groups);
                            }
                        });
                    }
                });
            }
        },
        clearConditionPlaceholder: (state, action) => {
            const { type } = action.payload;
            handleQueries(state.queries);

            function handleQueries(queries) {
                queries.forEach((query) => {
                    handleGroups(query[type]);

                    if (!!query.queries?.length) {
                        handleQueries(query.queries);
                    }

                    function handleGroups(groups) {
                        groups.forEach((conditionGroup, index) => {
                            if (conditionGroup.withPlaceholder) {
                                delete conditionGroup.withPlaceholder;
                            }

                            if (conditionGroup === 'placeholder') {
                                groups.splice(index, 1);
                            }

                            if (!!conditionGroup.groups?.length) {
                                handleGroups(conditionGroup.groups);
                            }
                        });
                    }
                });
            }

            return state;
        },
        addHoverField: (state, action) => {
            const {
                queryId,
                conditionGroupId,
                type,
                fieldType,
                fieldId,
                hovered,
            } = action.payload;
            addHover(state.queries);
            return state;

            function addHover(queries) {
                queries.forEach((query) => {
                    if (query.id === queryId) {
                        modifyGroup(query[type]);
                    } else if (!!query.queries?.length) {
                        addHover(query.queries);
                    }

                    function modifyGroup(groups) {
                        groups.forEach((group) => {
                            if (group.id === conditionGroupId) {
                                group.fields.forEach((field) => {
                                    if (field.id === fieldId &&  field[fieldType]) {
                                        field[fieldType].hovered = hovered;
                                    }
                                });
                            }

                            if (!!group.groups?.length) {
                                modifyGroup(group.groups);
                            }
                        });
                    }
                });
            }
        },
        removeHoverField: (state, action) => {
            const { type } = action.payload;
            queriesHandler(state.queries);

            return state;

            function queriesHandler(queries) {
                queries.forEach((query) => {
                    groupsHandler(query[type]);
                    if (!!query.queries?.length) {
                        queriesHandler(query.queries);
                    }

                    function groupsHandler(groups) {
                        groups.forEach((group) => {
                            group.fields?.forEach((field) => {
                                if (field.leftPart) {
                                    field.leftPart.hovered = false;
                                }
                                if (field.rightPart) {
                                    field.rightPart.hovered = false;
                                }
                            });
                            if (!!group.groups?.length) {
                                groupsHandler(group.groups);
                            }
                        });
                    }
                });
            }
        },
    },
});

export default ruleSlice.reducer;

export const {
    updateRuleData,
    addConditionPlaceholder,
    clearConditionPlaceholder,
    addHoverField,
    removeHoverField,
} = ruleSlice.actions;
  
export const selectRule = state => state.rule;