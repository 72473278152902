import React from 'react';
import { useState } from 'react';
import { useContext } from 'react';
import { createContext } from 'react';
import { getActionsPermissions } from '../services/actions';

const context = createContext();

export default function ActionsRoleProvider({
    children,
    role,
    defaultActionsData,
    tenancy
}) {
    const [actionsData, setActionsData] = useState(defaultActionsData);
    
    async function refetchActionsData() {
        const res = await getActionsPermissions();
        setActionsData(res);
    }

    return (
        <context.Provider value={{ role, actionsData, refetchActionsData, tenancy }}>
            {children}
        </context.Provider>
    );
}

export const useActionsRoleContext = () => useContext(context);
