import { createSlice } from '@reduxjs/toolkit';

const theme = localStorage.getItem('theme');

 const themeSlice = createSlice({
    name: 'language',
    initialState: theme || 'light',
    reducers: {
        changeTheme: (_state, action) => {
            return action.payload
        },
    },
});

export const { changeTheme } = themeSlice.actions;

export default themeSlice.reducer;
