import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment/moment';
import { setFilters } from '../../../redux/features/filters';
import Loader from '../../../components/loader/loader';
import { errorMsg, flagStatuses, languageLables } from '../../../utils/vars';
import {
    Button,
    DataGrid,
    DateBox,
    SelectBox,
    TextBox,
} from 'devextreme-react';
import axios from '../../../services/axios/config';
import CustomStore from 'devextreme/data/custom_store';
import { Column } from 'devextreme-react/data-grid';
import { useCheckPermission } from '../../../hooks/useCheckPermission';
import { Checkbox } from '@mui/material';
import {
    addId,
    removeId,
    selectAll,
    unSelectAll,
    uncheckHeader,
} from '../../../redux/features/reportsSelected';
import ExportButtons from '../exportButtons/exportButtons';

function HeaderCheckbox() {
    const { allSelected } = useSelector((state) => state.reportsSelected);
    const dispatch = useDispatch();

    return (
        <Checkbox
            checked={allSelected}
            onChange={(ev) => {
                if (ev.target.checked) {
                    dispatch(selectAll());
                } else {
                    dispatch(unSelectAll());
                    dispatch(uncheckHeader());
                }
            }}
        />
    );
}

function CellCheckbox({ id }) {
    const { allSelected, selected } = useSelector(
        (state) => state.reportsSelected
    );
    const dispatch = useDispatch();
    const index = selected.findIndex((e) => e === id);
    let isSelected = false;

    useEffect(() => {
        if (allSelected && index === -1) {
            dispatch(addId(id));
        }
    }, [allSelected]);

    if (allSelected) {
        isSelected = true;
    } else {
        if (index !== -1) {
            isSelected = true;
        }
    }

    function checkboxHandler(checked) {
        if (checked) {
            dispatch(addId(id));
        } else {
            if (allSelected) {
                dispatch(uncheckHeader());
            }
            dispatch(removeId(id));
        }
    }

    return (
        <Checkbox
            checked={isSelected}
            onChange={(ev) => checkboxHandler(ev.target.checked)}
        />
    );
}

export default function SingleReports() {
    const [showReport, setShowReport] = useState(false);
    const [filtersValues, setFiltersValues] = useState({});
    const lg = useSelector((state) => state.language);
    const [downloadLoading, setDownloadLoading] = useState(false);
    const [_rerender, setRerender] = useState(0);
    const dispatch = useDispatch();

    function generateReport() {
        const setFilterValues = { ...filtersValues };
        if (setFilterValues.periodFrom) {
            setFilterValues.periodFrom = moment(
                setFilterValues.periodFrom
            ).format('YYYY-MM-DD');
        }

        if (setFilterValues.periodTo) {
            setFilterValues.periodTo = moment(setFilterValues.periodTo).format(
                'YYYY-MM-DD'
            );
        }

        dispatch(setFilters(setFilterValues));
        setShowReport(true);
        setRerender((oldState) => oldState + 1);
    }

    function updateFilterValue(key, value) {
        setFiltersValues((oldState) => {
            return { ...oldState, [key]: value };
        });
    }

    if (downloadLoading) {
        return <Loader height={'70vh'} />;
    }

    function Export() {
        const filters = useSelector((state) => state.filters);
        const { allSelected, selected } = useSelector(
            (state) => state.reportsSelected
        );
        let postData = {
            periodFrom:
                (filters?.periodFrom &&
                    moment(filters?.periodFrom).format('YYYY-MM-DD')) ||
                '',
            periodTo:
                (filters?.periodTo &&
                    moment(filters?.periodTo).format('YYYY-MM-DD')) ||
                '',
            forExport: true,
            type: 'SINGLE',
            clientType: filters?.clientType,
            clientUic: filters?.clientUic,
            cid: filters?.cid,
            name: filters?.name,
            ids: allSelected || !selected.length ? [] : selected,
        };

        return (
            <ExportButtons
                reportType={'SINGLE'}
                setLoading={setDownloadLoading}
                filters={filters}
                disabled={!showReport}
                singlePostData={postData}
                />
        );
    }

    return (
        <div className="reports-wrapper single-report-wrapper">
            <div className="flex-container download-btns">
                <Export />
                <Button
                    text="Clear"
                    disabled={
                        filtersValues && !Object.keys(filtersValues).length
                    }
                    onClick={() => {
                        setShowReport(false);
                        setFiltersValues({});
                        dispatch(setFilters({}));
                    }}
                />
                <Button
                    text={languageLables.generateReport[lg]}
                    onClick={generateReport}
                    type="default"
                />
            </div>
            <div className="filters-section">
                <DateBox
                    stylingMode="underlined"
                    width={270}
                    label="From date"
                    value={filtersValues.periodFrom || null}
                    onValueChange={(v) => updateFilterValue('periodFrom', v)}
                />
                <DateBox
                    stylingMode="underlined"
                    width={270}
                    label="To date"
                    value={filtersValues.periodTo || null}
                    onValueChange={(v) => updateFilterValue('periodTo', v)}
                />
                <SelectBox
                    value={filtersValues.clientType || ''}
                    stylingMode="underlined"
                    label="Client type"
                    displayExpr={'label'}
                    valueExpr={'value'}
                    width={270}
                    items={[
                        { value: 'natural', label: 'Natural person' },
                        { value: 'legal', label: 'Legal entity' },
                    ]}
                    onValueChange={(v) => updateFilterValue('clientType', v)}
                />
                <TextBox
                    value={filtersValues.clientUic || ''}
                    stylingMode="underlined"
                    width={270}
                    label="Client UIC (EGN / Company number)"
                    onInput={(ev) =>
                        updateFilterValue('clientUic', ev.event.target.value)
                    }
                />
                <TextBox
                    stylingMode="underlined"
                    value={filtersValues.cid || ''}
                    width={270}
                    label="ID/Offer ID"
                    onInput={(ev) =>
                        updateFilterValue('cid', ev.event.target.value)
                    }
                />
                <TextBox
                    stylingMode="underlined"
                    value={filtersValues.name || ''}
                    width={270}
                    label="Name / Company name"
                    onInput={(ev) =>
                        updateFilterValue('name', ev.event.target.value)
                    }
                />
            </div>
            <SingleReportTable
                show={showReport}
                setDownloadLoading={setDownloadLoading}
            />
        </div>
    );
}

const SingleReportTable = React.memo(function SingleReportTable({ show }) {
    const filters = useSelector((state) => state.filters);
    const exportPermission = useCheckPermission('DOWNLOAD_REPORT');

    const [downloading, setDownloading] = useState(false);
    if (!show) {
        return null;
    }

    if (downloading) {
        return <Loader height={'300px'} />;
    }

    const postData = {
        type: 'SINGLE',
        clientType: filters?.clientType || '',
        clientUic: filters?.clientUic || '',
        cid: filters?.cid || '',
        name: filters?.name || '',
        periodFrom:
            (filters?.periodFrom &&
                moment(filters?.periodFrom).format('YYYY-MM-DD')) ||
            '',
        periodTo:
            (filters?.periodTo &&
                moment(filters?.periodTo).format('YYYY-MM-DD')) ||
            '',
        forExport: false,
    };

    const customStore = new CustomStore({
        key: '',
        load(loadOptions) {
            const skip = loadOptions.skip;
            const take = loadOptions.take;
            const page = skip / take;
            return axios
                .post(`report?page=${page}&size=${take}`, postData)
                .then((res) => {
                    const data = res.data;
                    return {
                        data: data.data,
                        totalCount: data.elements,
                    };
                    // return mapped;
                })
                .catch((err) => {
                    console.error(err);
                    throw errorMsg;
                });
        },
    });

    return (
        <div className="table-wrapper">
            <DataGrid
                allowColumnResizing={true}
                keyExpr={'id'}
                remoteOperations={{ paging: true }}
                dataSource={customStore}
            >
                <Column
                    allowSorting={false}
                    caption="Type of rule"
                    dataField={'typeOfRule'}
                />
                <Column
                    allowSorting={false}
                    caption="Rule name"
                    dataField={'ruleName'}
                />
                <Column
                    allowSorting={false}
                    caption="Case status"
                    dataField={'caseStatus'}
                    cellRender={(data) =>
                        flagStatuses[data.data?.caseStatus] || ''
                    }
                />
                <Column
                    allowSorting={false}
                    caption="Link to case"
                    dataField={'linkToCase'}
                    cellRender={(data) => {
                        return (
                            <a
                                rel="noreferrer"
                                target="_blank"
                                href={data.data?.linkToCase}
                            >
                                {data.data?.linkToCase}
                            </a>
                        );
                    }}
                />
                <Column
                    allowSorting={false}
                    caption="Username and role"
                    dataField={'userNameAndRole'}
                />
                <Column
                    allowSorting={false}
                    dataType={'datetime'}
                    caption="Date of case status"
                    dataField={'dateOfCaseStatus'}
                />
                <Column
                    allowSorting={false}
                    dataType={'datetime'}
                    caption="Date of resolution status"
                    dataField={'dateOfResolutionStatus'}
                />
                {exportPermission && (
                    <Column
                        alignment="center"
                        caption="Export"
                        allowSorting={false}
                        headerCellComponent={(data) => (
                            <HeaderCheckbox data={data.data} />
                        )}
                        // cellComponent={(data) => <Export data={data.data} />}
                        cellComponent={(data) => (
                            <CellCheckbox id={data.data.data.id} />
                        )}
                    />
                )}
            </DataGrid>
        </div>
    );
});
