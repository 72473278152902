import React, { createContext, useContext } from 'react';
import { useQuery } from 'react-query';
import Loader from '../../../components/loader/loader';
import { getFunctions } from '../../../services/rules';
import { reactQueryOptions } from '../../../utils/reactQueryOptions';

const context = createContext();

export default function FunctionsDataContextProvider({ children }) {
    const { data: functionsData, isLoading } = useQuery(
        'functions',
        getFunctions,
        reactQueryOptions
    );

    return (
        <context.Provider value={{ functionsData }}>
            {isLoading ? <Loader height={'100vh'}/> : children}
        </context.Provider>
    );
}

export const useFunctionsDataContext = () => useContext(context);