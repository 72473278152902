import Stack from '@mui/material/Stack';
import {
    Button,
    DateBox,
    Popup,
    SelectBox,
    TextArea,
    CheckBox,
} from 'devextreme-react';
import moment from 'moment';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useToastContext } from '../../../components/toast/toast';
import { useActionsRoleContext } from '../../../contexts/actionsRoleProvider';
import { changeFlagStatus } from '../../../services/flag';

export default function Resolution({
    resolutionStatus,
    refetch,
    disabled,
    flagStatus,
    dueDate,
    dueDateCheck,
}) {
    const { role } = useActionsRoleContext();
    const [resolutionValue, setResolutionValue] = useState(null);

    const [statusPopupOpen, setStatusPopupOpen] = useState(false);
    const statusOptions = {
        PENDING: [
            { label: 'Document Enquiry', value: 'DOCUMENT_ENQUIRY' },
            { label: 'For Investigation', value: 'FOR_INVESTIGATION' },
        ],
        IN_PROGRESS: [
            { label: 'Document Enquiry', value: 'DOCUMENT_ENQUIRY' },
            { label: 'For Investigation', value: 'FOR_INVESTIGATION' },
            { label: 'False Positive', value: 'FALSE_POSITIVE' },
            {
                label: 'Termination of the contract',
                value: 'TERMINATION_OF_CONTRACT',
            },
            {
                label: 'Transaction allowed',
                value: 'TRANSACTION_ALLOWED',
            },
            { label: 'Stop the operation', value: 'STOP_OPERATION' },
            { label: 'For Reporting', value: 'FOR_REPORTING' },
            { label: 'For Monitoring', value: 'FOR_MONITORING' },
        ],
        COMPLETED: [
            { label: 'False Positive', value: 'FALSE_POSITIVE' },
            {
                label: 'Termination of the contract',
                value: 'TERMINATION_OF_CONTRACT',
            },
            {
                label: 'Transaction allowed',
                value: 'TRANSACTION_ALLOWED',
            },
            { label: 'Stop the operation', value: 'STOP_OPERATION' },
            { label: 'For Reporting', value: 'FOR_REPORTING' },
            { label: 'For Monitoring', value: 'FOR_MONITORING' },
        ],
        ESCALATED: [
            { label: 'False Positive', value: 'FALSE_POSITIVE' },
            {
                label: 'Termination of the contract',
                value: 'TERMINATION_OF_CONTRACT',
            },
            {
                label: 'Transaction allowed',
                value: 'TRANSACTION_ALLOWED',
            },
            { label: 'Stop the operation', value: 'STOP_OPERATION' },
            { label: 'For Reporting', value: 'FOR_REPORTING' },
            { label: 'For Monitoring', value: 'FOR_MONITORING' },
        ],
    };
    const options = statusOptions[flagStatus];
    const currentDate = new Date();

    if (
        !!options &&
        resolutionStatus === 'FOR_MONITORING' &&
        role === 'AML_MANAGER'
    ) {
        options.push({ value: 'INCREASEDRISK', label: 'Increased risk' });
        options.push({ value: 'DECREASEDRISK', label: 'Decreased risk' });
    }

    const isDueDate = areDatesEqual();

    function areDatesEqual() {
        const dueDateObj = new Date(dueDate);
        return (
            currentDate.getFullYear() === dueDateObj?.getFullYear() &&
            currentDate.getMonth() === dueDateObj?.getMonth() &&
            currentDate.getDate() === dueDateObj?.getDate()
        );
    }
    return (
        <div>
            <div className="flex-container  resolution">
                <SelectBox
                    disabled={disabled}
                    defaultValue={resolutionStatus}
                    stylingMode="underlined"
                    height={'50px'}
                    items={options}
                    valueExpr="value"
                    displayExpr="label"
                    onValueChange={(v) => setResolutionValue(v)}
                />
                <Button
                    disabled={
                        !resolutionValue || resolutionStatus === resolutionValue
                    }
                    stylingMode="outlined"
                    type="default"
                    text="Change status"
                    onClick={() => setStatusPopupOpen(true)}
                />
                <StatusDescriptionPopup
                    resolutionValue={resolutionValue}
                    isOpen={statusPopupOpen}
                    close={() => setStatusPopupOpen(false)}
                    refetch={refetch}
                    showDatebox={resolutionValue === 'FOR_MONITORING'}
                />
            </div>
            {isDueDate && (
                <Stack flexDirection="row" gap="10px">
                    {moment(dueDate).format('DD/MM/yyyy')}{' '}
                    <DueDateCheckbox
                        disabled={disabled || !!dueDateCheck}
                        dueDateCheck={dueDateCheck}
                        refetch={refetch}
                        dueDate={dueDate}
                    />
                </Stack>
            )}
        </div>
    );
}

function StatusDescriptionPopup({
    resolutionValue,
    isOpen,
    close,
    refetch,
    showDatebox,
}) {
    const [description, setDescription] = useState(null);
    const [reviewJustification, setReviewJustification] = useState(null);
    const [dateBoxValue, setDateBoxValue] = useState(null);
    const { id } = useParams();
    const { role } = useActionsRoleContext();
    const btnDisabled = getBtnDisabledValue();
    const { showSuccessToast, showErrorToast } = useToastContext();

    function getBtnDisabledValue() {
        if (role === 'AML_MANAGER') {
            return resolutionValue === 'FOR_MONITORING'
                ? !description || !dateBoxValue || !reviewJustification
                : !description || !reviewJustification;
        }

        return resolutionValue === 'FOR_MONITORING'
            ? !description || !dateBoxValue
            : !description;
    }

    async function changeStatusHandler() {
        try {
            await changeFlagStatus({
                id,
                resolutionStatus: resolutionValue,
                description,
                dueDate: dateBoxValue,
                reviewJustification,
            });
            refetch();
            showSuccessToast('Status successfully changed.')
        } catch (err) {
            console.error(err);
            showErrorToast(err?.response?.data?.message);
        }
    }

    function cancelHandler() {
        setDescription(null);
        close();
    }

    return (
        <Popup visible={isOpen} maxWidth={270} height="auto" showTitle={false}>
            <Stack sx={{ padding: '10px 0' }} gap={'10px'}>
                {showDatebox && (
                    <DateBox
                        label="Due date"
                        stylingMode="outlined"
                        onValueChange={(v) => {
                            setDateBoxValue(
                                moment(v).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
                            );
                        }}
                    />
                )}
                <TextArea
                    onInput={(e) => setDescription(e.event.target.value)}
                    label="Description"
                    stylingMode="outlined"
                />
                {role === 'AML_MANAGER' && (
                    <TextArea
                        onInput={(e) =>
                            setReviewJustification(e.event.target.value)
                        }
                        label="Review justification"
                        stylingMode="outlined"
                    />
                )}

                <Stack flexDirection={'row'} justifyContent={'space-between'}>
                    <Button
                        text="Cancel"
                        onClick={cancelHandler}
                        type="danger"
                    />
                    <Button
                        text="Confirm"
                        onClick={changeStatusHandler}
                        type="success"
                        disabled={btnDisabled}
                    />
                </Stack>
            </Stack>
        </Popup>
    );
}

function DueDateCheckbox({ dueDateCheck, refetch, dueDate, disabled }) {
    const { id } = useParams();
    async function checkDueDateHandler() {
        try {
            await changeFlagStatus({
                resolutionStatus: 'FOR_MONITORING',
                id,
                dueDate,
                dueDateCheck: true,
            });
            refetch();
        } catch (err) {
            console.error(err);
        }
    }

    return (
        <CheckBox
            disabled={disabled}
            onValueChange={checkDueDateHandler}
            defaultValue={!!dueDateCheck}
        />
    );
}
