import Stack from '@mui/material/Stack';
import { Button, DataGrid } from 'devextreme-react';
import { Column } from 'devextreme-react/data-grid';
import { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useToastContext } from '../../../components/toast/toast';
import axios from '../../../services/axios/config';

export default function DocumentsTable({ documentsData, refetch }) {
    const { id } = useParams();
    const inputRef = useRef();
    const { showErrorToast } = useToastContext();

    async function uploadHandler(file) {
        const fd = new FormData();
        fd.append('file', file);

        if (file.size > 10 * 1024 * 1024) {
            showErrorToast('File size should be less than 10MB')
            return;
        }

        try {
            await axios.post(`flag/file/upload?flagId=${id}`, fd);
            refetch();
        } catch (err) {
            console.error(err);
            showErrorToast(err?.response?.data?.message ||
                'Something happened. Try again later.')
        }
    }

    return (
        <div className="documents-table-wrapper flag-table">
            <Stack
                flexDirection="row"
                justifyContent="space-between"
                alignItems="end"
                sx={{ marginBottom: '10px' }}
            >
                <h5>Related documents</h5>
                <Button
                    text="Upload"
                    stylingMode="outlined"
                    type="default"
                    icon="upload"
                    onClick={() => inputRef.current.click()}
                />
                <input
                    type="file"
                    style={{ display: 'none' }}
                    ref={inputRef}
                    onChange={(ev) => uploadHandler(ev.target.files[0])}
                />
            </Stack>
            <DataGrid dataSource={documentsData}>
                <Column
                    caption="File name"
                    cellRender={(data) => {
                        return data.data.name;
                    }}
                    allowResizing={true}
                />
                {/* <Column
                    alignment="center"
                    allowResizing={true}
                    caption="Download"
                    cellComponent={(data) => (
                        <DownloadFile
                            url={data.data.data.url}
                            filename={data.data.data.name}
                        />
                    )}
                /> */}
                <Column
                    alignment="center"
                    allowResizing={true}
                    caption="Download"
                    cellComponent={DownloadFile}
                />
            </DataGrid>
        </div>
    );
}

function DownloadFile({ data }) {
    const { url, filename } = data?.data;

    function downloadHandler() {
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = filename;
        anchor.style.display = 'none';

        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
    }

    return <Button icon="download" onClick={downloadHandler} />;
}
