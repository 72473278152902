import { DataGrid } from "devextreme-react";
import { Column } from "devextreme-react/data-grid";
import { flagStatuses } from "../../../utils/vars";

export default function StatusReviewHistory({ dataSource }) {
    return (
        <div className='flag-table'>
            <h5>Review status history</h5>
            <DataGrid dataSource={dataSource} keyExpr={'id'}>
                <Column
                    allowSorting={false}
                    allowResizing={true}
                    caption={'Date'}
                    dataField={'date'}
                    dataType="datetime"
                />
                <Column
                    allowSorting={false}
                    allowResizing={true}
                    caption={'Review status'}
                    dataField={'status'}
                    cellRender={(data) => flagStatuses[data.data.status]}
                />
                <Column
                    allowSorting={false}
                    allowResizing={true}
                    caption={'Employee'}
                    cellRender={(data) => data.data.user?.name}
                />
            </DataGrid>
        </div>
    );
}