import axios from './axios/config';

export const getMonitoringQueue = async () => {
    const res = await axios.get('flag/monitoring-queue');
    return res.data;
};

export const getFlagById = async (id) => {
    const res = await axios.get(`/flag/${id}/monitoring-queue`);
    return res.data;
};

export const changeFlagRisk = (data) => {
    return axios.put('flag/monitoring-queue/risk', data);
};

export const changeFlagStatus = (data) => {
    return axios.put('flag/monitoring-queue/status', data);
};

export const addFlag = (data) => {
    return axios.post('flag', data);
};

export const getFlaggedDataColumns = async (id) => {
    const res = await axios.get(`/flag/${id}/flagged-data`);
    return res.data;
};

export const changeStatusSearch = (status, ruleId, data = []) => {
    return axios.post(`/search/status?status=${status}&ruleId=${ruleId}`, data);
};

export const escalateFlag = (data) => {
    return axios.post('flag/monitoring-queue/escalate', data);
};

export const assignFlag = (data) => {
    return axios.post('flag/monitoring-queue/assign', data);
};

export const getFlagHistory = async (id) => {
    const res = await axios.get(`/flag/${id}/monitoring-queue/status/history`);
    return res.data;
};

export const changeAmlRisk = (data) => {
    return axios.put(`/flag/monitoring-queue/risk`, data);
};

export const assignTo = (data) => {
    return axios.post('flag/assign-to', data);
};
