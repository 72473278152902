import { useRuleContext } from "../../../ruleContext";
import MoreActions from './moreActions';

export default function FunctionWrapper({
    functionData,
    content,
    id,
    setNameEditMode,
}) {
    const { allowRuleEdit } = useRuleContext();

    return (
        <div className="function-wrapper">
            <div className="flex-container flex-end">
                <p>Function {functionData.function?.name}</p>
                {allowRuleEdit && (
                    <MoreActions
                        parentFunctionId={functionData.id}
                        id={id}
                        setNameEditMode={setNameEditMode}
                    />
                )}
            </div>
            {functionData.childEntityFieldFunction ? (
                <FunctionWrapper
                    functionData={functionData.childEntityFieldFunction}
                    content={content}
                    id={id}
                    setNameEditMode={setNameEditMode}
                />
            ) : (
                content
            )}
        </div>
    );
}