import { SelectBox } from 'devextreme-react';
import { updateField } from '../../../../services/rules';
import { useEffect, useMemo } from 'react';
import { useDrop } from 'react-dnd';
import { useConditionContext } from './conditionsContext';
import * as rulesService from '../../../../services/rules';
import { useDispatch } from 'react-redux';
import { errorMsg } from '../../../../utils/vars';
import { NestedPlaceholder } from './placeholder/placeholder';
import {
    addConditionPlaceholder,
    clearConditionPlaceholder,
    removeHoverField,
} from '../../../../redux/features/dashboard/rule';
import { useRuleContext } from '../../ruleContext';
import { useQueryContext } from './queryContext';
import FieldsGroupContextProvider from './fieldsGroupContext';
import RightField from './conditionFields/rightField';
import LeftField from './conditionFields/leftField';
import { useToastContext } from '../../../../components/toast/toast';
import { getAxiosResponseError } from '../../../../utils/functions';
import ChangeOperator from './changeOperator';

export default function Condition({ fieldsData, withNestedPlaceholder }) {
    const { currentGroupId, operatorsData, forJoin } = useConditionContext();
    const { refetchRule, allowRuleEdit } = useRuleContext();
    const { queryId } = useQueryContext();
    const dispatch = useDispatch();
    const { showErrorToast } = useToastContext();

    const [_collectedPropsCondition, dropCondition] = useDrop(() => ({
        accept: 'entity-drop-target',
        drop: (item, monitor) => dropHandler(monitor.getDropResult(), item),
        hover: (item, monitor) => {
            if (monitor.isOver({ shallow: true })) {
                dispatch(
                    removeHoverField({
                        queryId,
                        type: forJoin ? 'joinGroups' : 'conditionGroups',
                    })
                );
            }
        },
    }));

    const [_collectedPropsSelect, dropSelect] = useDrop(() => ({
        accept: 'entity-drop-target',
        drop: (item, monitor) => dropHandler(monitor.getDropResult(), item),
        hover: (item, monitor) => {
            if (monitor.isOver({ shallow: true })) {
                dispatch(
                    clearConditionPlaceholder({
                        type: forJoin ? 'joinGroups' : 'conditionGroups',
                    })
                );
                dispatch(
                    removeHoverField({
                        queryId,
                        type: forJoin ? 'joinGroups' : 'conditionGroups',
                    })
                );
                dispatch(
                    addConditionPlaceholder({
                        queryId,
                        conditionGroupId: currentGroupId,
                        isNestedGroup: true,
                        type: forJoin ? 'joinGroups' : 'conditionGroups',
                    })
                );
            }
        },
    }));

    async function dropHandler(dropResult, item) {
        if (dropResult) {
            return;
        }

        console.log('Drop on condition', item);

        const createdGroupResp = await rulesService.postConditionGroup({
            groupOperator: 'AND',
            queryId: Number(queryId),
            value: '',
            conditionGroupId: currentGroupId,
            conditionOperatorType: 'EQUALS',
            forJoin,
            leftFieldId: fieldsData.leftEntityField?.field?.id,
            leftDisplayedEntityId:
                fieldsData.leftEntityField?.displayedEntity?.id,
            rightFieldId: fieldsData.rightEntityField?.field?.id,
            rightDisplayedEntityId:
                fieldsData.rightEntityField?.displayedEntity?.id,
        });

        const createdGroup = createdGroupResp.data;

        await rulesService.updateField({
            id: createdGroup.fields?.[0]?.id,
            leftFieldId: item.data?.id,
            leftDisplayedEntityId: item.displayedEntityId,
        });

        refetchRule();
    }

    const memoizedFieldsData = useMemo(() => fieldsData, [fieldsData]);

    async function deleteConditionHandler() {
        try {
            await rulesService.deleteConditionGroup({ id: fieldsData.id });
            refetchRule();
        } catch (err) {
            console.error(err);
        }
    }

    if (!fieldsData) {
        return null;
    }

    return (
        <FieldsGroupContextProvider fieldsGroupData={fieldsData}>
            <div ref={dropCondition}>
                <div className="fields-flex-wrapper">
                    <LeftField fieldsData={fieldsData} />
                    <div
                        ref={dropSelect}
                        className={'condition-select-wrapper'}
                    >
                        <ChangeOperator />
                    </div>

                    <RightField fieldsData={memoizedFieldsData} />
                    {allowRuleEdit && (
                        <div
                            className="close-icon-wrapper"
                            onClick={deleteConditionHandler}
                        >
                            <i className="dx-icon-close" />
                        </div>
                    )}
                </div>
                {withNestedPlaceholder && <NestedPlaceholder />}
            </div>
        </FieldsGroupContextProvider>
    );
}
