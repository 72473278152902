import React, { useState } from 'react';
import Xarrow, { useXarrow } from 'react-xarrows';
import { idGenerator } from 'generate-custom-id';
import * as entityService from '../../../services/entity';
import Accordion from './accordion/accordion';
import { Toast } from 'devextreme-react';
import { useRef } from 'react';
import { toastDisplayTime } from '../../../utils/vars';
import { useQueryContext } from '../conditionsDropTarget/condition/queryContext';
import { useToastContext } from '../../../components/toast/toast';
import { getAxiosResponseError } from '../../../utils/functions';

export default function Entity({
    data,
    startPointId,
    removeEntity,
    allowRemove,
    from,
    parentOpen,
    diplayedEntityId,
    innerDisplayedEntities,
    parentDisplayedEntityId,
    refetchEntites,
    isTopLevel,
    name,
    parentRef,
}) {
    const {queryId} = useQueryContext();
    const open = !!diplayedEntityId;
    const updateArrows = useXarrow();
    const leftId = idGenerator('example', 4);
    const rightId = idGenerator('example', 4);
    const {showErrorToast} = useToastContext();

    const entityRef = useRef();

    async function onOpen() {
        try {
            if (from === 'entity-drop-target') {
                await entityService.expandEntity({
                    entityId: data.id,
                    parentDisplayedEntityId,
                    queryId
                });
            }
            refetchEntites();
            updateArrows();
        } catch (err) {
            showErrorToast(getAxiosResponseError(err));
        }
    }

    async function onClose() {
        try {
            await entityService.shrinkEntity(diplayedEntityId);
            refetchEntites();
        } catch (err) {
            console.error(err);
            showErrorToast(getAxiosResponseError(err));
        }
    }

    function isVisible(side) {
        const parentWidth = parentRef?.current?.clientWidth;
        const parentScrollLeft = parentRef?.current?.scrollLeft;
        const childWidth = entityRef?.current?.offsetWidth;
        const childOffsetLeft = entityRef?.current?.offsetLeft;
        const childRightCorner = childOffsetLeft + childWidth;

        if (side === 'right') {
            return (
                childRightCorner >= parentScrollLeft &&
                childRightCorner <= parentScrollLeft + parentWidth
            );
        } else {
            return (
                childOffsetLeft >= parentScrollLeft &&
                childOffsetLeft <= parentScrollLeft + parentWidth
            );
        }
    }

    const rightVisible = isVisible('right');

    return (
        <div className="accordion-entities-section">
            <div className="enitity-wrapper" ref={entityRef}>
                <Accordion
                    name={name}
                    from={from}
                    data={data}
                    open={open}
                    onClose={onClose}
                    removeEntity={removeEntity}
                    allowRemove={allowRemove}
                    startPointId={startPointId}
                    leftId={leftId}
                    rightId={rightId}
                    hasConnections={!!innerDisplayedEntities?.length}
                    parentOpen={parentOpen}
                    onOpen={onOpen}
                    diplayedEntityId={diplayedEntityId}
                    isTopLevel={isTopLevel}
                />
                {startPointId && (
                    <Xarrow
                        curveness={0}
                        strokeWidth={1}
                        start={startPointId}
                        end={leftId}
                        showHead={false}
                        color={'#0099cc'}
                    />
                )}
            </div>
            {open && (
                <div className="entity-group-width-wrapper">
                    {innerDisplayedEntities?.map((e, i) => (
                        <Entity
                            name={e.name}
                            e={e}
                            from={from}
                            // startPointId={rightId}
                            startPointId={rightVisible && rightId}
                            key={i}
                            data={e.entity || e}
                            parentOpen={open}
                            parentDisplayedEntityId={diplayedEntityId}
                            innerDisplayedEntities={e.innerDisplayedEntities}
                            diplayedEntityId={e.diplayedEntityId}
                            ruleEntityId={e.ruleEntityId}
                            refetchEntites={refetchEntites}
                            parentRef={parentRef}
                        />
                    ))}
                </div>
            )}
        </div>
    );
}
