import { TextBox } from 'devextreme-react';
import { useState } from 'react';
import { updateQueryName } from '../../../services/rules';
import { Toast } from 'devextreme-react/toast';
import { errorMsg, toastDisplayTime } from '../../../utils/vars';
import { useQueryContext } from '../conditionsDropTarget/condition/queryContext';
import { useRuleContext } from '../ruleContext';
import { useToastContext } from '../../../components/toast/toast';
import { getAxiosResponseError } from '../../../utils/functions';

export default function QueryName({ initialValue }) {
    const [editMode, setEditMode] = useState(false);
    const {showErrorToast} = useToastContext();

    const { refetchRule } = useRuleContext();
    const { queryId } = useQueryContext();
    
    const changeToEditMode = () => setEditMode(true);
    const changeToReadOnlyMode = () => setEditMode(false);

    async function saveHandler(alias) {
        try {
            await updateQueryName({ queryId, alias });
            refetchRule();
            changeToReadOnlyMode();
        } catch (err) {
            console.error(err);
            showErrorToast(getAxiosResponseError(err))
        }
    }

    return (
        <div className="query-name-wrapper">
            {editMode ? (
                <>
                    <TextBox
                        defaultValue={initialValue || 'Query name'}
                        stylingMode="underlined"
                        onChange={(ev) => saveHandler(ev.event.target.value)}
                        onFocusOut={changeToReadOnlyMode}
                    />
                </>
            ) : (
                <p className="query-name" onClick={changeToEditMode}>
                    {initialValue || 'Query name'}
                </p>
            )}
        </div>
    );
}
