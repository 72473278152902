import {
    Button,
    DataGrid,
    LoadPanel,
    SelectBox,
    Toast,
} from 'devextreme-react';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import './settings.scss';
import Loader from '../../components/loader/loader';
import ActionsPanel from './actionsPanel/actionsPanel';
import { useActionsRoleContext } from '../../contexts/actionsRoleProvider';
import EmailSettings from './emailSettings/emailSettings';
import GeneralSettings from './generalSettings/generalSettings';
import { toastDisplayTime } from '../../utils/vars';

export default function Settings() {
    const user = useSelector((state) => state.user);
    const { role } = useActionsRoleContext();

    if (!user?.timezone) {
        return <Loader height={'70vh'} />;
    }
    return (
        <div className="settings-wrapper">
            {/* <h2>{languageLables.settings[lg]}</h2> */}
            <GeneralSettings />
            {(role === 'GRANT_ADMIN' || role === 'ADMIN') && <ActionsPanel />}
            {(role === 'GRANT_ADMIN' || role === 'ADMIN') && <EmailSettings />}
        </div>
    );
}
