import { createSlice } from '@reduxjs/toolkit';

const errorFetchingActionsSlice = createSlice({
    name: 'errorFetchingActions',
    initialState: false,
    reducers: {
        setErrorFetchingActions: (_state, action) => {
            return  action.payload;
        },
    },
});

export default errorFetchingActionsSlice.reducer;

export const { setErrorFetchingActions } = errorFetchingActionsSlice.actions;
