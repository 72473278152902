import axios from './axios/config';

export const getSmtp = async () => {
  const res = await axios.get('/tenant/email/smtp');
  return res.data;
}

export const editSmpt = (data) => {
  return axios.put('/tenant/email/smtp', data);
}

export const getRestConfig = async () => {
    const res = await axios.get('tenant/email/rest');
    return res.data;
}

export const editRest = (data) => {
    return axios.put('tenant/email/rest', data);
}

export const getEmailConfig = async () => {
  const res = await axios.get('tenant/mail/configs');
  return res.data;
}

export const editEmailConfig = (data) => {
  return axios.put('admin/mail/config', data);  
}
