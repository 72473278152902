import { createSlice } from '@reduxjs/toolkit';

const limitSectionSlice = createSlice({
    name: 'language',
    initialState: {
        mode: 'edit',
        value: '',
        defaultValue: null
    },
    reducers: {
        changeMode: (state, action) => {
            state.mode = action.payload;
        },
        changeValue: (state, action) => {
            state.value = action.payload;
        },
        changeDefaultValue: (state, action) => {
            state.defaultValue = action.payload;
        }
    },
});

export const { changeMode, changeValue, changeDefaultValue } = limitSectionSlice.actions;

export default limitSectionSlice.reducer;
