import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as rulesService from '../../services/rules';
import './rules.scss';
import { Button } from 'devextreme-react/button';
import Popup from 'devextreme-react/popup';
import TextBox from 'devextreme-react/text-box';
import { useSelector } from 'react-redux';
import { errorMsg } from '../../utils/vars';
import { SelectBox, TextArea } from 'devextreme-react';
import { useCheckPermission } from '../../hooks/useCheckPermission';
import useThrottle from '../../hooks/useThrottle';
import Stack from '@mui/material/Stack';
import ExportBtn from '../../components/exportBtn/exportBtn';
import { isValidStringValue } from '../../utils/functions';
import { useToastContext } from '../../components/toast/toast';
import Table from '../../components/rules/Table';

export default function Rules() {
    const [searchValue, handleChange] = useThrottle(500);

    const [dialogOpen, setDialogOpen] = useState(false);
    const createRulePermission = useCheckPermission('CREATE_RULE');

    return (
        <div className="rules-tab">
            <div className="flex-container header-wrapper">
                <Stack flexDirection="row" gap="10px">
                    {createRulePermission && (
                        <div className="btn-wrapper">
                            <Button
                                onClick={() => setDialogOpen(true)}
                                icon="plus"
                                type="default"
                                stylingMode="contained"
                                text={'Create'}
                            />
                        </div>
                    )}
                    <ExportRules />
                </Stack>
                <TextBox
                    stylingMode="underlined"
                    width={200}
                    mode="search"
                    onInput={(e) => handleChange(e.event)}
                />
            </div>
            <Table searchValue={searchValue} rulesType="basic" />
            <CreateRule setDialogOpen={setDialogOpen} dialogOpen={dialogOpen} />
        </div>
    );
}

function CreateRule({ setDialogOpen, dialogOpen }) {
    const [ruleName, setRuleName] = useState('');
    const [description, setDescription] = useState('');
    const [errors, setErrors] = useState(false);
    const [descriptionErrors, setDescriptionErrors] = useState(false);
    const navigate = useNavigate();
    const [typeOfRule, setTypeOfRule] = useState('PTM');
    const typeSelectItems = [
        { value: 'PTM', label: 'PTM' },
        { value: 'RTM', label: 'RTM' },
    ];
    const { showErrorToast } = useToastContext();

    async function createRuleHandler() {
        if (!ruleName) {
            setErrors(true);
            return;
        }

        try {
            const res = await rulesService.create({
                name: ruleName,
                type: 'BASIC',
                typeOfRule,
                description,
            });
            navigate(`/rule-dashboard/${res.id}`);
        } catch (err) {
            console.error(err);
            showErrorToast(err.response?.data?.message || errorMsg);
        }
    }

    function dialogCloseHandler() {
        setDialogOpen(false);

        setTimeout(() => {
            setRuleName('');
            setDescription('');
        }, 500);
    }
    return (
        <Popup
            hideOnOutsideClick={true}
            visible={dialogOpen}
            onHiding={dialogCloseHandler}
            width={300}
            height="auto"
            title={'Create rule'}
            showCloseButton={true}
        >
            <form style={{ margin: '10px' }}>
                <Stack gap="10px">
                    <SelectBox
                        items={typeSelectItems}
                        displayExpr={'label'}
                        valueExpr={'value'}
                        defaultValue={'PTM'}
                        onItemClick={(e) => setTypeOfRule(e.itemData.value)}
                        stylingMode="underlined"
                    />
                    <TextBox
                        label={'Rule name *'}
                        isValid={!errors}
                        onInput={(e) => {
                            if (errors) {
                                setErrors(false);
                            }
                            setRuleName(e.event.currentTarget.value);
                        }}
                        stylingMode="underlined"
                        value={ruleName}
                    />
                    <TextArea
                        label={'Description *'}
                        isValid={!descriptionErrors}
                        onInput={(e) => {
                            if (descriptionErrors) {
                                setDescriptionErrors(false);
                            }
                            setDescription(e.event.currentTarget.value);
                        }}
                        stylingMode="underlined"
                        value={description}
                    />
                </Stack>

                <div style={{ marginTop: '10px' }}>
                    <Button
                        onClick={createRuleHandler}
                        stylingMode="contained"
                        type="default"
                        text={'Create'}
                        width={'100%'}
                        disabled={
                            !isValidStringValue(ruleName) ||
                            !isValidStringValue(description)
                        }
                    />
                </div>
            </form>
        </Popup>
    );
}

function ExportRules() {
    const exportParams = useSelector((state) => state.rulesSearchParams);

    return (
        <ExportBtn
            exportUrl={`rule/all/export${exportParams}`}
            method="GET"
            name={'rules'}
        />
    );
}
