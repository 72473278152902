import { useSelector } from 'react-redux';
import { deleteLimit, postLimit } from '../../../services/rules';
import Stack from '@mui/material/Stack';
import { NumberBox, TextBox } from 'devextreme-react';
import { useQueryContext } from '../conditionsDropTarget/condition/queryContext';
import { useRuleContext } from '../ruleContext';
import { useToastContext } from '../../../components/toast/toast';
import { getAxiosResponseError } from '../../../utils/functions';

export default function LimitSection({ isSubquery }) {
    let wrapperClasses = 'datafield-drop-target';
    const theme = useSelector((state) => state.theme);
    const { queryData, queryId } = useQueryContext();
    const { refetchRule, allowRuleEdit } = useRuleContext();
    const defaultValue = queryData?.limit?.limit;
    const {showErrorToast} = useToastContext();

    wrapperClasses +=
        theme === 'dark'
            ? ' limit-paper paper-dark-theme'
            : ' limit-paper paper';

    if (isSubquery) {
        wrapperClasses += ' paper-subquery ';
    }

    async function saveHandler(limit) {
        try {
            await postLimit({ queryId, limit });
            refetchRule();
        } catch (err) {
            console.error(err);
            showErrorToast(getAxiosResponseError(err));

        }
    }

    return (
        <div
            className={isSubquery ? 'limit-wrapper width-50' : 'limit-wrapper'}
        >
            <p className="section-label">Limit</p>
            <Stack
                className={wrapperClasses}
                flexDirection={'row'}
                justifyContent={'space-between'}
                alignItems={'end'}
                gap={'10px'}
            >
                {defaultValue || !allowRuleEdit ? (
                    <ReadOnlyComponent
                        value={defaultValue}
                    />
                ) : (
                    <NumberBox
                        defaultValue={0}
                        stylingMode="outlined"
                        width={250}
                        format={'#'}
                        // onInput={(e) => setLimitValue(e.event.target.value)}
                        onChange={(e) => saveHandler(e.event.target.value)}
                    />
                )}
            </Stack>
        </div>
    );
}

function ReadOnlyComponent({ value }) {
    const { queryId } = useQueryContext();
    const { refetchRule, allowRuleEdit } = useRuleContext();
    const { showErrorToast } = useToastContext();

    async function deleteHandler() {
        try {
            await deleteLimit({ queryId });
            refetchRule();
        } catch (err) {
            console.error(err);
            showErrorToast(getAxiosResponseError(showErrorToast));
        }
    }
    return (
        <div className="limit-readonly field-input-wrapper">
            <TextBox
                value={value?.toString()}
                stylingMode="outlined"
                disabled
            />
            {allowRuleEdit && (
                <div
                    className="field-delete-btn-wrapper"
                    onClick={deleteHandler}
                >
                    <i className="dx-icon-close" />
                </div>
            )}
        </div>
    );
}
