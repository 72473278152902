import { Button, Popup } from 'devextreme-react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { getCoordinators } from '../../../services/user';
import { reactQueryOptions } from '../../../utils/reactQueryOptions';
import { assignTo } from '../../../services/flag';
import { useParams } from 'react-router-dom';
import { useToastContext } from '../../../components/toast/toast';
import { useCheckPermission } from '../../../hooks/useCheckPermission';

export default function AssignTo({ refetch, rowFlagId }) {
    const { id: paramsFlagId } = useParams();
    const [popupOpen, setPopupOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const closePopup = () => setPopupOpen(false);
    const openPopup = () => setPopupOpen(true);
    const { showSuccessToast, showErrorToast } = useToastContext();
    const assignToPermisson = useCheckPermission('ASSIGN_TO');

    const { data: coordinators } = useQuery(
        'coordinators',
        getCoordinators,
        reactQueryOptions
    );
    const options = coordinators?.map((e) => ({
        label: `${e.name} - assigned flags - ${e.pendingFlags}    `,
        id: e.id,
    }));

    async function assignToHandler() {
        try {
            await assignTo({
                flagId: rowFlagId || paramsFlagId,
                userId: selectedUser.id,
            });
            showSuccessToast('Flag successfully assigned.');

            refetch();
        } catch (err) {
            console.log(err);
            showErrorToast(err.response?.data?.message);
        }
    }

    if (!assignToPermisson) {
        return null;
    }

    return (
        <div>
            <Button
                text="Assign to"
                stylingMode="outlined"
                type="default"
                onClick={(ev) => {
                    ev.event.stopPropagation();
                    openPopup();
                }}
            />
            <Popup
                visible={popupOpen}
                onHiding={closePopup}
                showTitle={false}
                width="auto"
                height="auto"
            >
                <Stack sx={{ padding: '10px' }} gap="10px">
                    <Autocomplete
                        sx={{ width: '250px' }}
                        options={options}
                        variant="underlined"
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Assign to"
                                variant="standard"
                            />
                        )}
                        onChange={(_ev, value) => setSelectedUser(value)}
                    />
                    <Stack
                        flexDirection="row"
                        justifyContent="space-between"
                        gap="10px"
                    >
                        <Button
                            text="Cancel"
                            type="danger"
                            onClick={closePopup}
                        />
                        <Button
                            text="Confirm"
                            stylingMode="outlined"
                            type="success"
                            onClick={assignToHandler}
                            disabled={!selectedUser}
                        />
                    </Stack>
                </Stack>
            </Popup>
        </div>
    );
}
