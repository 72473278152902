import axios from './axios/config';

export const getReportFields = async (type) => {
  const res = await axios.get(`report/type?type=${type}`);
  return res.data;
}

export const generate = async(page, size, data) => {
  const res = await axios.post(`report?page=${page}&size=${size}`, data);
  return res.data;
}

export const getSearchFields = async () => {
  const res = await axios.get('search/base');
  return res.data;
}

export const exportSingle = async (data) => {
  const res = await axios.post('/api/report/single/export', data);
  return res.data();
}
