import moment from 'moment';
import { onlyWhiteSpaceRegex } from './vars';

export const isValidStringValue = (value) =>
    !!value && !onlyWhiteSpaceRegex.test(value);

export function getFilterParams(filter, filterSelectors) {
    let params = '';
    if (filter[0] === 'dateCreated' || typeof filter.filterValue === 'object') {
        if (filter.selectedFilterOperation === '=') {
            const date = moment(filter.filterValue).format('yyyy-MM-DD');
            params = `&startDate=${date}&endDate=${date}`;
        } else {
            const startDate = moment(filter.filterValue[0]).format(
                'yyyy-MM-DD'
            );
            const endDate = moment(filter.filterValue[1]).format('yyyy-MM-DD');
            params = `&startDate=${startDate}&endDate=${endDate}`;
        }
    } else if (typeof filter[0] === 'string') {
        const selector = filter[0];
        params = `&${filterSelectors[selector]}=${filter[2]}`;
    } else {
        params = mapParams();
    }

    function mapParams() {
        let p = '';

        filter.forEach((e) => {
            if (e === 'and') {
                return;
            } else if (typeof e[0] === 'object') {
                if (e[1] === '<') {
                    const date = moment(e[2]).format('yyyy-MM-DD');
                    p += `&startDate=${date}&endDate=${date}`;
                } else {
                    const startDate = moment(e.filterValue[0]).format(
                        'yyyy-MM-DD'
                    );
                    const endDate = moment(e.filterValue[1]).format(
                        'yyyy-MM-DD'
                    );
                    p += `&startDate=${startDate}&endDate=${endDate}`;
                }
            } else {
                const selector = e[0];
                p += `&${filterSelectors[selector]}=${e[2]}`;
            }
        });
        return p;
    }

    return params;
}

export function getDirection(sort) {
    if (sort) {
        return sort[0].desc ? 'DESC' : 'ASC';
    }

    return 'DESC';
}

export const getAxiosResponseError = (err) => err?.response?.data?.message;

export const getCurrentRuleVersion = (ruleHistory, id) => {
    let v;

    ruleHistory?.forEach((e) => {
        if (e.ruleId === id) {
            v = e.version;
        }
    });

    return v;
};

export const getPreviousVersionDate = (ruleHistory, currentRuleVersion) => {
    let date;

    ruleHistory?.forEach((e) => {
        if (
            e.version === currentRuleVersion - 1 ||
            e.changeType === 'ACTIVATED'
        ) {
            date = e.activeSince;
        }
    });

    return date;
};

export const getRightType = (
    droppedFieldType,
    oldDroppedFieldType,
    oldRightType
) => {
    console.log('get right type');
    let type = '';

    switch (droppedFieldType) {
        case 'DATE':
            type = 'DATE';
            break;
        case 'BOOL':
            type = 'BOOL';
            break;
        default:
            type = 'FIELD';
    }

    if (oldDroppedFieldType) {
        let oldType = '';

        switch (oldDroppedFieldType) {
            case 'DATE':
                oldType = 'DATE';
                break;
            case 'BOOL':
                oldType = 'BOOL';
                break;
            default:
                oldType = 'FIELD';
        }
        
        if (type === oldType) {
            return oldRightType;
        }
    }

    return type;
};
