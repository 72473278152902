import axios from './axios/config';

export const getAll = async () => {
    const res = await axios.get('entity/all');
    return res.data;
};

export const getEntities = async (id) => {
    const res = await axios.get(`entity/all?entityId=${id}`);
    return res.data;
}

export const expandEntity = (data) => {
  return axios.post('rule/expand', data);
}

export const shrinkEntity = (id) => {
  return axios.delete('/rule/shrink', {data: {displayedEntityId: id}});
}


export const detachEntity = (id) => {
  return axios.delete('/rule/detach', {data: {displayedEntityId: id}});
}
