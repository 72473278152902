import { createSlice } from '@reduxjs/toolkit';

const rulesScroll = createSlice({
    name: 'rules-scroll',
    initialState: 0,
    reducers: {
        scroll: (state) => {
           return state += 1;
        },
    },
});

export default rulesScroll.reducer;

export const { scroll } = rulesScroll.actions;
